import { ThunkDispatch, ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { SERVER_URL } from "../../configs/service-config";
import { Dispatcher } from "./model";
import { getRequest, postRequest, deleteRequest } from "../interceptor";
import { setDispatchers } from "./action";
import _isUndefined from "lodash/isUndefined";
import _isEqual from "lodash/isEqual";
import _isNull from "lodash/isNull";
import { message } from "antd";
import { getClientId } from "../auth/actions";

export const fetchDispatchers = (): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const clientId: any = dispatch(getClientId());
    const fetchDispatchersEndpoint = new URL(SERVER_URL.DISPATCHERS);
    fetchDispatchersEndpoint.searchParams.append("clientId", clientId);

    await getRequest(fetchDispatchersEndpoint).then(response => {
      dispatch(setDispatchers(response));
    }).catch((error) => console.log("ERROR", error));
  };
};

export const saveDispatcher = (dispatcher:Dispatcher): ThunkAction<Promise<Dispatcher>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    
    const saveDispatcherURI = new URL(SERVER_URL.DISPATCHERS);
    let savedDispatcher: any = null;
    await postRequest(saveDispatcherURI, {body: JSON.stringify(dispatcher)})
      .then((response: any) => {
        savedDispatcher = response;
      })
      .catch((error: any) => {
        savedDispatcher = error;
      });
    return savedDispatcher;
  };
}

export const deleteDispatcher = (dispatcher:Dispatcher): ThunkAction<Promise<Dispatcher>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    
    const deleteDispatcherURI = new URL(`${SERVER_URL.DISPATCHER}/${dispatcher.id}`);  
    let deletedDispatcher: any = null;
    await deleteRequest(deleteDispatcherURI)
      .then((response: any) => {
        deletedDispatcher = response;
      })
      .catch((error: any) => {
        console.log("ERROR", error);
      });
    return deletedDispatcher;
  };
};


export const resetPasswordViaEmail = (email: string): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const clientId: any = dispatch(getClientId());
    let body = {
      "clientId": clientId,
      "email": email,
      "resetMode": "RESET"
    };
    let strBody = JSON.stringify(body);
    const endpoint = SERVER_URL.RESET_PASSWORD_ENDPOINT;
    const options = {
      headers: {
        Accept: "application/json",
        'Content-Type': 'application/json',
      },
      method: "POST",
      body: strBody
    };

    try {
      const req = new Request(endpoint.toString(), options);
      const res = await fetch(req);
      if (res) {
        message.success("The password was reset successfully, please check your email.");
      }
    } catch (error) {
      return message.error("An error occured while resetting the password");
    };
  };
};








