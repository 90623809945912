import React, { useEffect } from "react";
import { DatePicker, TimePicker, Button, Modal, Select, Form, InputNumber } from "antd";
import { useState } from "react";
import TextArea from "antd/lib/input/TextArea";
import HttpServiceRequest from "../../../services/service-request";
import '../../../assets/stylesheets/layout-style/modal-layout.scss'

import { UnassignedSR } from '../../../redux/unassign-service-request/model';

const MEMBER_FIELD = 'MEM';
const PROPERTY_FIELD = 'PRP';
const PROPERTY_ADDRESS_FIELD = 'PRPA';
const PROPERTY_STATE_FIELD = 'PRPS';
const PROPERTY_CITY_FIELD = 'PRPC';
const PROPERTY_ZIPCODE_FIELD = 'PRPZ';
const SERVICE_TYPE_FIELD = 'SVT';
const ROOM_FIELD = 'RM';
const ITEM_FIELD = 'ITM';
const BRAND_FIELD = 'BRN';
const MODEL_FIELD = 'MDL';
const SERIAL_NUMBER_FIELD = 'ISN';

const DETAIL_FIELD = 'DTL';

interface IProps {}

const BidOutLayout: React.FC<IProps> = (props): JSX.Element => {

  const [form] = Form.useForm();
  
  const [isOpen, actionModal] = useState(false);
  const [serviceRequest, setServiceRequest] = useState<UnassignedSR>();
  
  let httpService = new HttpServiceRequest();

  const formItemLayout = {
    wrapperCol: {
      span: 32,
      offset: 0,
    },
  };

  useEffect(() => {

    window.addEventListener("bidOutRequested", openBidOut);
    return () => window.removeEventListener("bidOutRequested", openBidOut);

  }, []);

  function openBidOut(event: Event) {

    form.resetFields();
    
    //@ts-ignore
    const { request } = event.detail;
    setServiceRequest(request)
    actionModal(true);

    const description = getFieldValue(request, 'DTL');
    form.setFieldsValue({description: description});
  }

  const onFinish = (values: any) => {
    constructData(values)
  };

  function constructData(formData:any){

    const bidOut = {...formData};
    bidOut.serviceRequestId = serviceRequest?.id;

    let scheduleDate = formData.startDate;
    scheduleDate.set('hour', formData.startTime.hour());
    scheduleDate.set('minute', formData.startTime.minutes());
    scheduleDate.set('second', 0);
    scheduleDate.set('millisecond', 0);

    bidOut.startDate = scheduleDate.toDate();

    httpService.bidOut(bidOut).then(res => {
      if (res) { 
        const item: UnassignedSR = createUnassignedServiceRequest(res);
        window.dispatchEvent(new CustomEvent('bidOutCompleted', {detail: {request: item}}));
        actionModal(false);
      }
    }, error => {
      console.log(error);
    });
  }

  /**
   * Creates the unassigned service request from the json instance returned by the server.
   * @param res the data returned by the server
   * @returns the unassigned service request
   */
   const createUnassignedServiceRequest = (res: any): UnassignedSR => {

    const item: UnassignedSR = {

      id: res.id,
      serviceRequestNo: res.serviceRequestNo,
      currentStatus: res.currentStatus,
      durationInMinutes: res.durationInMinutes,
      requestFormValues: res.requestFormValues,
      scheduleDateTimeStamp: res.scheduleDateTimeStamp,
      createDateTimeStamp: res.scheduleDateTimeStamp,
      technicianId: res.technicianId,
      technicianName: res.technicianName,
      isCollapse: false,
      permitTechnicianEntry: res.permitTechnicianEntry,
    }

    return item;
  }

  const getFieldValue = (request:any, fieldName: string):string => {

    const value = request?.requestFormValues.find((value:any) => {
      return value.field.shortName === fieldName;
    });
    
    return value ? value.value : '';
  }

  return (

    <Modal
      visible={isOpen}
      centered={true}
      className={"modal-body"}
      onCancel={() => actionModal(false)}
      closable={false}
      footer={null}
      width= '25%'>

      <br />
      <h3>BID OUT</h3>
      <br />
      
      <div style={{alignSelf: 'stretch', paddingLeft: 24, paddingRight: 24}}>
        
        <div style={{fontWeight: 'bold'}}>Property Details:</div>
        <div style={{paddingLeft: 20}}>Member: {getFieldValue(serviceRequest, MEMBER_FIELD)}</div>
        <div style={{paddingLeft: 20}}>Property: {getFieldValue(serviceRequest, PROPERTY_FIELD)}</div>
        <div style={{paddingLeft: 20}}>Address: {getFieldValue(serviceRequest, PROPERTY_ADDRESS_FIELD)}, {getFieldValue(serviceRequest, PROPERTY_CITY_FIELD)} {getFieldValue(serviceRequest, PROPERTY_STATE_FIELD)} {getFieldValue(serviceRequest, PROPERTY_ZIPCODE_FIELD)}</div>

        <br/>
        
        {getFieldValue(serviceRequest, ITEM_FIELD) &&
        <div>
          <div style={{fontWeight: 'bold'}}>Item Detail:</div>
          <div style={{paddingLeft: 20}}>{getFieldValue(serviceRequest, ITEM_FIELD)}</div>
        </div> 
        ||
        <div>
          <div style={{fontWeight: 'bold'}}>Service Type:</div>
          <div style={{paddingLeft: 20}}>{getFieldValue(serviceRequest, SERVICE_TYPE_FIELD)}</div>
        </div>
        }

        <br/>

        <div style={{fontWeight: 'bold'}}>Preferences:</div>
        
        <br/>
        
        <Form
          form={form}
          name="validate_other"
          {...formItemLayout}
          onFinish={onFinish}>

          <Form.Item name={'payRate'} rules={[{ required: true }]}>
            <InputNumber name={'payRate'} placeholder={"Pay Rate"} style={{width: '100%'}}/>
          </Form.Item>

          <Form.Item name={'technicianCount'} rules={[{ required: true }]}>
            <InputNumber name={'technicianCount'} placeholder={"Technician Count"} style={{width: '100%'}}/>
          </Form.Item>

          <div style={{display: 'grid', gridTemplateColumns: '49% 2% 49%'}}>
            <Form.Item name={'startDate'} rules={[{ required: true }]}>
              <DatePicker className={'custom-datepicker'} name={'startDate'} bordered={true} placeholder="Date"/>
            </Form.Item>
            <div></div>
            <Form.Item name={'startTime'} rules={[{ required: true }]}>
              <TimePicker className={'custom-datepicker'} use12Hours format="h:mm a" minuteStep={30} name={'startTime'} bordered={true}/>
            </Form.Item>
          </div>

          <Form.Item name={'durationInMinutes'} rules={[{ required: true }]}>
            <InputNumber name={'durationInMinutes'} placeholder={"Duration in Minutes"} style={{width: '100%'}}/>
          </Form.Item>

          <div style={{fontWeight: 'bold'}}>Description:</div>

          <Form.Item name={'description'}>
            <TextArea name={'description'}></TextArea>
          </Form.Item>

          <br/>
          <br/>
          
          <Form.Item className={'button-container'}>
            <div className={'button-container'}>
              <Button style={{'backgroundColor': '#3A78B3' ,'color': ' white', 'marginRight': '20px'}} block={true} type="primary" onClick={() => actionModal(false)}>
                Cancel
              </Button>
              <Button block={true} style={{'backgroundColor': '#3A78B3' ,'color': ' white'}} type="primary" htmlType="submit">
                Save
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default React.memo(BidOutLayout);

