import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { RootState } from "../../redux";
import { ThunkDispatch } from "redux-thunk";
import {
  Button,
  Form,
  Input,
  Upload,
  List,
  Checkbox,
  Radio,
  Rate,
  Select,
  Switch,
  Image,
  TimePicker,
  DatePicker,
  message,
  Modal,
} from "antd";
import {
  CaretDownOutlined,
  CloseOutlined,
  CheckOutlined,
  CloudUploadOutlined,
  MessageOutlined
} from "@ant-design/icons";
import BackBlueArrow from "../../assets/images/backbluearrow.png";
import _isqual from "lodash/isEqual";
import {
  postTechnicianProfilePhoto,
  postVendor,
  postVendorTechnicianAvailability,
  postVendorTechnicianBankInfo,
  postVendorTechnicianPersonalInfo,
  postVendorTechnicianServiceExperience,
} from "../../redux/vendors/service";
import moment from "moment";
import 'moment-timezone';
import _isEqual from "lodash/isEqual";
import _isNull from "lodash/isNull";
import _isEmpty from "lodash/isEmpty";
import { getSevenDays } from "../../redux/scheduler/action";
import { fetchTypeOfServices, resetPasswordViaEmail } from "../../redux/technician/service";
import ConversationModalComponent from "../conversation/ConversationModalComponent";

import VendorSRHistoryComponent from "./vendors-history";
import { getClientId } from "../../redux/auth/actions";
import { TypeOfService } from "../../redux/technician/model";
interface ComponentProps {
  setType: any;
  selectEmail: any;
}
interface ReduxState {
  vendor: any;
  requests: any[];
  technician?: any;
  bankInformation?: any;
  experience?: any;
  availabilities?: any;
  typeOfServices: Array<TypeOfService>;
}
interface DispatchProps {
  postProfilePhoto: (profilePhoto: any, userId: number) => any;
  postPersonalInfo: (personalInfo: any, clientId: any) => any;
  postBankInfo: (bankInfo: any) => void;
  postServiceExperience: (serviceExperience: any) => void;
  postAvailability: (availability: any, technician: any) => void;
  resetPasswordViaEmail: (email: any) => any;
  getClientId: () => any;
  fetchTypeOfServices: () => any;
}
type Props = ComponentProps & ReduxState & DispatchProps;
const AddVendorTechnician: React.FC<Props> = (props): JSX.Element => {

  const wrapperRef = useRef<any>(null);

  const [form] = Form.useForm();

  useEffect(() => {
    initServiceTypes();
    initTechnicianInfo();
    getTypeOfServices();
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [requestLoadingIndex, setRequestLoadingIndex] = useState<number>(-1);
  const [expiryDateModal, setExpiryDateModal] = useState(false)
  const [saving, setSaving] = useState(false);
  const [submit, setSubmit] = useState(true);
  const [conversationType, setConversationType] = useState("");
  const [showConversationModal, setShowConversationModal] = useState<boolean>(false);
  const [conversationPayload, setConversationPayload] = useState();
  const [technician, setTechnician] = useState<any>(null);

  const [togglePicker, setTogglePicker] = useState({
    day: "",
    toggle: false,
    seq: 0,
  });

  const [profileUpload, setProfileUpload] = useState<any>({
    previewVisible: false,
    previewImage: "",
    fileList: [],
  });

  const [documentsUpload, setDocumentsUpload] = useState<any>({
    previewVisible: false,
    previewImage: "",
    fileList: [],
  });

  const [personalInfo, setPersonalInfo] = useState({
    id: null,
    firstName: null,
    middleName: null,
    lastName: null,
    mobileNumber: null,
    email: null,
    streetAddress: '',
    emergencyContact: '',
    emergencyContactNumber: '',
    city: null,
    state: null,
    zipCode: 0,
    vendor: {},
    driversLicence: '',
    expiryDate: '',
    password: null,
    temporaryPassword: true,
    photo: null,
    lastLoginDate: null,
    position: null,
    currentStatus: "AVAILABLE",
    lat: 0,
    lng: 0,
    payRate: 0,
    cancellationFee: 0,
    tripCharge: 0,
    skills: [] as any[],
    fullName: null,
    status: "PENDING_PROFILE",
    enabled: true,
    hashed: false,
    rating: 0,
  });

  const [bankInfo, setBankInfo] = useState({
    id: null,
    bankName: '',
    nameOnBankAccount: '',
    bankAccountNumber: '',
    bankRoutingNumber: '',
  });

  const [serviceExperience, setServiceExperience] = useState<any>({
    primaryService: null,
    eligibility: null,
    yearsExperience: 0,
    trainings: [],
    liftRequirements: null,
    productAssembly: null,
    inHomeAssembly: null,
    mobileSoftwareSystems: null,
    reliableTransportation: null,
    comfortableWithPpe: null,
  });

  const [availability, setAvailability] = useState<any>([
    {
      id: 0,
      day: "Sundays",
      value: 0,
      startTime: null,
      endTime: null,
      onCall: false,
    },
    {
      id: 0,
      day: "Mondays",
      value: 1,
      startTime: null,
      endTime: null,
      onCall: false,
    },
    {
      id: 0,
      day: "Tuesdays",
      value: 2,
      startTime: null,
      endTime: null,
      onCall: false,
    },
    {
      id: 0,
      day: "Wednesdays",
      value: 3,
      startTime: null,
      endTime: null,
      onCall: false,
    },
    {
      id: 0,
      day: "Thursdays",
      value: 4,
      startTime: null,
      endTime: null,
      onCall: false,
    },
    {
      id: 0,
      day: "Fridays",
      value: 5,
      startTime: null,
      endTime: null,
      onCall: false,
    },
    {
      id: 0,
      day: "Saturdays",
      value: 6,
      startTime: null,
      endTime: null,
      onCall: false,
    },
  ]);

  const [serviceTypes, setServiceTypes] = useState<string[]>([]);

  const initTechnicianInfo = () => {

    const getDay = (day: number) => {

      switch (day) {
        case 0: return 'Sundays';
        case 1: return 'Mondays';
        case 2: return 'Tuesdays';
        case 3: return 'Wednesdays';
        case 4: return 'Thursdays';
        case 5: return 'Fridays';
        case 6: return 'Saturdays';
        default: return 'Sundays';
      }
    }

    const { bankInformation, experience, availabilities, technician } = props;

    if (technician) {
      setPersonalInfo({
        id: technician.id,
        firstName: technician.firstName,
        middleName: technician.middleName,
        lastName: technician.lastName,
        mobileNumber: technician.mobileNumber,
        email: technician.email,
        streetAddress: technician.streetAddress,
        city: technician.city,
        state: technician.state,
        zipCode: technician.zipCode,
        emergencyContact: technician.emergencyContact,
        emergencyContactNumber: technician.emergencyContactNumber,
        vendor: props.vendor,
        driversLicence: technician.driversLicence,
        expiryDate: technician.expiryDate ? technician.expiryDate : moment().format('MMMM DD, YYYY hh:mm a'),
        password: null,
        temporaryPassword: false,
        photo: technician.photo,
        lastLoginDate: null,
        position: technician.position,
        currentStatus: technician.status,
        lat: 0,
        lng: 0,
        payRate: technician.payRate,
        cancellationFee: technician.cancellationFee,
        tripCharge: technician.tripCharge,
        skills: [] as any[],
        fullName: null,
        status: technician.status,
        enabled: true,
        hashed: false,
        rating: technician.rating,
      });

      if (bankInformation) {
        setBankInfo({
          ...bankInformation
        })
      }

      if (experience) {
        setServiceExperience({
          primaryService: experience.primaryService,
          eligibility: experience.eligibility,
          yearsExperience: experience.yearsExperience,
          trainings: experience.trainings,
          liftRequirements: experience.liftRequirements,
          productAssembly: experience.productAssembly,
          inHomeAssembly: experience.inHomeAssembly,
          mobileSoftwareSystems: experience.mobileSoftwareSystems,
          reliableTransportation: experience.reliableTransportation,
          comfortableWithPpe: experience.comfortableWithPpe,
        })
      }

      if (availabilities && availabilities.length > 0) {
        let availability: any[] = [];
        availabilities.forEach((item: any) => {
          availability.push({
            id: item.id,
            day: getDay(item.day),
            value: item.day,
            startTime: item.startTime,
            endTime: item.endTime,
            onCall: item.onCall,
            version: item.version,
          });
        });
        setAvailability(availability);

      } else {

        setAvailability([
          {
            id: 0,
            day: "Sundays",
            value: 0,
            startTime: null,
            endTime: null,
            onCall: false,
          },
          {
            id: 0,
            day: "Mondays",
            value: 1,
            startTime: null,
            endTime: null,
            onCall: false,
          },
          {
            id: 0,
            day: "Tuesdays",
            value: 2,
            startTime: null,
            endTime: null,
            onCall: false,
          },
          {
            id: 0,
            day: "Wednesdays",
            value: 3,
            startTime: null,
            endTime: null,
            onCall: false,
          },
          {
            id: 0,
            day: "Thursdays",
            value: 4,
            startTime: null,
            endTime: null,
            onCall: false,
          },
          {
            id: 0,
            day: "Fridays",
            value: 5,
            startTime: null,
            endTime: null,
            onCall: false,
          },
          {
            id: 0,
            day: "Saturdays",
            value: 6,
            startTime: null,
            endTime: null,
            onCall: false,
          },
        ]);
      }
    }
  }

  const handleExpiryDate = async (time: any) => {
    await setPersonalInfo({ ...personalInfo, expiryDate: moment(time).format("LL").toString() })
    setExpiryDateModal(!expiryDateModal)
  }

  const isSkillEnabled = (skill: string) => {
    if (props.technician.id) {
      const index = props.technician.skills.findIndex((serviceType: any) => { return serviceType.skill === skill });
      return index >= 0;
    } else if (props.vendor) {
      const index = props.vendor.serviceTypes.findIndex((serviceType: any) => { return serviceType.skill === skill });
      return index >= 0;
    } else {
      return false;
    }
  };

  const [skillSet, setSkillSet] = useState<any[]>([

  ]);

  useEffect(() => {
    if (props.typeOfServices.length != 0) {
      let skills: any = [];
      for (let i = 0; i < props.typeOfServices.length; i++) {
        const typeOfService: TypeOfService = props.typeOfServices[i];
        skills.push({
          title: typeOfService.name,
          checked: isSkillEnabled(typeOfService.name)
        })
      }
      setSkillSet(skills)
    }
  }, [props.typeOfServices])

  const getTypeOfServices = async () => {
    await props.fetchTypeOfServices();
  }

  const dataTraining = [
    {
      label: "Self-taught",
      value: "SELF_TAUGHT",
    },
    {
      label: "Apprenticeship",
      value: "APPRENTICESHIP",
    },
    {
      label: "Vocational School",
      value: "VOCATIONAL_SCHOOL",
    },
    {
      label: "Experience",
      value: "EXPERIENCE",
    },
    {
      label: "Other",
      value: "OTHER",
    },
  ];

  const handleProfilePreview = (file: any) => {
    setProfileUpload({
      ...profileUpload,
      previewImage: file.thumbUrl,
      previewVisible: true,
    });
  };

  const handleDocumentsPreview = (file: any) => {
    setDocumentsUpload({
      ...documentsUpload,
      previewImage: file.thumbUrl,
      previewVisible: true,
    });
  };

  const handleProfileUPload = ({ fileList }: any) => {
    setProfileUpload({ ...profileUpload, fileList });
  };

  const handleDocumentsUpload = ({ fileList }: any) => {
    setDocumentsUpload({ ...documentsUpload, fileList });
  };

  const handleResetTechnicianPassword = () => {
    props.resetPasswordViaEmail(personalInfo.email)
  }
  //   const renderSpinner = () => {
  //     return (
  //       <Spin
  //         style={{
  //           position: "absolute",
  //           display: "flex",
  //           justifyContent: "center",
  //           alignItems: "center",
  //           backgroundColor: "white",
  //           top: 0,
  //           right: 0,
  //           marginLeft: 240,
  //           left: 0,
  //           bottom: 0,
  //         }}
  //       />
  //     );
  //   };

  const radioValues = {
    yesNo: [
      {
        label: "Yes",
        value: true,
      },
      {
        label: "No",
        value: false,
      },
    ],
    noneSomeExpert: [
      {
        label: "None",
        value: "NONE",
      },
      {
        label: "Some",
        value: "SOME",
      },
      {
        label: "Expert",
        value: "EXPERT",
      },
    ],
    experience: [
      {
        label: "No Experience",
        value: "NO_EXPERIENCE",
      },
      {
        label: "Less than 1 year",
        value: "LESS_THAN_ONE",
      },
      {
        label: "1 - 3 years",
        value: "ONE_TO_THREE",
      },
      {
        label: "5+ years",
        value: "FIVE_PLUS",
      },
    ],
  };

  const initServiceTypes = () => {
    if (props.vendor) {
      props.vendor.serviceTypes.forEach((serviceType: any) => {
        serviceTypes.push(serviceType.skill);
      });
      setServiceTypes(serviceTypes);
    }
  }

  const onChangeValuePersonalInfo = (e: any) => {

    if (e.target.value !== "") {
      setPersonalInfo({
        ...personalInfo,
        [e.target.name]: e.target.value,
      });
    } else {
      setPersonalInfo({
        ...personalInfo,
        [e.target.name]: null,
      });
    }
  };

  const onChangeValueBankInfo = (e: any) => {
    if (e.target.value !== "") {
      setBankInfo({
        ...bankInfo,
        [e.target.name]: e.target.value,
      });
    } else {
      setBankInfo({
        ...bankInfo,
        [e.target.name]: null,
      });
    }
  };

  const onChangeValueServiceExperience = (e: any) => {
    if (e.target.value !== "") {
      setServiceExperience({
        ...serviceExperience,
        [e.target.name]: e.target.value,
      });
    } else {
      setServiceExperience({
        ...serviceExperience,
        [e.target.name]: null,
      });
    }
  };

  const onChangeTraining = (e: any) => {
    const existingTraining = serviceExperience.trainings.find(
      (item: any) => item.trainingType === e.target.value
    );

    if (existingTraining) {
      const removeTraining = serviceExperience.trainings.filter(
        (item: any) => e.target.value !== item.trainingType
      );
      setServiceExperience({ ...serviceExperience, trainings: removeTraining });
    } else {
      const newTraining = {
        trainingType: e.target.value,
      };
      setServiceExperience({
        ...serviceExperience,
        trainings: [...serviceExperience.trainings, newTraining],
      });
    }
  };

  const handleChangeDateTime = (time: any) => {
    const newTime = moment(time).format("LT");
    const currentDate = moment();
    const weekStart = currentDate
      .clone()
      .startOf("isoWeek")
      .format("YYYY-MM-DD");
    const momentObj = moment(weekStart + newTime, "YYYY-MM-DDLT");
    const dateTime = momentObj.format("YYYY-MM-DDTHH:mm:ss");
    const result = availability.map((item: any, index: any) => {
      if (item.day === togglePicker.day) {
        if (time !== null) {
          if (togglePicker.seq === 0) {
            return {
              ...item,
              startTime: moment(dateTime).add(index, "days"),
            };
          } else {
            return {
              ...item,
              endTime: moment(dateTime).add(index, "days"),
            };
          }
        } else {
          return {
            ...item,
            startTime: time,
            endTime: time,
          };
        }
      } else {
        return item;
      }
    });
    setAvailability(result);
    setTogglePicker({
      day: "",
      toggle: false,
      seq: 0,
    });
  };

  const toggleSwitch = (id: number) => {
    const newArray = availability.map((item: any) => {
      if (item.value === id) {
        return {
          ...item,
          onCall: item.onCall ? false : true,
        };
      } else {
        return item;
      }
    });
    setAvailability(newArray);
  };

  const findTraining = (value: any) => {

    const findTrainingIndex = serviceExperience.trainings.findIndex(
      (item: any) => item.trainingType === value
    );
    return findTrainingIndex >= 0;
  };

  const sliceTime = (time: any) => {
    const timeFormat = moment(time).tz('America/Chicago').format("LT");
    const newTime = timeFormat.slice(0, -2);
    const timeClock = timeFormat.slice(-2);
    return {
      newTime,
      timeClock,
    };
  };

  const onChangeSkills = (title: string) => {

    let index = skillSet.findIndex((skill: any) => { return skill.title == title });
    skillSet[index].checked = !skillSet[index].checked;
    setSkillSet(skillSet);
  }

  const handleClickOutside = async (event: any) => {
    if (wrapperRef.current && wrapperRef.current.contains(event.target)) {
      await moment.tz.setDefault('America/Chicago')
      setTogglePicker({
        day: "",
        toggle: false,
        seq: 0,
      });
      setExpiryDateModal(false)
    }
  };

  const submitVendorData = async () => {

    let error = false
    const { firstName, lastName, mobileNumber, email } = personalInfo;
    if (
      firstName !== null &&
      lastName !== null &&
      mobileNumber !== null &&
      email !== null
    ) {

      setSaving(true);

      let skills: any[] = [];
      skillSet.forEach((skill: any) => {
        if (skill.checked) skills.push({ skill: skill.title });
      });

      const clientId: any = props.getClientId();

      if (props.vendor) {
        personalInfo.vendor = props.vendor;
      } else {
        let companyAddress = '';
        if (personalInfo.streetAddress) companyAddress += (personalInfo.streetAddress + ' ');
        if (personalInfo.city) companyAddress += (personalInfo.city + ' ');
        if (personalInfo.state) companyAddress += (personalInfo.state + ' ');
        if (personalInfo.zipCode) companyAddress += (personalInfo.zipCode);

        let newVendor = {
          bankAccountNumber: bankInfo.bankAccountNumber,
          bankName: bankInfo.bankName,
          bankRoutingNumber: bankInfo.bankRoutingNumber,
          cancellationFee: personalInfo.cancellationFee,
          clientId: clientId,
          companyAddress: companyAddress,
          companyName: personalInfo.firstName + ' ' + personalInfo.lastName,
          contactName: personalInfo.firstName + ' ' + personalInfo.lastName,
          contactNumber: personalInfo.mobileNumber,
          email: personalInfo.email,
          enabled: true,
          nameOnBankAccount: bankInfo.nameOnBankAccount,
          payRate: personalInfo.payRate,
          status: 'ACTIVE',
          tripCharge: personalInfo.tripCharge,
          vendorContactNumber: personalInfo.mobileNumber,
          vendorInvoiceEmail: personalInfo.email,
          serviceTypes: skills,
        };
        personalInfo.vendor = newVendor;
      }

      personalInfo.skills = skills;

      const result = await props.postPersonalInfo({
        ...personalInfo,
        vendor: props.vendor !== null ? props.vendor : personalInfo.vendor,
      }, clientId);

      // If user creation is successful...
      if (!result.code) {

        if (profileUpload.fileList.length !== 0) {
          const response = await props.postProfilePhoto(
            profileUpload.fileList[0].originFileObj,
            result.id
          );
          if (response.status === 500) {
            error = true;
            message.error("Profile Photo exceeds the maximum size (1048576 bytes)");
          } else {
            error = false
          }
        }

        props.postBankInfo({ ...bankInfo, technician: result });
        props.postServiceExperience({ ...serviceExperience, technician: result });
        props.postAvailability(availability, result);

        if (!error) {
          setPersonalInfo({
            id: null,
            firstName: null,
            middleName: null,
            lastName: null,
            mobileNumber: null,
            email: null,
            streetAddress: '',
            city: null,
            emergencyContact: '',
            emergencyContactNumber: '',
            state: null,
            zipCode: 0,
            vendor: {},
            driversLicence: '',
            expiryDate: '',
            password: null,
            temporaryPassword: true,
            photo: null,
            lastLoginDate: null,
            position: null,
            currentStatus: "AVAILABLE",
            lat: 0,
            lng: 0,
            payRate: 0,
            cancellationFee: 0,
            tripCharge: 0,
            skills: skills,
            fullName: null,
            status: "PENDING_PROFILE",
            enabled: true,
            hashed: false,
            rating: 0,
          });

          setBankInfo({
            id: null,
            bankName: '',
            nameOnBankAccount: '',
            bankAccountNumber: '',
            bankRoutingNumber: '',
          });

          setServiceExperience({
            primaryService: null,
            eligibility: null,
            yearsExperience: null,
            trainings: [],
            liftRequirements: null,
            productAssembly: null,
            inHomeAssembly: null,
            mobileSoftwareSystems: null,
            reliableTransportation: null,
            comfortableWithPpe: null,
          });

          setAvailability([
            {
              id: 0,
              day: "Sundays",
              value: 0,
              startTime: null,
              endTime: null,
              onCall: false,
            },
            {
              id: 0,
              day: "Mondays",
              value: 1,
              startTime: null,
              endTime: null,
              onCall: false,
            },
            {
              id: 0,
              day: "Tuesdays",
              value: 2,
              startTime: null,
              endTime: null,
              onCall: false,
            },
            {
              id: 0,
              day: "Wednesdays",
              value: 3,
              startTime: null,
              endTime: null,
              onCall: false,
            },
            {
              id: 0,
              day: "Thursdays",
              value: 4,
              startTime: null,
              endTime: null,
              onCall: false,
            },
            {
              id: 0,
              day: "Fridays",
              value: 5,
              startTime: null,
              endTime: null,
              onCall: false,
            },
            {
              id: 0,
              day: "Saturdays",
              value: 6,
              startTime: null,
              endTime: null,
              onCall: false,
            },
          ]);

          setProfileUpload({
            previewVisible: false,
            previewImage: "",
            fileList: [],
          });

          setDocumentsUpload({
            previewVisible: false,
            previewImage: "",
            fileList: [],
          });

          setTogglePicker({
            day: "",
            toggle: false,
            seq: 0,
          });
          message.success("The vendor technician was saved successfully");
          props.setType("vendorList");
        }
      } else {
        // console.log('result.error', result.message);
        message.error(result.message);
      }

      setSaving(false);

    } else {
      setSubmit(false);
      message.error("Please fill all the required fields");
    }
  };

  // const getServiceType = async (request:any) => {
  //   let value = request.requestFormValues.find((value:any) => { return value.field.shortName === 'SVT'});
  //   if (!value) value = request.requestFormValues.find((value:any) => { return value.field.shortName === 'ITM'});
  //   return value.value;
  // } 

  const getDate = (request: any) => {
    if (request.scheduleDateTimeStamp) {
      const date = moment(request.scheduleDateTimeStamp).format('MMMM DD, YYYY');
      return date;
    } else {
      return '-';
    }
  }

  return (
    <div className="add-vendor-container" ref={wrapperRef}>
      <div
        onClick={() => props.setType("vendorList")}
        style={{ color: "#5d97bc", cursor: "pointer" }}
      >
        <Image
          style={{ width: 30, marginRight: 10 }}
          preview={false}
          src={BackBlueArrow}
        />
        Back
      </div>
      {/*
      <div className="add-vendor-button-right">
        <Button
          style={{
            marginRight: 20,
            color: "white",
            backgroundColor: "#3a78b3",
          }}
        >
          <p
            style={{ opacity: 0.7 }}
            onClick={() => props.setType("addVendor")}
          >
            +Add New Vendor
          </p>
        </Button>
        <Button className="button-add">
          <p style={{ opacity: 0.7 }}>+Add New Vendor Technician</p>
        </Button>
      </div>
        */}
      <Form form={form}>
        <div className="add-vendor-company-info">
          <h3 style={{ fontWeight: 900 }}>Personal Information</h3>
          <div className="add-vendor-company-info-details">
            <div className="add-vendor-company-sub-details">
              <Input
                defaultValue={props.technician && props.technician.id ? props.technician.id : null}
                size="small"
                name="id"
                disabled={true}
                placeholder="Tech ID"
                onChange={(e) => { }}
              />
              <Input
                size="small"
                placeholder="Company Name"
                style={{ marginTop: 10 }}
                disabled={true}
                value={props.vendor ? props.vendor.companyName : null}
                onChange={(e) => console.log(e.target.value)}
              />
              <Input
                defaultValue={props.technician.firstName}
                name="firstName"
                placeholder="First Name"
                style={{ marginTop: 10 }}
                className={
                  submit === false && personalInfo.firstName === null
                    ? "required-input-msg"
                    : ""
                }
                onChange={(e) => onChangeValuePersonalInfo(e)}
              />
              <Input
                defaultValue={props.technician.middleName}
                name="middleName"
                placeholder="Middle Name"
                style={{ marginTop: 10 }}
                className={
                  submit === false && personalInfo.middleName === null
                    ? "required-input-msg"
                    : ""
                }
                onChange={(e) => onChangeValuePersonalInfo(e)}
              />
              <Input
                defaultValue={props.technician.lastName}
                size="small"
                name="lastName"
                placeholder="Last Name"
                style={{ marginTop: 10 }}
                className={
                  submit === false && personalInfo.lastName === null
                    ? "required-input-msg"
                    : ""
                }
                onChange={(e) => onChangeValuePersonalInfo(e)}
              />
              <Input
                defaultValue={props.technician.mobileNumber}
                size="small"
                name="mobileNumber"
                placeholder="Phone Number"
                style={{ marginTop: 10 }}
                className={
                  submit === false && personalInfo.mobileNumber === null
                    ? "required-input-msg"
                    : ""
                }
                onChange={(e) => onChangeValuePersonalInfo(e)}
              />
              <Input
                defaultValue={props.technician.email}
                size="small"
                name="email"
                placeholder="Email Address"
                style={{ marginTop: 10 }}
                className={
                  submit === false && personalInfo.email === null
                    ? "required-input-msg"
                    : ""
                }
                onChange={(e) => onChangeValuePersonalInfo(e)}
              />
            </div>
            <div className="add-vendor-company-sub-details">
              <Input.TextArea
                value={personalInfo.streetAddress}
                name="streetAddress"
                placeholder="Mailing Address"
                rows={5}
                onChange={(e) => onChangeValuePersonalInfo(e)}
              />
              <Input
                value={personalInfo.zipCode}
                size="small"
                name="zipCode"
                placeholder="Zip Code"
                style={{ marginTop: 10 }}
                onChange={(e) => onChangeValuePersonalInfo(e)}
              />
              <Input
                value={personalInfo.driversLicence}
                size="small"
                name="driversLicence"
                placeholder="Driver's Licence No."
                style={{ marginTop: 10 }}
                onChange={(e) => onChangeValuePersonalInfo(e)}
              />
              <div className="ant-timepicker">
                <div style={{
                  border: '2px solid lightgrey',
                  marginTop: '10px',
                  padding: '0px 10px 0px 8px',
                  fontWeight: 500,
                  cursor: 'pointer',
                  color: 'grey'
                }}
                  onClick={() => setExpiryDateModal(!expiryDateModal)}
                >
                  {personalInfo.expiryDate !== undefined ? personalInfo.expiryDate : "Expiry Date"}
                </div>
                <DatePicker
                  onChange={(time: any, timeString: any) =>
                    handleExpiryDate(time)
                  }
                  use12Hours
                  format="h:mm A"
                  size="large"
                  open={expiryDateModal}
                  suffixIcon
                  style={{
                    width: "150px",
                    height: "0px",
                    marginTop: -20,
                  }}
                />
              </div>
            </div>
            <div style={{ width: "500px" }}>
              <div
                className="upload-container"
                style={{
                  width: "410px",
                  padding: 10,
                  cursor: "pointer",
                }}
              >
                <Upload.Dragger
                  listType="picture"
                  multiple={false}
                  fileList={profileUpload.fileList}
                  onPreview={handleProfilePreview}
                  style={{ background: "transparent", border: "0px" }}
                  onChange={handleProfileUPload}
                  beforeUpload={() => false}
                >
                  <p style={{ opacity: 0.8 }}>Upload Profile Pic</p>
                  <CloudUploadOutlined
                    style={{ fontSize: "70px", marginTop: 10, color: "grey" }}
                  />
                </Upload.Dragger>
              </div>
              <div style={{ marginTop: 30, display: "flex" }}>
                <div style={{ marginRight: 30, lineHeight: 0.3 }}>
                  <p style={{ opacity: 0.7 }}>Tech Rating:</p>
                  <Rate
                    allowHalf
                    value={personalInfo.rating}
                    style={{ color: "#3a78b3" }}
                    onChange={(e) => setPersonalInfo({ ...personalInfo, rating: parseFloat(e.toFixed()) })}
                  />
                </div>
                <div style={{ marginRight: 30, lineHeight: 0.3 }}>
                  <p style={{ opacity: 0.7 }}>Tech chat:</p>
                  <MessageOutlined
                    onClick={() => {
                      setRequestLoadingIndex(0);
                      setConversationType("chat-with-global");
                      setConversationPayload(undefined);
                      setTechnician(props.technician);
                      setShowConversationModal(true);
                      localStorage.setItem("chatConversationModal", "IsOpen");
                    }}
                    style={{ width: 60, color: "#3a78b3", fontSize: '24px' }}
                  />
                </div>
              </div>
              <div style={{ marginTop: 30, display: 'flex', flexDirection: 'row' }}>
                <div
                  style={{ marginRight: 30, lineHeight: 0.3, textAlign: 'center' }}
                  className="vendor-status"
                >
                  <p style={{ opacity: 0.7 }}>Tech Status:</p>
                  <Select
                    value={personalInfo.status}
                    suffixIcon={
                      <CaretDownOutlined
                        style={{ color: "white", fontSize: "15px" }}
                      />
                    }
                    size="small"
                    onChange={(e) => {
                      setPersonalInfo({ ...personalInfo, status: e })
                    }
                    }
                  >
                    <Select.Option value="PENDING_PROFILE">Pending Tech Profile</Select.Option>
                    <Select.Option value="COMPLETE_PROFILE">Tech Profile Complete</Select.Option>
                    <Select.Option value="ACTIVE">Active</Select.Option>
                    <Select.Option value="DEACTIVATED">Deactivated</Select.Option>
                  </Select>
                </div>
                <div style={{ lineHeight: 0.3, marginRight: 30, textAlign: 'center' }}>
                  <p style={{ opacity: 0.7 }}>Reset Password:</p>
                  <Button
                    style={{
                      height: "25px",
                      width: "100px",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      fontWeight: "bold",
                      color: "white",
                      backgroundColor: "#ff0000",
                      fontSize: "1.7vh",
                    }}
                    onClick={() => handleResetTechnicianPassword()}
                  >
                    Reset
                  </Button>
                </div>
                <div style={{ lineHeight: 0.3, textAlign: 'center' }}>
                  <p style={{ opacity: 0.7 }}>Set Password:</p>
                  <Button
                    style={{
                      height: "25px",
                      width: "100px",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      fontWeight: "bold",
                      color: "white",
                      backgroundColor: "#ff0000",
                      fontSize: "1.7vh",
                    }}
                    onClick={() => {
                      props.selectEmail(personalInfo.email)
                      props.setType("setTechnicianPassword")
                    }}
                  >
                    Set
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ marginTop: 48 }}>
          <h3 style={{ fontWeight: 900 }}>Type of Services</h3>
          <div style={{ display: "flex", marginTop: 20, marginLeft: 50 }}>
            <List
              grid={{ gutter: 16, column: skillSet.length > 4 ? 5 : 0 }}
              dataSource={skillSet}
              renderItem={(item, index) => (
                <List.Item key={index}>
                  <Checkbox defaultChecked={skillSet[index].checked} onClick={(e) => onChangeSkills(item.title)}>{item.title}</Checkbox>
                </List.Item>
              )}
            />
          </div>
        </div>

        <div style={{ marginTop: 48 }}>
          <h3 style={{ fontWeight: 900 }}>Service and Experience</h3>
          <div style={{ marginTop: 20, marginLeft: 50 }}>
            <div style={{ display: "flex" }}>
              <p>Are you legally eligible to work in the United States?</p>
              <Radio.Group
                value={serviceExperience.eligibility}
                name="eligibility"
                onChange={(e) => onChangeValueServiceExperience(e)}
                style={{ marginLeft: "60px" }}
              >
                {radioValues.yesNo.map((item, index) => {
                  return (
                    <Radio key={index} value={item.value}>
                      {item.label}
                    </Radio>
                  );
                })}
              </Radio.Group>
            </div>
            <div style={{ display: "flex" }}>
              <p>How many years of paid experience do yo have?</p>
              <Radio.Group
                value={serviceExperience.yearsExperience}
                name="yearsExperience"
                onChange={(e) => onChangeValueServiceExperience(e)}
                style={{ marginLeft: "85px" }}
              >
                {radioValues.experience.map((item, index) => {
                  return (
                    <Radio key={index} value={item.value}>
                      {item.label}
                    </Radio>
                  );
                })}
              </Radio.Group>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                Where did you receive your training? Check all that apply
              </p>
              <List
                style={{ flex: 2, marginRight: "55px" }}
                grid={{ column: 5 }}
                dataSource={dataTraining}
                renderItem={(item, index) => (
                  <List.Item key={index}>
                    <Checkbox
                      name={item.label}
                      value={item.value}
                      checked={findTraining(item.value)}
                      onChange={(e) => onChangeTraining(e)}
                    >
                      {item.label}
                    </Checkbox>
                  </List.Item>
                )}
              />
            </div>
            <div style={{ display: "flex" }}>
              <p>
                Are you physically able to lift and manage heavy and bulky goods
                (up to 50-200 lbs.) within a customer's home?*
              </p>
              <Radio.Group
                value={serviceExperience.liftRequirements}
                name="liftRequirements"
                onChange={(e) => onChangeValueServiceExperience(e)}
                style={{ marginLeft: "30px" }}
              >
                {radioValues.yesNo.map((item, index) => {
                  return (
                    <Radio key={index} value={item.value}>
                      {item.label}
                    </Radio>
                  );
                })}
              </Radio.Group>
            </div>
            <div style={{ display: "flex" }}>
              <p>
                Do you have experience assembling products such as treadmills
                and furniture?*
              </p>
              <Radio.Group
                value={serviceExperience.productAssembly}
                name="productAssembly"
                onChange={(e) => onChangeValueServiceExperience(e)}
                style={{ marginLeft: "30px" }}
              >
                {radioValues.yesNo.map((item, index) => {
                  return (
                    <Radio key={index} value={item.value}>
                      {item.label}
                    </Radio>
                  );
                })}
              </Radio.Group>
            </div>
            <div style={{ display: "flex" }}>
              <p>
                Have you performed in-home assembly or installation services in
                the past?*
              </p>
              <Radio.Group
                value={serviceExperience.inHomeAssembly}
                name="inHomeAssembly"
                onChange={(e) => onChangeValueServiceExperience(e)}
                style={{ marginLeft: "56px" }}
              >
                {radioValues.noneSomeExpert.map((item, index) => {
                  return (
                    <Radio key={index} value={item.value}>
                      {item.label}
                    </Radio>
                  );
                })}
              </Radio.Group>
            </div>
            <div style={{ display: "flex" }}>
              <p>
                Do you have experience working in mobile systems to enter time,
                accept delivery routes, obtain customer signatures and photos or
                working in a point of sale system?*
              </p>
              <Radio.Group
                value={serviceExperience.mobileSoftwareSystems}
                name="mobileSoftwareSystems"
                onChange={(e) => onChangeValueServiceExperience(e)}
                style={{ marginLeft: "30px" }}
              >
                {radioValues.noneSomeExpert.map((item, index) => {
                  return (
                    <Radio key={index} value={item.value}>
                      {item.label}
                    </Radio>
                  );
                })}
              </Radio.Group>
            </div>
            <div style={{ display: "flex" }}>
              <p>
                Do you have insured and reliable transporation to travel and
                from customer work sites?*
              </p>
              <Radio.Group
                value={serviceExperience.reliableTransportation}
                name="reliableTransportation"
                onChange={(e) => onChangeValueServiceExperience(e)}
                style={{ marginLeft: "30px" }}
              >
                {radioValues.yesNo.map((item, index) => {
                  return (
                    <Radio key={index} value={item.value}>
                      {item.label}
                    </Radio>
                  );
                })}
              </Radio.Group>
            </div>
            <div style={{ display: "flex" }}>
              <p>
                Are you comfortable using PPE (face mask and face shield) while
                at the customer's location during the assembly/installation?*
              </p>
              <Radio.Group
                value={serviceExperience.comfortableWithPpe}
                name="comfortableWithPpe"
                onChange={(e) => onChangeValueServiceExperience(e)}
                style={{ marginLeft: "30px" }}
              >
                {radioValues.yesNo.map((item, index) => {
                  return (
                    <Radio key={index} value={item.value}>
                      {item.label}
                    </Radio>
                  );
                })}
              </Radio.Group>
            </div>
          </div>
        </div>

        <div style={{ marginTop: 50 }}>
          <h3 style={{ fontWeight: 900 }}>Documents</h3>
          <div style={{ width: "950px" }}>
            <div
              className="upload-container"
              style={{
                padding: 10,
                cursor: "pointer",
              }}
            >
              <Upload.Dragger
                listType="picture"
                fileList={documentsUpload.fileList}
                onPreview={handleDocumentsPreview}
                style={{ background: "transparent", border: "0px" }}
                onChange={handleDocumentsUpload}
                beforeUpload={() => false}
              >
                <p style={{ opacity: 0.8 }}>
                  Upload pertinent documents such as w9s, Vendor insurance,
                  Agreements/Contracts signed
                </p>
                <CloudUploadOutlined
                  style={{
                    fontSize: "70px",
                    color: "grey",
                    marginTop: 20,
                    alignSelf: "center",
                  }}
                />
              </Upload.Dragger>
            </div>
          </div>
        </div>

        <div style={{ marginTop: 20 }}>
          <h3 style={{ fontWeight: 900 }}>Company Billing Information</h3>
          <div style={{ marginTop: 20 }}>
            <div style={{ display: "flex", marginTop: 20 }}>
              <Input
                value={bankInfo.bankName}
                size="small"
                name="bankName"
                placeholder="Bank Name"
                style={{ marginRight: 20, width: "250px" }}
                onChange={(e) => onChangeValueBankInfo(e)}
              />
              <Input
                value={bankInfo.nameOnBankAccount}
                size="small"
                name="nameOnBankAccount"
                placeholder="Name on Bank Account"
                style={{ marginRight: 20, width: "250px" }}
                onChange={(e) => onChangeValueBankInfo(e)}
              />
              <Input
                value={bankInfo.bankAccountNumber}
                size="small"
                name="bankAccountNumber"
                placeholder="Bank Account No."
                style={{ marginRight: 20, width: "250px" }}
                onChange={(e) => onChangeValueBankInfo(e)}
              />
              <Input
                value={bankInfo.bankRoutingNumber}
                size="small"
                name="bankRoutingNumber"
                placeholder="Bank Routing No."
                style={{ width: "250px" }}
                onChange={(e) => onChangeValueBankInfo(e)}
              />
            </div>
          </div>
        </div>

        <div style={{ marginTop: 20 }}>
          <h3 style={{ fontWeight: 900 }}>Pay Rate</h3>
          <div style={{ display: "flex", marginTop: 20 }}>
            <Input
              value={personalInfo.payRate}
              size="small"
              name="payRate"
              placeholder="Pay Rate ($)"
              style={{ marginRight: 40, width: "250px" }}
              onChange={(e) => { onChangeValuePersonalInfo(e) }}
            />
            <Input
              value={personalInfo.cancellationFee}
              size="small"
              name="cancellationFee"
              placeholder="Cancellation Fee ($)"
              style={{ marginRight: 40, width: "250px" }}
              onChange={(e) => onChangeValuePersonalInfo(e)}
            />
            <Input
              value={personalInfo.tripCharge}
              size="small"
              name="tripCharge"
              placeholder="Trip Charge ($)"
              style={{ width: "250px" }}
              onChange={(e) => onChangeValuePersonalInfo(e)}
            />
          </div>
        </div>

        <div style={{ marginTop: 20, color: "gray" }}>
          <h3 style={{ fontWeight: 900 }}>My Availability</h3>
          <div style={{ display: "flex", padding: 30 }}>
            <div style={{ marginTop: "45px", marginRight: "20px" }}>
              <p>From</p>
              <p style={{ marginTop: "30px" }}>To</p>
              <p style={{ marginTop: "30px" }}>On-call</p>
            </div>
            {availability.map((item: any, index: any) => {
              return (
                <div
                  key={index}
                  style={{ marginRight: "20px", cursor: "pointer" }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <p style={{ fontWeight: "bold" }}>{item.day}</p>
                  </div>
                  <div className="ant-timepicker">
                    <div
                      style={{
                        border: "1px solid lightgray",
                        fontSize: "25px",
                        fontWeight: "bold",
                        color: "grey",
                        padding: "0px 10px 0px 10px",
                        textAlign: "center",
                      }}
                      onClick={() =>
                        setTogglePicker({
                          day: item.day,
                          toggle: true,
                          seq: 0,
                        })
                      }
                      id={`${item.value}0`}
                    >
                      {item.startTime !== null
                        ? `${sliceTime(item.startTime).newTime}${sliceTime(item.startTime).timeClock
                        }`
                        : "OFF"}
                    </div>
                    <TimePicker
                      onChange={(time: any, timeString: any) =>
                        handleChangeDateTime(time)
                      }
                      use12Hours
                      format="h:mm A"
                      size="large"
                      minuteStep={30}
                      open={
                        togglePicker.day === item.day &&
                          togglePicker.toggle &&
                          togglePicker.seq === 0
                          ? true
                          : false
                      }
                      suffixIcon
                      style={{
                        width: "150px",
                        height: "0px",
                        marginTop: -20,
                        position: "absolute",
                        marginBottom: 50,
                      }}
                      renderExtraFooter={() => (
                        <Button
                          size="small"
                          type="primary"
                          onClick={() => handleChangeDateTime(null)}
                        >
                          OFF
                        </Button>
                      )}
                    />
                  </div>
                  <div className="ant-timepicker" style={{ marginTop: "10px" }}>
                    <div
                      style={{
                        border: "1px solid lightgray",
                        fontSize: "25px",
                        width: "140px",
                        fontWeight: "bold",
                        color: "grey",
                        padding: "0px 10px 0px 10px",
                        textAlign: "center",
                      }}
                      onClick={() =>
                        setTogglePicker({
                          day: item.day,
                          toggle: true,
                          seq: 1,
                        })
                      }
                      id={`${item.value}1`}
                    >
                      {item.endTime !== null
                        ? `${sliceTime(item.endTime).newTime}${sliceTime(item.endTime).timeClock
                        }`
                        : "OFF"}
                    </div>
                    <TimePicker
                      onChange={(time: any, timeString: any) =>
                        handleChangeDateTime(time)
                      }
                      use12Hours
                      format="h:mm A"
                      size="large"
                      open={
                        togglePicker.day === item.day &&
                          togglePicker.toggle &&
                          togglePicker.seq === 1
                          ? true
                          : false
                      }
                      suffixIcon
                      style={{
                        width: "150px",
                        height: "0px",
                        marginTop: -20,
                        position: "absolute",
                      }}
                      renderExtraFooter={() => (
                        <Button
                          size="small"
                          type="primary"
                          onClick={() => handleChangeDateTime(null)}
                        >
                          OFF
                        </Button>
                      )}
                    />
                  </div>
                  <div
                    style={{
                      marginTop: "20px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Switch
                      checked={item.onCall}
                      onChange={() => toggleSwitch(item.value)}
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div style={{ marginTop: 20 }}>
          <h3 style={{ fontWeight: 900 }}>Emergency Contact</h3>
          <div className="disp-flex">
            <Input
              value={personalInfo.emergencyContact}
              size="small"
              name="emergencyContact"
              placeholder="Emergency Contact Name"
              style={{ marginRight: 40, width: "250px" }}
              onChange={(e) => onChangeValuePersonalInfo(e)}
            />
            <Input
              value={personalInfo.emergencyContactNumber}
              size="small"
              name="emergencyContactNumber"
              placeholder="Emergency Contact Number"
              style={{ marginRight: 40, width: "250px" }}
              onChange={(e) => onChangeValuePersonalInfo(e)}
            />
          </div>
        </div>

        {props.technician &&
          <div style={{ marginTop: 20 }}>
            <h3 style={{ fontWeight: 900 }}>History of Service Requests</h3>
            <div style={{ marginTop: 20, marginLeft: 50, color: "grey" }}>
              <VendorSRHistoryComponent
                loadingIndex={requestLoadingIndex}
                user={props.technician}
                type={'vendor-tech-list'}
                setConversationType={setConversationType}
                setRequestLoadingIndex={setRequestLoadingIndex}
                setConversationPayload={setConversationPayload}
                setTechnician={setTechnician}
                setShowConversationModal={setShowConversationModal}
              />
            </div>
          </div>}

        <ConversationModalComponent
          show={showConversationModal}
          setShow={setShowConversationModal}
          type={conversationType}
          payload={conversationPayload}
          setLoadingIndex={setRequestLoadingIndex}
          user={technician}
        />
        <div
          style={{ marginTop: 40, display: "flex", justifyContent: "center" }}
        >
          <Button
            style={{
              color: "white",
              backgroundColor: "#ff0000",
              borderRadius: 5,
              width: "120px",
              fontSize: 12,
              fontWeight: "bold",
              marginRight: 20,
            }}
          >
            <p
              style={{ opacity: 0.8, marginTop: "3px" }}
              onClick={() => props.setType("vendorList")}
            >
              CANCEL
            </p>
          </Button>
          <Button
            style={{
              color: "white",
              backgroundColor: "#00d400",
              borderRadius: 5,
              width: "120px",
              fontSize: 12,
              fontWeight: "bold",
            }}
            onClick={() => submitVendorData()}
            loading={saving}
          >
            <p style={{ opacity: 0.8, marginTop: "3px" }}>SAVE</p>
          </Button>
        </div>
        <div></div>
        <div></div>
      </Form>
    </div>
  );
};

const mapStateToProps = (states: RootState): ReduxState => {
  return {
    vendor: states.vendorsReducer.state.selectedVendor,
    requests: states.technicianReducer.state.completedRequests,
    technician: states.technicianReducer.state.technician,
    bankInformation: states.technicianReducer.state.bankInformation,
    experience: states.technicianReducer.state.serviceExperience,
    availabilities: states.technicianReducer.state.availabilities,
    typeOfServices: states.technicianReducer.state.typeOfServices,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>
): DispatchProps => {
  return {
    resetPasswordViaEmail: (email: any) => dispatch(resetPasswordViaEmail(email)),
    postProfilePhoto: (profilePhoto: any, userId: number) =>
      postTechnicianProfilePhoto(profilePhoto, userId),
    postPersonalInfo: (personalInfo: any, clientId: any) =>
      postVendorTechnicianPersonalInfo(personalInfo, clientId),
    postBankInfo: (bankInfo: any) =>
      dispatch(postVendorTechnicianBankInfo(bankInfo)),
    postServiceExperience: (serviceExperience: any) =>
      dispatch(postVendorTechnicianServiceExperience(serviceExperience)),
    postAvailability: (availability: any, technician: any) =>
      dispatch(postVendorTechnicianAvailability(availability, technician)),
    getClientId: () => dispatch(getClientId()),
    fetchTypeOfServices: () => dispatch(fetchTypeOfServices())
  };
};

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(AddVendorTechnician)
);
