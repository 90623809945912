import React, { useEffect } from "react";
import { Button, Modal, Form, Empty, message } from "antd";
import { useState, useCallback } from "react";
import HttpServiceRequest from "../../../services/service-request";
import "../../../assets/stylesheets/layout-style/modal-layout.scss";
import NoStatus from "../../../assets/fsa-icons/svg/nostatus.svg";
import OnMyWay from "../../../assets/fsa-icons/svg/onmyway.svg";
import OnSite from "../../../assets/fsa-icons/svg/onsite.svg";
import OnSiteLate from "../../../assets/images/onsitelate.png";
import JobCompleted from "../../../assets/fsa-icons/svg/jobcompleted.svg";
import JobCompletedLate from "../../../assets/images/jclate.png";
import CompletionForm from "../../../assets/fsa-icons/svg/completionform.svg";
import PlayButton from "../../../assets/images/play.jpg";
import {
  MessageFilled,
  PhoneFilled,
  HomeFilled,
  ClockCircleFilled,
  EnvironmentFilled,
  FileTextFilled,
} from "@ant-design/icons";
import CircleIcon from "@mui/icons-material/Circle";
import { ArrowLeftOutlined, CloseOutlined } from "@ant-design/icons";
import ImageViewer from "react-simple-image-viewer";
import ReactPlayer from "react-player";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux";
import _isUndefined from "lodash/isUndefined";
import _isNull from "lodash/isNull";


import ConversationModalComponent from "../../conversation/ConversationModalComponent";

import { SERVER_URL } from "../../../configs/service-config";

import {
  ServiceRequest,
  Photo,
  Video,
} from "../../../redux/service-request/model";
import { Technician } from "../../../redux/technician/model";
import moment from "moment";
import ServiceRequestMap from "../../maps/service-request-map";
import { getSRCloseForm } from "../../../redux/service-request/service";

interface StateProps { }

interface DispatchProps {
  getSRCloseForm: (id: any) => any;
}
type Props = StateProps & DispatchProps;

const ServiceRequestDetailsLayout: React.FC<Props> = (props): JSX.Element => {
  const [form] = Form.useForm();

  const [isOpen, actionModal] = useState(false);
  const [isCloseFormOpen, setCloseFormOpen] = useState(false);
  const [serviceRequest, setServiceRequest] = useState<ServiceRequest>();
  const [closeForm, setCloseForm] = useState<any>(null);
  const [srTechnicians, setSrTechnicians] = useState<any[]>([]);
  const [technician, setTechnician] = useState<Technician>();
  const [technicians, setTechnicians] = useState<any>([]);
  const [showConversationModal, setShowConversationModal] = useState<boolean>(
    false
  );
  const [conversationPayload, setConversationPayload] = useState();
  const [conversationType, setConversationType] = useState("");
  const [requestLoadingIndex, setRequestLoadingIndex] = useState<number>(-1);
  const [imageType, setImageType] = useState("");
  const [images, setImages] = useState<any[]>([]);
  const [signatures, setSignatures] = useState<any>([]);
  const [replacedPartsImages, setReplacedPartsImages] = useState<any>([]);
  const [newPartsImages, setNewPartsImages] = useState<any>([]);
  const [attachments, setAttachments] = useState<any>([]);
  const [notes, setNotes] = useState<any[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [videoId, setVideoId] = useState<number>();
  const [history, setHistory] = useState<any[]>([]);

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const [originalContent, setOriginalContent] = useState("");

  const memberNo = serviceRequest?.requestFormValues.find(
    (number) => number.field.name === "Member Number"
  );

  const memberName = serviceRequest?.requestFormValues.find(
    (name) => name.field.name === "Member"
  );
  const memberAddress = serviceRequest?.requestFormValues.find(
    (address) => address.field.name === "Address"
  );
  const membersPropertyId = serviceRequest?.requestFormValues.find(
    (property) => property.field.name === "Property ID"
  );
  const room = serviceRequest?.requestFormValues.find(
    (room) => room.field.name === "Room"
  );
  const addtlDtls = serviceRequest?.requestFormValues.find(
    (detail) => detail.field.name === "Detail"
  );
  const srItem = serviceRequest?.requestFormValues.find(
    (srItem) => srItem.field.name === "Item"
  );
  const srType = serviceRequest?.requestFormValues.find(
    (srType) => srType.field.name === "Service Type"
  );

  const externalPartyName = serviceRequest?.requestFormValues.find(
    (exPartyName) => exPartyName.field.name === "External Party Name"
  );

  const externalPartyJobId = serviceRequest?.requestFormValues.find(
    (exPartyJobId) => exPartyJobId.field.name === "External Party Service Job Id"
  );

  //Get Asurion Email Original Content

  const getAsurionOriginalContent = async (id: any) => {
    const dataStore: any = localStorage.getItem("SESSION_CREDENTIALS");
    const { accessToken }: any = JSON.parse(dataStore);

    let endpoint = "";

    if (externalPartyName?.value == "Asurion") {
      endpoint = SERVER_URL.ASURION_RETRIEVE_JOB_DETAILS + "/" + id;
    } else if (externalPartyName?.value == "Costco") {
      endpoint = SERVER_URL.COSTCO_RETRIEVE_JOB_DETAILS + "/" + id;
    }

    if (endpoint != "") {
      const options = {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
        },
        method: "GET",
      };
      try {
        let res = await fetch(endpoint, options);
        let data = await res.json();
        setOriginalContent(data.originalContent);
      } catch (e) {
        console.log(e);
      }
    }
  };

  let dateString = serviceRequest?.scheduleDateTimeStamp;
  let dateObj = new Date(dateString);
  let momentObj = moment(dateObj);
  let srDate = momentObj.format("MMMM DD YYYY");

  let unassignedSrDate = momentObj.add(
    serviceRequest?.durationInMinutes,
    "minutes"
  );

  let lateCompleted = momentObj
    .add(serviceRequest?.durationInMinutes, "minutes")
    .toString();

  // const videos = [
  //   {
  //     "id": 1,
  //     video: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
  //   },
  //   {
  //     "id": 2,
  //     video: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4"
  //   }
  // ]

  let durationHours = serviceRequest?.durationInMinutes
    ? serviceRequest.durationInMinutes / 60
    : null;

  useEffect(() => {
    window.addEventListener(
      "serviceRequestDetailsRequested",
      openServiceRequestDetails
    );
    window.addEventListener("pushServiceRequestStatus", trackSRStatus);
    return () => {
      window.removeEventListener(
        "serviceRequestDetailsRequested",
        openServiceRequestDetails
      );
      window.removeEventListener("pushServiceRequestStatus", trackSRStatus);
    };
  }, []);

  useEffect(() => {
    if (!_isUndefined(serviceRequest?.id)) {
      getSrHistory(serviceRequest?.id);
      fetchNotes(serviceRequest?.id);
      fetchSRCloseForm(serviceRequest?.id);
    }
    preparePhotoViewer();
    trackTechStatus();
    getAsurionOriginalContent(externalPartyJobId?.value)
  }, [serviceRequest]);

  const trackTechStatus = () => {
    const srTechniciansClone = srTechnicians;
    const findTech = srTechniciansClone.find(
      (tech: any) => tech.id === serviceRequest?.technicianId
    );
    if (findTech) {
      findTech.srStatus = serviceRequest?.currentStatus;
    }
    setSrTechnicians(srTechniciansClone);
  };

  function openServiceRequestDetails(event: Event) {
    setCloseFormOpen(false);
    setCloseForm(null);
    const newTechArray = [];
    form.resetFields();
    //@ts-ignore
    const { request, technicians } = event.detail;
    if (technicians !== undefined) {
      if (request.technicianIds) {
        request.technicianIds.map((id: number) => {
          const findMultipleTech = technicians.find(
            (tech: any) => tech.id === id
          );
          if (findMultipleTech) {
            newTechArray.push({
              ...findMultipleTech,
              type: "technician",
              srStatus:
                findMultipleTech.id === request.technicianId
                  ? request.currentStatus
                  : null,
            });
          }
        });
      } else {
        const findTech = technicians.find(
          (tech: any) => tech.id === request.technicianId
        );
        if (findTech) {
          newTechArray.push({
            ...findTech,
            type: "technician",
            srStatus: request.currentStatus,
          });
        }
      }
      setSrTechnicians(newTechArray);
      setServiceRequest({ ...request, type: "SR" });
      actionModal(true);
      setTechnicians(technicians);
    } else {
      setSrTechnicians([]);
      setTechnician(undefined);
      actionModal(true);
      setServiceRequest({ ...request, type: "SR" });
    }
  }

  const trackSRStatus = (event: Event) => {
    //@ts-ignore
    const { request } = event.detail;
    if (!_isUndefined(request)) {
      if (!_isUndefined(serviceRequest)) {
        //@ts-ignore
        setServiceRequest((prev) => ({ ...prev, currentStatus: request.status }));
      }
    }
  };

  const setLocationDetails = (id: any, distance: any, duration: any) => {
    const srTechniciansClone = srTechnicians;
    const findTech = srTechniciansClone.find((tech: any) => tech.id === id);
    findTech.distance = distance;
    findTech.duration = duration;
    setSrTechnicians(srTechniciansClone);
  };

  const onChat = () => {
    fetchNotes(serviceRequest?.id);
  };

  const showModal = (id: any) => {
    setVideoId(id);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getSrHistory = async (id: any) => {
    const dataStore: any = localStorage.getItem("SESSION_CREDENTIALS");
    const { accessToken }: any = JSON.parse(dataStore);
    let endpoint = SERVER_URL.HISTORY + "?serviceRequestId=" + id;
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
      },
      method: "GET",
    };
    try {
      let res = await fetch(endpoint, options);
      let data = await res.json();
      setHistory(data);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchNotes = async (id: any) => {
    const dataStore: any = localStorage.getItem("SESSION_CREDENTIALS");
    const { accessToken }: any = JSON.parse(dataStore);
    let endpoint = SERVER_URL.NOTES + "?serviceRequestId=" + id;
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
      },
      method: "GET",
    };
    try {
      let res = await fetch(endpoint, options);
      let data = await res.json();
      setNotes(data);
    } catch (e) {
      console.log(e);
    }
  };

  const preparePhotoViewer = () => {
    let photoSet: any = [];
    let photoSet2 = [];

    if (serviceRequest) {
      if (serviceRequest?.photos) {
        serviceRequest?.photos.map((photo: Photo) => {
          photoSet.push(SERVER_URL.SERVICE_REQUEST_PHOTO + photo.photo);
          return photoSet;
        });
      }

      const photoName = serviceRequest?.requestFormValues.find(
        (srItem) => srItem.field.shortName === "IPH"
      );
      const photoItemName = photoName?.value;
      if (photoItemName) {
        photoSet2.push(SERVER_URL.SERVICE_REQUEST_ITEM_PHOTO + photoItemName);
      }
    }

    const finalSetOfImages = photoSet.concat(photoSet2);
    setImages(finalSetOfImages);
  };

  const fetchSRCloseForm = async (id: any) => {
    if (serviceRequest) {
      const response = await props.getSRCloseForm(id);
      if (response.statusCode === 200) {
        const {
          replacedParts,
          newParts,
          attachments,
          signature,
        } = response.body;
        setCloseForm(response.body);
        if (replacedParts.length > 0) {
          const replacePartsArray = await replacedParts.map((r: any) => {
            return `${SERVER_URL.CLOSE_FORM_PARTS}/${r.photo}`;
          });
          setReplacedPartsImages(replacePartsArray);
        }
        if (newParts.length > 0) {
          const newPartsArray = await newParts.map((n: any) => {
            return `${SERVER_URL.CLOSE_FORM_PARTS}/${n.photo}`;
          });
          setNewPartsImages(newPartsArray);
        }
        if (attachments.length > 0) {
          const attachmentsArray = await attachments.map((a: any) => {
            return `${SERVER_URL.CLOSE_FORM_ATTACHMENT}/${a.attachment}`;
          });
          setAttachments(attachmentsArray);
        }
        if (signature !== null) {
          setSignatures([`${SERVER_URL.CLOSE_FORM_SIGNATURE}/${signature}`]);
        }
      }
    }
  };

  const openImageViewer = useCallback(async (index, photoType) => {
    await setImageType(photoType);
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const convertDateStamp = (date: any) => {
    let initialDate = new Date(date);
    let finalDate = moment(initialDate).format("MMMM DD, YYYY");
    return finalDate;
  };

  const convertTimeStamp = (date: any) => {
    let initialTime = moment(new Date(date));
    let finalTime = initialTime.format("h:mm A");
    return finalTime;
  };

  const calculateStartTime = (scheduleDateTimeStamp: any) => {
    const dateTimeStamp: any = moment(scheduleDateTimeStamp);
    const timeStamp = dateTimeStamp.minute() < 29
      ? dateTimeStamp.set('minutes', 0)
      : dateTimeStamp.clone().set('minutes', 0).add(30, "minutes");
    const startTime = timeStamp.format('h:mm A');
    return startTime
  }

  const calculateEndTime = (scheduleDateTimeStamp: any, durationInMinutes: number) => {
    const dateTimeStamp: any = moment(scheduleDateTimeStamp);
    const timeStamp = dateTimeStamp.minute() < 29
      ? dateTimeStamp.set('minutes', 0)
      : dateTimeStamp.clone().set('minutes', 0).add(30, "minutes");
    const endTime = timeStamp.clone().add(durationInMinutes, "minutes").format('h:mm A');
    return endTime;
  }

  const noStatus = (status: any) => {
    switch (status) {
      case "ENROUTE":
      case "ONSITE":
      case "SERVICE_COMPLETED":
      case "INVOICE_SUBMITTED":
      case "COMPLETED":
        return false;
      default:
        return true;
    }
  };

  const arrived = (status: any) => {
    switch (status) {
      case "ONSITE":
      case "SERVICE_COMPLETED":
      case "INVOICE_SUBMITTED":
      case "COMPLETED":
        return true;
      default:
        return false;
    }
  };

  const assignedDateTime = history.find(
    (assigned) => assigned.status === "Assigned"
  );
  // const acceptedDateTime = history.find(accepted => accepted.status === "Accepted");
  // const onsiteDateTime = history.find(onsite => onsite.status === "Onsite");
  // const enrouteDateTime = history.find(enroute => enroute.status === "On My Way");
  // const completedDateTime = history.find(completed => completed.status === "Service Completed");
  const invoiceDateTime = history.find(
    (invoice) => invoice.status === "Closed Form"
  );

  const assignedArray = history.filter(
    (assigned) => assigned.status === "Assigned"
  );
  const rejectedArray = history.filter(
    (rejected) => rejected.status === "Rejected"
  );
  const onsiteArray = history.filter((onsite) => onsite.status === "Onsite");
  const enrouteArray = history.filter(
    (enroute) => enroute.status === "On My Way"
  );
  const completedArray = history.filter(
    (completed) => completed.status === "Service Completed"
  );
  const closeFormArray = history.filter(
    (closeForm) => closeForm.status === "Closed Form"
  );
  const acceptedArray = history.filter(
    (accepted) => accepted.status === "Accepted"
  );

  const convertStr = (str: any) => {
    const arr = str.split("_");

    const result = [];

    for (const word of arr) {
      result.push(
        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() // 👈️ lowercase remainder
      );
    }

    return result.join(" ");
  };

  const reassignedDateTime = assignedArray[assignedArray.length - 1];
  const onsiteDateTime = onsiteArray[onsiteArray.length - 1];
  const enrouteDateTime = enrouteArray[enrouteArray.length - 1];
  const completedDateTime = completedArray[completedArray.length - 1];
  const acceptedDateTime = acceptedArray[acceptedArray.length - 1];
  const rejectedDateTime = rejectedArray[rejectedArray.length - 1];


  return (
    <Modal
      visible={isOpen}
      centered={true}
      className={"modal-body"}
      onCancel={() => {
        actionModal(false);
      }}
      closable={false}
      footer={null}
      width={isCloseFormOpen ? "40%" : "50%"}
    >
      {isCloseFormOpen && closeForm !== null ? (
        <div
          style={{
            width: "100%",
            padding: 35,
            fontFamily: "Arial, Helvetica, sans-serif",
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "space-between",
            }}
          >
            <ArrowLeftOutlined
              style={{ fontSize: 20 }}
              onClick={() => {
                setCloseFormOpen(false);
              }}
            />
            <CloseOutlined
              style={{ fontSize: 20 }}
              onClick={() => {
                setCloseFormOpen(false);
              }}
            />
          </div>
          <h2 style={{ fontWeight: "bold", marginTop: 20, fontSize: 18 }}>
            SR Close Form
          </h2>
          <div style={{ flex: 1, marginTop: 20 }}>
            <p style={{ fontWeight: "bold", marginTop: 20, fontSize: 18 }}>
              GENERAL INFORMATION
            </p>
            <div>
              <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
                <p
                  style={{
                    flex: 1,
                    color: "gray",
                    fontSize: 17,
                    marginBottom: 0,
                  }}
                >
                  SR Issue
                </p>
                <p style={{ flex: 2.5, fontSize: 17, marginBottom: 0 }}>
                  {!_isUndefined(srType)
                    ? convertStr(srType?.value)
                    : srItem?.value}
                </p>
              </div>
              <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
                <p
                  style={{
                    flex: 1,
                    color: "gray",
                    fontSize: 17,
                    marginBottom: 0,
                  }}
                >
                  SR No.
                </p>
                <p style={{ flex: 2.5, fontSize: 17, marginBottom: 0 }}>
                  {serviceRequest?.serviceRequestNo}
                </p>
              </div>
              <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
                <p
                  style={{
                    flex: 1,
                    color: "gray",
                    fontSize: 17,
                    marginBottom: 0,
                  }}
                >
                  Date
                </p>
                <p style={{ flex: 2.5, fontSize: 17, marginBottom: 0 }}>
                  {srDate}
                </p>
              </div>
              <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
                <p
                  style={{
                    flex: 1,
                    color: "gray",
                    fontSize: 17,
                    marginBottom: 0,
                  }}
                >
                  Property ID No.
                </p>
                <p style={{ flex: 2.5, fontSize: 17, marginBottom: 0 }}>
                  {membersPropertyId?.value}
                </p>
              </div>
              <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
                <p
                  style={{
                    flex: 1,
                    color: "gray",
                    fontSize: 17,
                    marginBottom: 0,
                  }}
                >
                  Member's Address
                </p>
                <p style={{ flex: 2.5, fontSize: 17, marginBottom: 0 }}>
                  {memberAddress?.value}
                </p>
              </div>
              <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
                <p
                  style={{
                    flex: 1,
                    color: "gray",
                    fontSize: 17,
                    marginBottom: 0,
                  }}
                >
                  Member's Name
                </p>
                <p style={{ flex: 2.5, fontSize: 17, marginBottom: 0 }}>
                  {memberName?.value}
                </p>
              </div>
            </div>
          </div>
          <div style={{ marginTop: 20, lineHeight: 1.2 }}>
            <p style={{ fontWeight: "bold", marginTop: 20, fontSize: 18 }}>
              NOTES
            </p>
            {closeForm !== null && closeForm.notesSummary !== null ? (
              <div style={{ display: "grid", width: "100%" }}>
                <p style={{ fontSize: 17, color: "gray" }}>
                  {closeForm.notesSummary}
                </p>
              </div>
            ) : (
              <div
                style={{
                  height: 50,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{ textAlign: "center", color: "gray", fontSize: 17 }}
                >
                  There is no Notes Summary
                </div>
              </div>
            )}
          </div>
          <div style={{ marginTop: 20 }}>
            <p style={{ fontWeight: "bold", marginTop: 20, fontSize: 18 }}>
              PARTS/MATERIALS USED
            </p>
            <div>
              <p style={{ color: "gray", fontSize: 17 }}>
                List of Replaced Items
              </p>
              {closeForm.replacedParts.length > 0 ? (
                <div style={{ maxHeight: 180, overflow: "auto" }}>
                  {closeForm.replacedParts.map((p: any, index: any) => {
                    return (
                      <div
                        key={`closeF_repl_parts${index}`}
                        style={{
                          marginLeft: 40,
                          display: "flex",
                          marginTop: 10,
                        }}
                      >
                        <div
                          style={{
                            height: 70,
                            width: 70,
                            marginRight: 10,
                            borderRadius: 10,
                            border: "1px solid lightgray",
                            overflow: "hidden",
                          }}
                          onClick={() =>
                            openImageViewer(index, "replacedParts")
                          }
                        >
                          <img
                            style={{
                              height: "100%",
                              width: "100%",
                              objectFit: "cover",
                            }}
                            src={`${SERVER_URL.CLOSE_FORM_PARTS}/${p.photo}`}
                          />
                        </div>
                        <div style={{ fontSize: 16 }}>
                          <p
                            style={{
                              fontSize: 17,
                              fontWeight: "500",
                              marginBottom: 0,
                            }}
                          >
                            {p.name}
                          </p>
                          <p
                            style={{
                              color: "gray",
                              fontSize: 15,
                              marginBottom: 0,
                            }}
                          >
                            {p.quantity} pcs
                          </p>
                          <div
                            style={{
                              width: 350,
                              display: "flex",
                              color: "gray",
                              justifyContent: "space-between",
                            }}
                          >
                            <p style={{ marginBottom: 0 }}>
                              Serial No. {p.serialNumber}
                            </p>
                            <p style={{ marginLeft: 40, marginBottom: 0 }}>
                              {p.model}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div
                  style={{ textAlign: "center", color: "gray", fontSize: 17 }}
                >
                  There are no Replaced Items
                </div>
              )}
            </div>
            <div style={{ marginTop: 20 }}>
              <p style={{ color: "gray", fontSize: 17 }}>List of New Items</p>
              {closeForm.newParts.length > 0 ? (
                <div style={{ maxHeight: 180, overflow: "auto" }}>
                  {closeForm.newParts.map((p: any, index: any) => {
                    return (
                      <div
                        key={`closeF_n_parts${index}`}
                        style={{
                          marginLeft: 40,
                          display: "flex",
                          marginTop: 10,
                        }}
                      >
                        <div
                          style={{
                            height: 70,
                            width: 70,
                            marginRight: 10,
                            borderRadius: 10,
                            border: "1px solid lightgray",
                            overflow: "hidden",
                          }}
                          onClick={() => openImageViewer(index, "newParts")}
                        >
                          <img
                            style={{
                              height: "100%",
                              width: "100%",
                              objectFit: "cover",
                            }}
                            src={`${SERVER_URL.CLOSE_FORM_PARTS}/${p.photo}`}
                          />
                        </div>
                        <div style={{ fontSize: 16 }}>
                          <p
                            style={{
                              fontSize: 17,
                              fontWeight: "500",
                              marginBottom: 0,
                            }}
                          >
                            {p.name}
                          </p>
                          <p
                            style={{
                              color: "gray",
                              fontSize: 15,
                              marginBottom: 0,
                            }}
                          >
                            {p.quantity} pcs
                          </p>
                          <div
                            style={{
                              width: 350,
                              display: "flex",
                              color: "gray",
                              justifyContent: "space-between",
                            }}
                          >
                            <p style={{ marginBottom: 0 }}>
                              Serial No. {p.serialNumber}
                            </p>
                            <p style={{ marginLeft: 40, marginBottom: 0 }}>
                              {p.model}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div
                  style={{ textAlign: "center", color: "gray", fontSize: 17 }}
                >
                  There are no New Items
                </div>
              )}
            </div>
          </div>
          <div style={{ marginTop: 20 }}>
            <p style={{ fontWeight: "bold", marginTop: 20, fontSize: 18 }}>
              SERVICE INVOICE
            </p>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  color: "gray",
                  fontSize: 17,
                }}
              >
                Invoice
              </div>
              <Button
                style={{
                  backgroundColor: "#3A78B3",
                  color: " white",
                  height: 40,
                  width: 90,
                  borderRadius: 7,
                  fontSize: 17,
                }}
              >
                <a
                  href="#"
                  onClick={() =>
                    window.open(
                      `${SERVER_URL.CLOSE_FORM}/invoice?closeFormId=${closeForm.id}`,
                      "_blank"
                    )
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  View
                </a>
              </Button>
            </div>
          </div>
          <div style={{ marginTop: 20 }}>
            <p style={{ fontWeight: "bold", marginTop: 20, fontSize: 18 }}>
              SIGNATURE
            </p>
            {closeForm.signature !== null ? (
              <div
                style={{
                  height: 100,
                  width: 270,
                  marginRight: 10,
                  borderRadius: 5,
                  border: "1px solid lightgray",
                  overflow: "hidden",
                }}
                onClick={() => openImageViewer(0, "signature")}
              >
                <img
                  src={`${SERVER_URL.CLOSE_FORM_SIGNATURE}/${closeForm.signature}`}
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                  }}
                />
              </div>
            ) : (
              <div style={{ textAlign: "center", color: "gray", fontSize: 17 }}>
                There is no Signature
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "grid",
            width: "100%",
            gridTemplateColumns: "50% 50%",
            padding: 20,
          }}
        >
          <div style={{ gridTemplateRows: "50% 50%" }}>
            <h3 style={{ fontWeight: "bold", gridColumn: 1 }}>
              SR#{serviceRequest?.serviceRequestNo} Summary
            </h3>
            {/* <br /> */}

            <div
              style={{
                display: "flex",
                width: "100%",
                height: "250px",
              }}
            >
              <ServiceRequestMap
                serviceRequest={serviceRequest}
                srTechnicians={srTechnicians}
                setLocationDetails={setLocationDetails}
              />
            </div>
            <div
              style={{
                gridColumn: 1,
                margin: "0 0 20px 0",
                border: "1px solid rgba(0, 0, 0, 0.05)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  zIndex: 1,
                  justifyContent: "space-around",
                  position: "relative",
                  padding: "20px 0 20px 0",
                }}
              >
                <div
                  style={{
                    alignSelf: "center",
                    color:
                      serviceRequest?.currentStatus === "ENROUTE"
                        ? "white"
                        : "#202020",
                    fontSize: 12,
                    whiteSpace: "pre",
                  }}
                >
                  On My Way
                </div>
                <div
                  style={{
                    alignSelf: "center",
                    color:
                      serviceRequest?.currentStatus === "ONSITE"
                        ? "white"
                        : "#202020",
                    fontSize: 12,
                    whiteSpace: "pre",
                  }}
                >
                  On Site
                </div>
                <div
                  style={{
                    alignSelf: "center",
                    textAlign: "center",
                    color:
                      serviceRequest?.currentStatus === "SERVICE_COMPLETED"
                        ? "white"
                        : "#202020",
                    fontSize: 12,
                    whiteSpace: "pre",
                  }}
                >
                  Job{"\n"}Completed
                </div>
                <div
                  style={{
                    alignSelf: "center",
                    textAlign: "center",
                    color:
                      serviceRequest?.currentStatus === "INVOICE_SUBMITTED"
                        ? "white"
                        : "#202020",
                    fontSize: 12,
                    whiteSpace: "pre",
                  }}
                >
                  Completion{"\n"}Form
                </div>

                <div
                  style={{ position: "absolute", zIndex: -1, width: "100%" }}
                >
                  {serviceRequest?.currentStatus === "ENROUTE" ? (
                    <img
                      src={OnMyWay}
                      alt="On My Way"
                      style={{ width: "100%" }}
                    />
                  ) : null}
                  {serviceRequest?.currentStatus === "ONSITE" &&
                    onsiteDateTime?.date <=
                    serviceRequest?.scheduleDateTimeStamp ? (
                    <img src={OnSite} alt="On Site" style={{ width: "100%" }} />
                  ) : null}

                  {serviceRequest?.currentStatus === "ONSITE" &&
                    onsiteDateTime?.date >
                    serviceRequest?.scheduleDateTimeStamp ? (
                    <img
                      src={OnSiteLate}
                      alt="On Site Late"
                      style={{ width: "100%" }}
                    />
                  ) : null}

                  {serviceRequest?.currentStatus === "SERVICE_COMPLETED" &&
                    completedDateTime !== undefined &&
                    new Date(completedDateTime?.date) <=
                    new Date(lateCompleted) ? (
                    <img
                      src={JobCompleted}
                      alt="Job Completed"
                      style={{ width: "100%" }}
                    />
                  ) : null}

                  {serviceRequest?.currentStatus === "SERVICE_COMPLETED" &&
                    completedDateTime !== undefined &&
                    new Date(completedDateTime?.date) >
                    new Date(lateCompleted) ? (
                    <img
                      src={JobCompletedLate}
                      alt="Job Completed Late"
                      style={{ width: "100%" }}
                    />
                  ) : null}

                  {serviceRequest?.currentStatus === "INVOICE_SUBMITTED" &&
                    closeFormArray.length !== 0 ? (
                    <img
                      src={CompletionForm}
                      alt="Completion Form"
                      style={{ width: "100%" }}
                    />
                  ) : null}

                  {serviceRequest?.currentStatus === "COMPLETED" &&
                    closeFormArray.length !== 0 ? (
                    <img src={CompletionForm} style={{ width: "100%" }} />
                  ) : null}
                  {noStatus(serviceRequest?.currentStatus) &&
                    closeFormArray.length == 0 ? (
                    <img
                      src={NoStatus}
                      alt="Default"
                      style={{ width: "100%" }}
                    />
                  ) : null}
                </div>
              </div>
              {srTechnicians !== undefined && srTechnicians.length > 0 ? (
                <div style={{ marginLeft: 20 }}>
                  {srTechnicians.map((tech, index) => {
                    return (
                      <div
                        key={`sr_technician${index}`}
                        style={{
                          display: "grid",
                          width: "100%",
                          gridTemplateColumns: "85% 15%",
                          marginBottom: 20,
                        }}
                      >
                        <h3
                          style={{
                            fontWeight: "bold",
                            gridColumn: 1,
                            color: "#3A78B3",
                          }}
                        >
                          {tech.fullName} {!serviceRequest?.primary && '| Helper'}
                        </h3>
                        <Button
                          style={{
                            backgroundColor: "#3A78B3",
                            color: " white",
                            borderRadius: 25,
                          }}
                          className={"create-btn"}
                          icon={<MessageFilled />}
                          onClick={() => {
                            setRequestLoadingIndex(0);
                            setConversationType("chat-with-service-request");
                            setConversationPayload(undefined);
                            setTechnician(tech);
                            setShowConversationModal(true);
                            localStorage.setItem(
                              "chatConversationModal",
                              "IsOpen"
                            );
                          }}
                        ></Button>
                        <div style={{ gridColumn: 1 }}>
                          <ClockCircleFilled style={{ marginRight: 15 }} />
                          {tech.srStatus && arrived(tech.srStatus)
                            ? onsiteDateTime?.date >
                              serviceRequest?.scheduleDateTimeStamp
                              ? "Arrived late"
                              : "Arrived on time"
                            : tech.duration
                              ? `Arrives in ${tech.duration}`
                              : "Duration cannot be measured"}
                        </div>
                        <div style={{ gridColumn: 1 }}>
                          <EnvironmentFilled style={{ marginRight: 15 }} />
                          {tech.distance
                            ? `${tech.distance} miles away from destination`
                            : "Distance cannot be determined"}
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div style={{ marginLeft: 20 }}>
                  <div
                    style={{
                      display: "grid",
                      width: "100%",
                      gridTemplateColumns: "85% 15%",
                      marginBottom: 20,
                    }}
                  >
                    <h3
                      style={{
                        fontWeight: "bold",
                        gridColumn: 1,
                        color: "#3A78B3",
                      }}
                    >
                      Unassigned
                    </h3>
                    <div style={{ gridColumn: 1 }}>
                      <ClockCircleFilled style={{ marginRight: 15 }} />
                      N/A
                    </div>
                    <div style={{ gridColumn: 1 }}>
                      <EnvironmentFilled style={{ marginRight: 15 }} />
                      N/A
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div>
              <div>
                <h3 style={{ fontWeight: "bold", gridColumn: 1 }}>
                  TECH NOTES
                </h3>
              </div>
              <div style={{ display: "grid", width: "100%" }}>
                <div
                  style={{
                    backgroundColor: "#F2F2F2",
                    height: 200,
                    overflowY: "scroll",
                    borderRadius: 5,
                    padding: 20,
                  }}
                >
                  {notes?.length > 0 ? (
                    <ul style={{ paddingLeft: 20 }}>
                      {notes?.map((note) => {
                        return (
                          <li key={note.id}>
                            {note.serviceRequest.technicianName} -{" "}
                            {convertDateStamp(note.dateTimeStamp)} -{" "}
                            {convertTimeStamp(note.dateTimeStamp)} -{" "}
                            {note.notes}
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    <p>No available tech notes</p>
                  )}
                </div>
              </div>
            </div>
            <div
              style={{
                height: 70,
                border: "1px solid rgba(0, 0, 0, 0.05)",
                borderRadius: 5,
                padding: 20,
                marginTop: 20,
              }}
            >
              <div
                style={{
                  display: "grid",
                  width: "100%",
                  gridTemplateColumns: "85% 15%",
                  marginBottom: 20,
                }}
              >
                <h3 style={{ fontWeight: "bold", gridColumn: 1 }}>
                  SR CLOSE FORM
                </h3>
                <Button
                  style={{
                    backgroundColor: "#3A78B3",
                    color: " white",
                    borderRadius: 25,
                  }}
                  className={"create-btn"}
                  icon={<FileTextFilled />}
                  onClick={() => {
                    closeForm !== null
                      ? setCloseFormOpen(true)
                      : message.error(
                        "There is currently no Close Form for this Service Request."
                      );
                  }}
                ></Button>
              </div>
              <div style={{ display: "grid", width: "100%" }}></div>
            </div>
            <div style={{ height: 100, marginTop: 20 }}>
              <div style={{ display: "grid", width: "100%" }}>
                <h3 style={{ fontWeight: "bold", gridColumn: 1 }}>
                  NOTES SUMMARY
                </h3>
              </div>

              {closeForm !== null && closeForm.notesSummary !== null ? (
                <div style={{ display: "grid", width: "100%" }}>
                  <p>{closeForm.notesSummary}</p>
                </div>
              ) : (
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{ textAlign: "center", color: "gray", fontSize: 17 }}
                  >
                    There is no Notes Summary
                  </div>
                </div>
              )}
            </div>
            <div style={{ height: 150, marginTop: 40 }}>
              <div>
                <h3
                  style={{ fontWeight: "bold", gridColumn: 1, marginTop: 20 }}
                >
                  SR CLOSE FORM ATTACHMENTS
                </h3>
              </div>

              {closeForm !== null && closeForm.attachments.length > 0 ? (
                <div
                  style={{
                    height: 100,
                    display: "flex",
                    width: "100%",
                    overflow: "auto",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  {closeForm.attachments.map((a: any, index: any) => {
                    return (
                      <div
                        key={`closeF_attch_${index}`}
                        style={{
                          width: "22%",
                          height: 70,
                          alignItems: "center",
                          borderRadius: 10,
                          margin: 5,
                          border: "1px solid lightgray",
                          overflow: "hidden",
                        }}
                        onClick={() => openImageViewer(index, "attachment")}
                      >
                        <img
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                          }}
                          src={`${SERVER_URL.CLOSE_FORM_ATTACHMENT}/${a.attachment}`}
                        />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  imageStyle={{ color: "red" }}
                />
              )}
            </div>
          </div>
          <div style={{ marginLeft: 20 }}>
            <div style={{ marginTop: 30 }}>
              <div
                style={{
                  display: "grid",
                  width: "100%",
                  gridTemplateColumns: "75% 25%",
                }}
              >
                <h3
                  style={{
                    fontWeight: "bold",
                    gridColumn: 1,
                    color: "#3A78B3",
                  }}
                >
                  {memberName?.value}
                </h3>
                <Button
                  style={{
                    backgroundColor: "#3A78B3",
                    color: " white",
                    borderRadius: 5,
                  }}
                  className={"create-btn"}
                  icon={<MessageFilled />}
                  onClick={() => {
                    setRequestLoadingIndex(0);
                    setConversationType("chat-with-member");
                    setConversationPayload(undefined);
                    setShowConversationModal(true);
                    localStorage.setItem("chatConversationModal", "IsOpen");
                  }}
                >
                  Chat
                </Button>
                <div style={{ gridColumn: 1 }}>
                  <PhoneFilled style={{ marginRight: 15 }} />
                  {memberNo?.value}
                </div>
                <div style={{ gridColumn: 1 }}>
                  <HomeFilled style={{ marginRight: 15 }} />
                  {memberAddress?.value}
                </div>
              </div>
              <div style={{ height: "20%" }}>
                <div>
                  <h3
                    style={{ fontWeight: "bold", gridColumn: 1, marginTop: 20 }}
                  >
                    SR DETAILS
                  </h3>
                </div>
                {room?.value ? (
                  <div>
                    <div
                      style={{
                        display: "grid",
                        width: "100%",
                        gridTemplateColumns: "50% 50%",
                      }}
                    >
                      <p style={{ gridColumn: 1, marginBottom: 0 }}>Room</p>
                      <p style={{ gridColumn: 2, marginBottom: 0 }}>
                        {room?.value}
                      </p>
                      <p style={{ gridColumn: 1, marginBottom: 0 }}>Item</p>
                      <p style={{ gridColumn: 2, marginBottom: 0 }}>
                        {srItem?.value}
                      </p>
                      <p style={{ gridColumn: 1, marginBottom: 0 }}>
                        Service Date
                      </p>
                      <p style={{ gridColumn: 2, marginBottom: 0 }}>
                        {serviceRequest?.currentStatus === "EXCEPTION" ? (
                          <span>Exception</span>
                        ) : null}
                        {serviceRequest?.currentStatus === "UNASSIGNED" ? (
                          <span>{srDate}</span>
                        ) : null}
                        {serviceRequest?.currentStatus !== "EXCEPTION" &&
                          serviceRequest?.currentStatus !== "UNASSIGNED" ? (
                          <span>{srDate}</span>
                        ) : null}
                      </p>
                      <p style={{ gridColumn: 1, marginBottom: 0 }}>
                        Service Hours
                      </p>
                      <p style={{ gridColumn: 2, marginBottom: 0 }}>
                        {/* {serviceRequest?.timeStamp.startTime} -{" "}
                      {serviceRequest?.timeStamp.endTime} ({durationHours}{" "}
                      {durationHours !== null && durationHours > 1
                        ? "hours"
                        : "hour"}
                      ) */}
                        {serviceRequest?.currentStatus === "EXCEPTION" ? (
                          <span>Exception</span>
                        ) : null}
                        {serviceRequest?.currentStatus === "UNASSIGNED" ? (
                          <span>
                            {calculateStartTime(
                              serviceRequest?.scheduleDateTimeStamp
                            )}{" "}
                            -{" "}
                            {calculateEndTime(
                              serviceRequest?.scheduleDateTimeStamp,
                              serviceRequest?.durationInMinutes
                            )}{" "}
                            ({durationHours}{" "}
                            {durationHours !== null && durationHours > 1
                              ? "hours"
                              : "hour"}
                            )
                          </span>
                        ) : null}
                        {serviceRequest?.currentStatus !== "EXCEPTION" &&
                          serviceRequest?.currentStatus !== "UNASSIGNED" ? (
                          <span>
                            {serviceRequest?.timeStamp.startTime} -{" "}
                            {serviceRequest?.timeStamp.endTime} ({durationHours}{" "}
                            {durationHours !== null && durationHours > 1
                              ? "hours"
                              : "hour"}
                            )
                          </span>
                        ) : null}
                      </p>
                      <p style={{ gridColumn: 1, marginBottom: 0 }}>
                        Additional Notes
                      </p>
                      {/* <p style={{ gridColumn: 2, marginBottom: 0 }}>
                        {addtlDtls?.value}
                      </p> */}
                    </div>

                    <div style={{ display: "grid", width: "100%" }}>
                      <div
                        style={{
                          backgroundColor: "#F2F2F2",
                          height: 200,
                          overflowY: "scroll",
                          borderRadius: 5,
                          padding: 20,
                        }}
                      >
                        {(externalPartyName?.value == "Asurion" ||
                          externalPartyName?.value == "Costco") &&
                          externalPartyJobId ? (
                          <ul style={{ paddingLeft: 20 }}>
                            {originalContent?.split("\r\n").map((str) => (
                              <p key={`o_${str}`}>{str}</p>
                            ))}
                          </ul>
                        ) : (!_isNull(addtlDtls?.value)) ? (
                          addtlDtls?.value
                        ) : (
                          "No available additional notes"
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div
                      style={{
                        display: "grid",
                        width: "100%",
                        gridTemplateColumns: "50% 50%",
                      }}
                    >
                      <p style={{ gridColumn: 1, marginBottom: 0 }}>
                        Service Request
                      </p>
                      <p style={{ gridColumn: 2, marginBottom: 0 }}>
                        {!_isUndefined(srType)
                          ? convertStr(srType?.value)
                          : null}
                      </p>
                      <p style={{ gridColumn: 1, marginBottom: 0 }}>
                        Service Date
                      </p>
                      <p style={{ gridColumn: 2, marginBottom: 0 }}>
                        {serviceRequest?.currentStatus === "EXCEPTION" ? (
                          <span>Exception</span>
                        ) : null}
                        {serviceRequest?.currentStatus === "UNASSIGNED" ? (
                          <span>{srDate}</span>
                        ) : null}
                        {serviceRequest?.currentStatus !== "EXCEPTION" &&
                          serviceRequest?.currentStatus !== "UNASSIGNED" ? (
                          <span>{srDate}</span>
                        ) : null}
                      </p>
                      <p style={{ gridColumn: 1, marginBottom: 0 }}>
                        Service Hours
                      </p>
                      <p style={{ gridColumn: 2, marginBottom: 0 }}>
                        {/* {serviceRequest?.timeStamp.startTime} -{" "}
                      {serviceRequest?.timeStamp.endTime} ({durationHours}{" "}
                      {durationHours !== null && durationHours > 1
                        ? "hours"
                        : "hour"}
                      ) */}
                        {serviceRequest?.currentStatus === "EXCEPTION" ? (
                          <span>Exception</span>
                        ) : null}

                        {serviceRequest?.currentStatus === "UNASSIGNED" ? (
                          <span>
                            {calculateStartTime(
                              serviceRequest?.scheduleDateTimeStamp
                            )}{" "}
                            -{" "}
                            {calculateEndTime(
                              serviceRequest?.scheduleDateTimeStamp,
                              serviceRequest?.durationInMinutes
                            )}{" "}
                            ({durationHours}{" "}
                            {durationHours !== null && durationHours > 1
                              ? "hours"
                              : "hour"}
                            )
                          </span>
                        ) : null}

                        {serviceRequest?.currentStatus !== "EXCEPTION" &&
                          serviceRequest?.currentStatus !== "UNASSIGNED" ? (
                          <span>
                            {serviceRequest?.timeStamp.startTime} -{" "}
                            {serviceRequest?.timeStamp.endTime} ({durationHours}{" "}
                            {durationHours !== null && durationHours > 1
                              ? "hours"
                              : "hour"}
                            )
                          </span>
                        ) : null}
                      </p>
                      <p style={{ gridColumn: 1, marginBottom: 0 }}>
                        Additional Notes
                      </p>
                      {/* <p style={{ gridColumn: 2, marginBottom: 0 }}>
                        {addtlDtls?.value}
                      </p> */}
                    </div>
                    <div style={{ display: "grid", width: "100%" }}>
                      <div
                        style={{
                          backgroundColor: "#F2F2F2",
                          height: 200,
                          overflowY: "scroll",
                          borderRadius: 5,
                          padding: 20,
                        }}
                      >
                        {(externalPartyName?.value == "Asurion" ||
                          externalPartyName?.value == "Costco") &&
                          externalPartyJobId ? (
                          <ul style={{ paddingLeft: 20 }}>
                            {originalContent?.split("\r\n").map((str) => (
                              <p key={`o2_${str}`}>{str}</p>
                            ))}
                          </ul>
                        ) : (!_isNull(addtlDtls?.value)) ? (
                          addtlDtls?.value
                        ) : (
                          "No available additional notes"
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div style={{ height: 100 }}>
                <div>
                  <h3
                    style={{ fontWeight: "bold", gridColumn: 1, marginTop: 20 }}
                  >
                    SR ATTACHMENTS
                  </h3>
                </div>
                {images.length > 0 ||
                  (serviceRequest?.videos &&
                    serviceRequest?.videos?.length > 0) ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: 100,
                    }}
                  >
                    {/* <div> */}
                    {images.map((photoImage, index) => (
                      <img
                        src={photoImage}
                        onClick={() => openImageViewer(index, "srImage")}
                        width="300"
                        key={index}
                        style={{ width: 50, height: 50 }}
                        alt=""
                      />
                    ))}

                    <span>
                      {serviceRequest?.videos.map((video) => {
                        return (
                          <img
                            src={PlayButton}
                            onClick={() => {
                              showModal(video.id);
                            }}
                            width="300"
                            style={{ width: 50, height: 50 }}
                            alt=""
                          />
                        );
                      })}
                    </span>
                  </div>
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              </div>
              <div style={{ marginTop: 40 }}>
                <div>
                  <h3 style={{ fontWeight: "bold", gridColumn: 1 }}>
                    SR HISTORY
                  </h3>
                </div>
                <div style={{ display: "grid", width: "100%" }}>
                  <div
                    style={{
                      backgroundColor: "#F2F2F2",
                      height: 480,
                      borderRadius: 5,
                      padding: 20,
                    }}
                  >
                    <div
                      style={{
                        display: "grid",
                        width: "100%",
                        gridTemplateColumns: "10% 90%",
                      }}
                    >
                      {serviceRequest?.currentStatus === "ASSIGNED" &&
                        rejectedArray.length == 0 &&
                        closeFormArray.length == 0 ? (
                        <CircleIcon fontSize="small" color="success" />
                      ) : (
                        <CircleIcon fontSize="small" />
                      )}
                      {serviceRequest?.currentStatus === "ASSIGNED" &&
                        rejectedArray.length == 0 &&
                        closeFormArray.length == 0 ? (
                        <span style={{ color: "green" }}> Assigned </span>
                      ) : (
                        <span> Assigned </span>
                      )}
                      {/* {enrouteArray.length > 0 || onsiteArray.length > 0 ||  ? (
                      <CircleIcon fontSize="small" />
                    ) : (
                      <CircleIcon fontSize="small"  color="success" />
                    )}
                    {enrouteArray.length > 0 || onsiteArray.length > 0 ? (
                      <span> Assigned </span>
                    ) : (
                      <span  style={{ color: "green" }}> Assigned </span>
                    )} */}
                      <div
                        style={{
                          gridColumn: 1,
                          marginLeft: 10,
                          height: 20,
                          borderLeft: "1px solid black",
                        }}
                      ></div>
                      {acceptedDateTime !== undefined ? (
                        <span style={{ gridColumn: 2, fontSize: 12 }}>
                          {convertDateStamp(acceptedDateTime.date)} {"-"}{" "}
                          {convertTimeStamp(acceptedDateTime.date)}
                        </span>
                      ) : (
                        <span style={{ gridColumn: 2, fontSize: 12 }}></span>
                      )}
                    </div>
                    <div
                      style={{
                        marginLeft: 10,
                        height: 20,
                        borderLeft: "1px solid black",
                      }}
                    ></div>
                    <div
                      style={{
                        display: "grid",
                        width: "100%",
                        gridTemplateColumns: "10% 90%",
                      }}
                    >
                      {serviceRequest?.currentStatus === "UNASSIGNED" &&
                        rejectedArray.length !== 0 &&
                        closeFormArray.length == 0 ? (
                        <CircleIcon fontSize="small" color="success" />
                      ) : (
                        <CircleIcon fontSize="small" />
                      )}
                      {serviceRequest?.currentStatus === "UNASSIGNED" &&
                        rejectedArray.length !== 0 &&
                        closeFormArray.length == 0 ? (
                        <span style={{ color: "green" }}> Rejected </span>
                      ) : (
                        <span> Rejected </span>
                      )}
                      {/* {enrouteArray.length > 0 || onsiteArray.length > 0 ||  ? (
                      <CircleIcon fontSize="small" />
                    ) : (
                      <CircleIcon fontSize="small"  color="success" />
                    )}
                    {enrouteArray.length > 0 || onsiteArray.length > 0 ? (
                      <span> Assigned </span>
                    ) : (
                      <span  style={{ color: "green" }}> Assigned </span>
                    )} */}
                      <div
                        style={{
                          gridColumn: 1,
                          marginLeft: 10,
                          height: 20,
                          borderLeft: "1px solid black",
                        }}
                      ></div>
                      {rejectedDateTime !== undefined ? (
                        <span style={{ gridColumn: 2, fontSize: 12 }}>
                          {convertDateStamp(rejectedDateTime.date)} {"-"}{" "}
                          {convertTimeStamp(rejectedDateTime.date)}
                        </span>
                      ) : (
                        <span style={{ gridColumn: 2, fontSize: 12 }}></span>
                      )}
                    </div>
                    <div
                      style={{
                        marginLeft: 10,
                        height: 20,
                        borderLeft: "1px solid black",
                      }}
                    ></div>
                    <div
                      style={{
                        display: "grid",
                        width: "100%",
                        gridTemplateColumns: "10% 90%",
                      }}
                    >
                      {serviceRequest?.currentStatus === "ENROUTE" &&
                        closeFormArray.length == 0 ? (
                        <CircleIcon fontSize="small" color="success" />
                      ) : (
                        <CircleIcon fontSize="small" />
                      )}
                      {serviceRequest?.currentStatus === "ENROUTE" &&
                        closeFormArray.length == 0 ? (
                        <span style={{ color: "green" }}> On My Way </span>
                      ) : (
                        <span> On My Way </span>
                      )}
                      {/* {onsiteArray.length > 0 ? (
                      <CircleIcon fontSize="small" />
                    ) : (
                      <CircleIcon fontSize="small"  color="success" />
                    )}
                    {onsiteArray.length > 0  ? (
                      <span> On My Way </span>
                    ) : (
                      <span  style={{ color: "green" }}> On My Way </span>
                    )}                     */}
                      <div
                        style={{
                          gridColumn: 1,
                          marginLeft: 10,
                          height: 20,
                          borderLeft: "1px solid black",
                        }}
                      ></div>
                      {/* <span style={{ gridColumn: 2, fontSize: 12 }}> test</span> */}
                      {enrouteDateTime !== undefined ? (
                        <span style={{ gridColumn: 2, fontSize: 12 }}>
                          {convertDateStamp(enrouteDateTime.date)} {"-"}{" "}
                          {convertTimeStamp(enrouteDateTime.date)}
                        </span>
                      ) : (
                        <span style={{ gridColumn: 2, fontSize: 12 }}></span>
                      )}
                    </div>
                    <div
                      style={{
                        marginLeft: 10,
                        height: 20,
                        borderLeft: "1px solid black",
                      }}
                    ></div>
                    <div
                      style={{
                        display: "grid",
                        width: "100%",
                        gridTemplateColumns: "10% 90%",
                      }}
                    >
                      {serviceRequest?.currentStatus === "ONSITE" &&
                        closeFormArray.length == 0 ? (
                        <CircleIcon fontSize="small" color="success" />
                      ) : (
                        <CircleIcon fontSize="small" />
                      )}
                      {serviceRequest?.currentStatus === "ONSITE" &&
                        closeFormArray.length == 0 ? (
                        <span style={{ color: "green" }}> Onsite </span>
                      ) : (
                        <span> Onsite </span>
                      )}
                      <div
                        style={{
                          gridColumn: 1,
                          marginLeft: 10,
                          height: 20,
                          borderLeft: "1px solid black",
                        }}
                      ></div>
                      {/* <span style={{ gridColumn: 2, fontSize: 12 }}> test</span> */}
                      {onsiteDateTime !== undefined ? (
                        <span style={{ gridColumn: 2, fontSize: 12 }}>
                          {convertDateStamp(onsiteDateTime.date)} {"-"}{" "}
                          {convertTimeStamp(onsiteDateTime.date)}
                        </span>
                      ) : (
                        <span style={{ gridColumn: 2, fontSize: 12 }}></span>
                      )}
                    </div>
                    <div
                      style={{
                        marginLeft: 10,
                        height: 20,
                        borderLeft: "1px solid black",
                      }}
                    ></div>
                    <div
                      style={{
                        display: "grid",
                        width: "100%",
                        gridTemplateColumns: "10% 90%",
                      }}
                    >
                      {serviceRequest?.currentStatus === "SERVICE_COMPLETED" &&
                        closeFormArray.length == 0 ? (
                        <CircleIcon fontSize="small" color="success" />
                      ) : (
                        <CircleIcon fontSize="small" />
                      )}
                      {serviceRequest?.currentStatus === "SERVICE_COMPLETED" &&
                        closeFormArray.length == 0 ? (
                        <span style={{ color: "green" }}> Job Completed </span>
                      ) : (
                        <span> Job Completed </span>
                      )}
                      <div
                        style={{
                          gridColumn: 1,
                          marginLeft: 10,
                          height: 20,
                          borderLeft: "1px solid black",
                        }}
                      ></div>
                      {/* <span style={{ gridColumn: 2, fontSize: 12 }}> test</span> */}
                      {completedDateTime !== undefined ? (
                        <span style={{ gridColumn: 2, fontSize: 12 }}>
                          {convertDateStamp(completedDateTime.date)} {"-"}{" "}
                          {convertTimeStamp(completedDateTime.date)}
                        </span>
                      ) : (
                        <span style={{ gridColumn: 2, fontSize: 12 }}></span>
                      )}
                    </div>
                    <div
                      style={{
                        marginLeft: 10,
                        height: 20,
                        borderLeft: "1px solid black",
                      }}
                    ></div>
                    <div
                      style={{
                        display: "grid",
                        width: "100%",
                        gridTemplateColumns: "10% 90%",
                      }}
                    >
                      {closeFormArray.length > 0 ? (
                        <CircleIcon fontSize="small" color="success" />
                      ) : (
                        <CircleIcon fontSize="small" />
                      )}
                      {closeFormArray.length > 0 ? (
                        <span style={{ color: "green" }}> Close Form </span>
                      ) : (
                        <span> Close Form </span>
                      )}
                      <div
                        style={{
                          gridColumn: 1,
                          marginLeft: 10,
                          height: 20,
                          borderLeft: "1px solid black",
                        }}
                      ></div>
                      {/* <span style={{ gridColumn: 2, fontSize: 12 }}> test</span> */}
                      {invoiceDateTime !== undefined ? (
                        <span style={{ gridColumn: 2, fontSize: 12 }}>
                          {convertDateStamp(invoiceDateTime.date)} {"-"}{" "}
                          {convertTimeStamp(invoiceDateTime.date)}
                        </span>
                      ) : (
                        <span style={{ gridColumn: 2, fontSize: 12 }}></span>
                      )}
                    </div>
                    <div
                      style={{
                        marginLeft: 10,
                        height: 20,
                        borderLeft: "1px solid black",
                      }}
                    ></div>
                    <div
                      style={{
                        display: "grid",
                        width: "100%",
                        gridTemplateColumns: "10% 90%",
                      }}
                    >
                      {serviceRequest?.currentStatus === "EXCEPTION" ? (
                        <CircleIcon fontSize="small" color="success" />
                      ) : (
                        <CircleIcon fontSize="small" />
                      )}
                      {serviceRequest?.currentStatus === "EXCEPTION" ? (
                        <span style={{ color: "green" }}> Exception</span>
                      ) : (
                        <span> Exception </span>
                      )}
                    </div>
                    <div
                      style={{
                        marginLeft: 10,
                        height: 20,
                        borderLeft: "1px solid black",
                      }}
                    ></div>
                    <div
                      style={{
                        display: "grid",
                        width: "100%",
                        gridTemplateColumns: "10% 90%",
                      }}
                    >
                      {serviceRequest?.currentStatus !== "EXCEPTION" &&
                        serviceRequest?.currentStatus === "ASSIGNED" &&
                        rejectedArray.length > 0 &&
                        assignedArray.length > 1 ? (
                        <CircleIcon fontSize="small" color="success" />
                      ) : (
                        <CircleIcon fontSize="small" />
                      )}
                      {serviceRequest?.currentStatus !== "EXCEPTION" &&
                        serviceRequest?.currentStatus === "ASSIGNED" &&
                        rejectedArray.length > 0 &&
                        assignedArray.length > 1 ? (
                        <span style={{ color: "green" }}>Reassigned</span>
                      ) : (
                        <span> Reassigned </span>
                      )}
                      {/* <span style={{ gridColumn: 2, fontSize: 12 }}> test</span> */}
                      {reassignedDateTime !== undefined &&
                        serviceRequest?.currentStatus !== "EXCEPTION" &&
                        serviceRequest?.currentStatus === "ASSIGNED" &&
                        rejectedArray.length > 0 &&
                        assignedArray.length > 1 ? (
                        <span style={{ gridColumn: 2, fontSize: 12 }}>
                          {convertDateStamp(reassignedDateTime.date)} {"-"}{" "}
                          {convertTimeStamp(reassignedDateTime.date)}
                        </span>
                      ) : (
                        <span style={{ gridColumn: 2, fontSize: 12 }}></span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <ConversationModalComponent
        show={showConversationModal}
        setShow={setShowConversationModal}
        type={conversationType}
        payload={serviceRequest}
        setLoadingIndex={setRequestLoadingIndex}
        user={technician}
      />
      <Modal
        visible={isModalVisible}
        centered
        onOk={handleOk}
        onCancel={handleCancel}
        width={700}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Close
          </Button>,
        ]}
      >
        <ReactPlayer
          className="videoFrame"
          url={SERVER_URL.SERVICE_REQUEST_VIDEO + videoId}
          playing
          controls
        />
      </Modal>
      {isViewerOpen && (
        <ImageViewer
          src={
            images.length > 0 && imageType === "srImage"
              ? images
              : replacedPartsImages.length > 0 && imageType === "replacedParts"
                ? replacedPartsImages
                : newPartsImages.length > 0 && imageType === "newParts"
                  ? newPartsImages
                  : attachments.length > 0 && imageType === "attachment"
                    ? attachments
                    : signatures.length > 0 && imageType === "signature"
                      ? signatures
                      : []
          }
          currentIndex={currentImage}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      )}
    </Modal>
  );
};

const mapStateToProps = (states: RootState) => {
  return {}
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>
): DispatchProps => {
  return {
    getSRCloseForm: (id: any) => dispatch(getSRCloseForm(id)),
  };
};

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(ServiceRequestDetailsLayout)
);
