import { ThunkDispatch, ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { History } from "history";
import { SERVER_URL } from "../../configs/service-config";
import { getRequest, postRequest } from "../interceptor";
import {
  setIsAuthenticating,
  setResetPasswordfields,
  setSessionCredentials,
  setSessionCredentialsToStorage,
  removeSessionFromStorage,
  setUserDetails,
  getUserDetails,
  setClientId,
  getClientId,
} from "./actions";
import { LoginFormFields, SessionCredentials, UserDetails } from "./model";
import { setLoginErrorMessage } from "../error-msg/action";
import _isNull from "lodash/isNull";
import _isEqual from "lodash/isEqual";
import _isUndefined from "lodash/isUndefined";
import { createBrowserHistory } from "history";
import { message } from "antd";

const history: History = createBrowserHistory();

export const initializeUserDetails = (): ThunkAction<
  Promise<void>,
  {},
  {},
  AnyAction
> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    await dispatch(fetchUserDetails());
  };
};

export const fetchUserDetails = (): ThunkAction<
  Promise<void>,
  {},
  {},
  AnyAction
> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const clientId: any = await dispatch(getClientId());
    if (!_isNull(clientId)) {
      const endpoint = `${SERVER_URL.USER_DETAILS_ENDPOINT}?clientId=${clientId}`;

      await getRequest(endpoint)
        .then((response: UserDetails) => {
          if (!_isUndefined(response.clientId)) {
            dispatch(setClientId(response.clientId));
          }

          dispatch(setUserDetails(response));
        })
        .catch((error: any) => {
          // dispatch(removeSessionFromStorage(history));
          // window.location.href = window.location.origin + "/login";
          console.log("ERROR USER RESPONSE", error);
        });
    }

    if (_isNull(clientId)) {
      // dispatch(removeSessionFromStorage(history));
      // window.location.href = window.location.origin + "/login";
    }
  };
};

export const fetchUserByEmail = (
  email: string
): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const clientId: any = dispatch(getClientId());
    const fetchUserDetailsService = new URL(SERVER_URL.USER_BY_EMAIL);
    fetchUserDetailsService.searchParams.append("clientId", "1");
    fetchUserDetailsService.searchParams.append("email", email);
    return await getRequest(fetchUserDetailsService)
      .then((response: any) => response)
      .catch((error: any) => {
        console.log("ERROR", error);
      });
  };
};

export const formLoginSubmission = (
  loginFormFields: LoginFormFields,
  history: History
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(setIsAuthenticating(true));
    const authenticatingService = new URL(SERVER_URL.LOGIN_ENDPOINT);
    authenticatingService.searchParams.append("grant_type", "password");
    authenticatingService.searchParams.append("access_type", "admin");
    authenticatingService.searchParams.append(
      "username",
      loginFormFields.username
    );
    authenticatingService.searchParams.append(
      "password",
      loginFormFields.password
    );
    await postRequest(authenticatingService)
      .then((response: any) => {
        dispatch(setSessionCredentials(response));
        dispatch(
          setSessionCredentialsToStorage({
            refreshToken: response.refresh_token,
            accessToken: response.access_token,
          })
        );
        dispatch(setIsAuthenticating(false));
        history.push("/dashboard");
      })
      .catch((error: any) => {
        dispatch(setIsAuthenticating(false));
        dispatch(
          setLoginErrorMessage(
            error.status,
            "Invalid email or password",
            "An error occur in login service"
          )
        );
        console.log("ERROR", error);
      });
  };
};

export const setResetPassword = (
  email: string,
  history?: History
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const clientId: any = dispatch(getClientId());
    dispatch(setResetPasswordfields(email));
    dispatch(setIsAuthenticating(true));

    const data = await fetch(SERVER_URL.resetPassword, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        clientId: clientId,
        email,
        loginMode: "RESET",
      }),
    });

    if (_isEqual(data.status, 200)) {
      message.success("Reset password successfully");
      if (history) history.push("/login");
    } else {
      dispatch(
        setLoginErrorMessage(
          data.status,
          "Invalid email",
          "An error occur in login service"
        )
      );
    }
    dispatch(setIsAuthenticating(false));
  };
};

export const resetWithSetPassword = (
  email: string,
  password: string,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const clientId: any = dispatch(getClientId());

    let jsonRaw = {
      clientId: clientId,
      email,
      password,
      loginMode: null,
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(jsonRaw),
    }
    const resetWithSetPasswordURL = new URL(SERVER_URL.RESET_WITH_SETPASSWORD);
    const resetWithSetPasswordResponse = await postRequest(resetWithSetPasswordURL, requestOptions);
    return resetWithSetPasswordResponse;
  };
};

export const validatePassword = (
  password: string
): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const endpoint = SERVER_URL.VALIDATE_PASSWORD;
    const userDetails = dispatch(getUserDetails()).payload;
    const dataStore: any = localStorage.getItem("SESSION_CREDENTIALS");
    const { accessToken } = JSON.parse(dataStore);
    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      method: "POST",
      body: JSON.stringify({
        id: userDetails.id,
        password,
      }),
    };

    try {
      const req = new Request(endpoint.toString(), options);
      const res = await fetch(req);
      if (res.status === 200) {
        return true;
      } else return false;
    } catch (error) {
      return false;
    }
  };
};

export const changePassword = (
  password: string,
  history: History
): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const endpoint = SERVER_URL.CHANGE_PASSWORD_ENDPOINT;
    const userDetails = dispatch(getUserDetails()).payload;
    const dataStore: any = localStorage.getItem("SESSION_CREDENTIALS");
    const { accessToken } = JSON.parse(dataStore);
    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      method: "POST",
      body: JSON.stringify({
        id: userDetails.id,
        password,
      }),
    };

    try {
      const req = new Request(endpoint.toString(), options);
      const res = await fetch(req);
    } catch (error) {
      message.error("An error occured while changing the password", 3);
    }
  };
};

export const setTechnicianPasswordService = (
  email: string,
  password: string
): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const clientId: any = dispatch(getClientId());
    const endpoint = SERVER_URL.SET_TECHNICIAN_PASSWORD_ENDPOINT;
    const dataStore: any = localStorage.getItem("SESSION_CREDENTIALS");
    const { accessToken } = JSON.parse(dataStore);
    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      method: "POST",
      body: JSON.stringify({
        clientId: clientId,
        email,
        password,
        resetMode: "RESET",
      }),
    };

    try {
      const req = new Request(endpoint.toString(), options);
      const res = await fetch(req);
      return res;
    } catch (error) {
      message.error("An error occured while changing the password", 3);
    }
  };
};
