import React, { useState, useEffect } from "react";

import { Button, Input, Radio, message, Modal } from "antd";

import { FilterOutlined, SearchOutlined } from '@ant-design/icons';

import { connect } from "react-redux";

import { RootState } from "../../redux";

import { ThunkDispatch } from "redux-thunk";

import { Filter, FilterRule, FilterTag } from "../../redux/filter/model";

import { setFilter, setFilters } from "../../redux/filter/action";

import { saveFilter, fetchFilters, fetchFilterFields } from "../../redux/filter/service";

import FilterItem from "./filter-item";

import FilterItemGroup from "./filter-item-group";

import FilterTags from "./filter-tags";
import { getClientId } from "../../redux/auth/actions";

interface OwnProps {
}

interface StateProps {
  filter?: Filter,
  filters: Array<Filter>;
}

interface DispatchProps {
  saveFilter: (filter: Filter) => Promise<Filter>;
  setFilter: (filter: Filter) => void;
  setFilters: (filters: Array<Filter>) => void;
  fetchFilters: (q?: string) => void;
  fetchFilterFields: (filterClass: string) => void;
  getClientId: () => any;
}

type Props = OwnProps & StateProps & DispatchProps;

const FilterBuilder: React.FC<Props> = (props): JSX.Element => {

  const [debounce, setDebounce] = useState<any>();
  const [filterName, setFilterName] = useState<string>('');
  const [filterClass, setFilterClass] = useState<string>('BOTH');
  const [savingAllowed, setSavingAllowed] = useState<boolean>(false);
  const [confirmNameSaving, setConfirmNameSaving] = useState<boolean>(false);
  const [confirmSaving, setConfirmSaving] = useState<boolean>(false);
  const [newFilterVisible, setNewFilterVisible] = useState<boolean>(false);
  const [filter, setFilter] = useState<Filter>();
  const [filters, setFilters] = useState<Array<Filter>>([]);
  const [search, setSearch] = useState<string>();

  useEffect(() => {
    setFilter(props.filter);
    setFilters(props.filters);
    isSavingAllowed();
  }, [
    props.filter,
    props.filters,
  ]);

  const selectFilter = (filter: Filter) => {

    props.fetchFilterFields(filter.filterClass);
    props.setFilter({ ...filter });
  }

  const openAddFilter = () => {
    setFilterName('');
    setNewFilterVisible(true);
  }

  const cancelNewFilter = () => {
    setNewFilterVisible(false);
  }

  const filterNameChanged = (e: any) => {
    setFilterName(e.target.value);
  }

  const filterClassChanged = (e: any) => {
    setFilterClass(e.target.value);
  }

  const nameChanged = (e: any) => {
    if (props.filter) {
      props.filter.name = e.target.value;
      props.setFilter({ ...props.filter });
    }
  }

  const classChanged = (e: any) => {
    if (props.filter) {
      props.filter.filterClass = e.target.value;
      props.setFilter({ ...props.filter });
      props.fetchFilterFields(e.target.value);
    }
  }

  /**
   * Add a new filter.
   */
  const addFilter = async () => {

    const rule: FilterRule = {
      seqNo: 1,
      ruleClass: 'RULE',
      filterRules: [],
    }

    const group: FilterRule = {
      seqNo: 1,
      ruleClass: 'GROUP',
      filterRules: [rule],
    }

    const filterGroup: FilterRule = {
      seqNo: 1,
      ruleClass: 'GROUP',
      filterRules: [group]
    }

    const clientId: any = props.getClientId();

    const newFilter: Filter = {
      clientId: clientId,
      name: filterName,
      filterClass: filterClass,
      preset: false,
      shared: false,
      adminFilter: true,
      filterRules: [filterGroup],
      filterTags: []
    }

    setConfirmNameSaving(true);
    const savedFilter: Filter = await props.saveFilter(newFilter);
    props.filters.push(savedFilter);
    if (props.setFilters) props.setFilters([...props.filters]);

    setNewFilterVisible(false);
    setConfirmNameSaving(false);

    selectFilter(savedFilter);
  }

  const togglePreset = (e: React.MouseEvent<HTMLElement>, index: number) => {

    props.filters[index].preset = !props.filters[index].preset;
    if (props.setFilters) props.setFilters([...props.filters]);

    e.stopPropagation();

  }

  const searchChanged = (e: any) => {

    clearTimeout(debounce);

    setSearch(e.target.value);

    const newDebounce = setTimeout(() => {
      props.fetchFilters(e.target.value);
    }, 1000);
    setDebounce(newDebounce);
  }

  const addGroup = () => {

    if (props.filter) {

      const rule: FilterRule = {
        seqNo: 1,
        ruleClass: 'RULE',
        filterRules: [],
      }

      const group: FilterRule = {
        seqNo: 1,
        ruleClass: 'GROUP',
        filterRules: [rule],
      }

      const seqNo = props.filter.filterRules[props.filter.filterRules.length - 1].seqNo;

      const filterGroup: FilterRule = {
        seqNo: seqNo ? seqNo + 1 : 1,
        operator: props.filter.filterRules.length > 0 ? 'AND' : undefined,
        ruleClass: 'GROUP',
        filterRules: [group]
      }

      props.filter.filterRules.push(filterGroup);

      props.setFilter({ ...props.filter });
    }
  }

  const deleteGroup = (index: number) => {

    if (props.filter) {
      props.filter.filterRules.splice(index, 1);
      props.setFilter({ ...props.filter });
    }
  }

  const tagsChanged = (filterTags: Array<FilterTag>) => {

    if (props.filter) props.filter.filterTags = filterTags;
  }

  const toggleDetailPreset = () => {
    if (props.filter) {
      props.filter.preset = !props.filter.preset;
      props.setFilter({ ...props.filter })
    }
  }

  const clear = () => {

    if (filter) {

      const rule: FilterRule = {
        seqNo: 1,
        ruleClass: 'RULE',
        filterRules: [],
      }

      const group: FilterRule = {
        seqNo: 1,
        ruleClass: 'GROUP',
        filterRules: [rule],
      }

      const filterGroup: FilterRule = {
        seqNo: 1,
        operator: undefined,
        ruleClass: 'GROUP',
        filterRules: [group]
      }

      filter.filterRules = [filterGroup];
      props.setFilter({ ...filter });
    }
  }

  const saveFilter = async () => {

    if (props.filter) {

      setConfirmSaving(true);

      const savedFilter: Filter = await props.saveFilter(props.filter);
      props.setFilter(savedFilter);

      const index = props.filters.findIndex((filter: any) => { return filter.id == savedFilter.id });
      props.filters[index].name = savedFilter.name;
      props.filters[index].preset = savedFilter.preset;
      props.setFilters([...filters]);

      setConfirmSaving(false);

      message.success('The filter was saved successfully.', 3);
    }
  }

  const isSavingAllowed = () => {

    if (props.filter) {
      let allowed = validateContent(props.filter?.filterRules);
      setSavingAllowed(allowed);
    } else {
      setSavingAllowed(false);
    }
  }

  const validateContent = (filterRules: Array<FilterRule>): boolean => {

    for (let i = 0; i < filterRules.length; i++) {

      let filterRule = filterRules[i];
      if (filterRule.ruleClass == 'GROUP') {
        const valid = validateContent(filterRule.filterRules);
        if (!valid) return valid;
      } else if (filterRule.ruleClass == 'RULE' &&
        (!filterRule.filterField || (!filterRule.filterValue && filterRule.filterField.hasOptions && !filterRule.optionalValue))) {
        return false;
      }
    }
    return true;
  }

  return (
    <div className="filter-builder">
      <div className="filter-list-container">
        <div className="header">
          <h3>Filter Builder</h3>
          <Button type="link" size="small" onClick={openAddFilter}>+ Add New</Button>
          <div></div>
        </div>
        <Input placeholder="Search Filters or Tags" value={search} prefix={<SearchOutlined />} allowClear onChange={searchChanged} />
        <div className="preset-header">Preset</div>
        <div className="filter-list">
          {filters.map((filter, index) => {
            return <FilterItem key={index} filter={filter} selectFilter={selectFilter} togglePreset={(e) => togglePreset(e, index)} />
          })}
        </div>
      </div>

      <div className="filter-detail">
        {filter ? <>
          <div className="header">
            <Input className="input-header" value={filter.name} onChange={nameChanged} />
            <Radio.Group onChange={classChanged} value={filter.filterClass}>
              <Radio value={'BOTH'}>BOTH</Radio>
              <Radio value={'GRID'}>GRID</Radio>
              <Radio value={'BOARD'}>BOARD</Radio>
            </Radio.Group>
            <Button type="primary" style={{ background: 'purple', borderColor: 'purple' }} size="small" onClick={addGroup}>
              + GROUP
            </Button>
          </div>
          <div className="filter-detail-container">
            <div>
              {filter.filterRules.map((filterGroup, index) => {
                return <FilterItemGroup key={index} filterGroupIndex={index} removable={index != 0} deleteGroup={() => deleteGroup(index)} />
              })}
            </div>
            <div className="filter-tag-container">
              <FilterTags tags={filter.filterTags} onTagsChanged={tagsChanged} />
            </div>
            <div className="preset">
              <div className="outer" onClick={toggleDetailPreset}>
                {filter.preset ? <div className="inner"></div> : null}
              </div>
              <div style={{ marginLeft: 8 }}>Preset Filter</div>
            </div>
            <div className="controls">
              <Button type="ghost" onClick={clear}>CLEAR</Button>&nbsp;
              <Button type="primary" style={savingAllowed ? { background: '#1e5c8bff', borderColor: '#1e5c8bff' } : { background: 'lightgray', borderColor: 'lightgray' }} loading={confirmSaving} disabled={!savingAllowed} onClick={saveFilter}>SAVE</Button>
            </div>
          </div>
        </> : null}
      </div>

      <Modal
        title="Enter Filter Name"
        visible={newFilterVisible}
        onOk={addFilter}
        confirmLoading={confirmNameSaving}
        onCancel={cancelNewFilter}>
        <Input placeholder="Enter filter name" value={filterName} prefix={<FilterOutlined />} onChange={filterNameChanged} />
        <br /><br /><br />
        <div style={{ textAlign: 'center' }}>
          <Radio.Group onChange={filterClassChanged} value={filterClass}>
            <Radio value={'BOTH'}>BOTH</Radio>
            <Radio value={'GRID'}>GRID</Radio>
            <Radio value={'BOARD'}>BOARD</Radio>
          </Radio.Group>
        </div>
        <br />
      </Modal>
    </div>
  );
};

const mapStateToProps = (states: RootState): StateProps => {
  return {
    filter: states.filterReducer.state.filter,
    filters: states.filterReducer.state.filters,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
  return {
    saveFilter: (filter) => saveFilter(filter),
    setFilter: (filter) => dispatch(setFilter(filter)),
    setFilters: (filters) => dispatch(setFilters(filters)),
    fetchFilters: (q?: string) => dispatch(fetchFilters(q)),
    fetchFilterFields: (filterClass) => dispatch(fetchFilterFields(filterClass)),
    getClientId: () => dispatch(getClientId())
  };
};

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(FilterBuilder))
