import React from "react";
import { connect } from "react-redux";
import { History } from "history";
import { RootState } from "../../redux";
import { ThunkDispatch } from "redux-thunk";
import { Spin, Tabs } from "antd";
import { SchedulerSidebar, SchedulerGridComponent, ToolbarComponent, ClaimHistory, TechnicianListComponent } from "../../components";
import GoogleMaps from "../../components/maps/maps";
import ImportServiceRequestLayout from "../../components/layouts/modal/import-sr-layout";
import ServiceRequestLayout from "../../components/layouts/modal/service-request-layout";
import ServiceRequestList from "../../components/maps/service-request-list";
import { fetchTechniciansBOARD, fetchTechniciansGRID, fetchTechniciansServiceRequestCount } from "../../redux/technician/service";
import { getAssignedServiceRequests, setAssignedServiceRequests } from "../../redux/service-request/action";
import { ServiceRequest, ServiceRequestCount } from "../../redux/service-request/model";
import { Technician } from "../../redux/technician/model";

import "./SchedulerPage.scss";
import { fetchAssignedServiceRequest } from "../../redux/service-request/service";
import { DailyWeekly, SchedulerType, Toolbar } from "../../redux/scheduler/model";
import { setIsLoadingScheduler, setSchedulerToolbar, setSchedulerType } from "../../redux/scheduler/action";
import { fetchFilters } from "../../redux/filter/service";
import _isqual from "lodash/isEqual";
import _isUndefined from "lodash/isUndefined";
import { fetchUnassignedSR } from "../../redux/unassign-service-request/service";
const { TabPane } = Tabs;

interface ComponentProps { history: History; location: any }
interface ReduxState {
   isLoading: boolean;
   toolbar: Toolbar;
   serviceRequestCount: Array<ServiceRequestCount>;
}
interface DispatchProps {
   setIsLoadingScheduler: (status: boolean) => void;
   fetchTechniciansBOARD: () => void;
   fetchTechniciansGRID: () => void;
   fetchAssignedServiceRequest: () => void;
   fetchUnassignedSR: () => void;
   getAssignedServiceRequests: () => any;
   setAssignedServiceRequests: (serviceRequests: Array<ServiceRequest>) => void;
   fetchTechniciansServiceRequestCount: () => any;
   fetchFilters: () => any;
   setSchedulerType: (t: SchedulerType) => void;
   setToolbar: (params: Toolbar) => void;
}
type Props = ComponentProps & ReduxState & DispatchProps;
const SchedulerPage: React.FC<Props> = (props): JSX.Element => {
   const [isClaimHistoryShow, setIsClaimHistoryShow] = React.useState(false);
   const [serviceRequestId, setServiceRequestId] = React.useState<any>(-1);
   const [serviceRequestMap, setServiceRequestMap] = React.useState<any>(null);
   React.useEffect(() => {
      onInit();
      assigningSetup();
      window.addEventListener("reloadScheduler", onInit);
      window.addEventListener("refreshTechnicianBoard", refreshTechnianBoard);
      window.addEventListener("newScheduledRequestCreated", addScheduledServiceRequest);
      window.addEventListener("assigningSetup", assigningSetup);
      return () => {
         window.removeEventListener("assigningSetup", assigningSetup);
         window.removeEventListener("refreshTechnicianBoard", assigningSetup);
         window.removeEventListener("reloadScheduler", onInit);
         window.removeEventListener("newScheduledRequestCreated", addScheduledServiceRequest);
      }
   }, [props.toolbar]);


   const refreshTechnianBoard = () => {
      props.fetchTechniciansBOARD();
   }

   const onInit = () => {
      props.setIsLoadingScheduler(true);
      props.fetchTechniciansGRID();
      props.fetchTechniciansBOARD();
      props.fetchUnassignedSR();
      props.fetchAssignedServiceRequest();
      props.fetchTechniciansServiceRequestCount();
      props.fetchFilters();
      setTimeout(() => props.setIsLoadingScheduler(false), 2000);
   }

   const assigningSetup = (param?: any) => {
      if (!_isUndefined(props.location.state) && !_isUndefined(props.location.state.notificationItem)) {
         const notificationItem = props.location.state.notificationItem;
         window.dispatchEvent(new CustomEvent("scrollToUnassignedSR", { detail: { title: notificationItem.title } }));
      }

      if (!_isUndefined(param)) {
         const notificationItem = param.detail.notificationItem;
         window.dispatchEvent(new CustomEvent("scrollToUnassignedSR", { detail: { title: notificationItem.title } }));
      }
   }

   const addScheduledServiceRequest = async (event: Event) => {
      //@ts-ignore
      const { request } = event.detail;

      let assignedServiceRequests: Array<ServiceRequest> = props.getAssignedServiceRequests().payload;
      assignedServiceRequests.push(request);
      setAssignedServiceRequests([...assignedServiceRequests]);
      window.dispatchEvent(new Event("reloadSchedulerGridComponent"));
   }

   function setClaimHistoryShow(isClaimHistoryComponent: boolean, requestId?: number): void {
      if (!isClaimHistoryComponent && isClaimHistoryShow) return;
      const isClaimHistoryShowed = !isClaimHistoryShow;
      setIsClaimHistoryShow(isClaimHistoryShowed);
      setServiceRequestId(requestId);
   }


   const renderSpinner = () => {
      return (
         <Spin style={{
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'white',
            top: 0,
            right: 0,
            marginLeft: 240,
            left: 0,
            bottom: 0
         }} />
      )
   }

   return (
      <Tabs onChange={(tab: string) => {
         if (_isqual(tab, "BOARD")) {
            props.setSchedulerType(SchedulerType.BOARD);
         }

         if (_isqual(tab, "GRID")) {
            props.setSchedulerType(SchedulerType.GRID);
         }
      }}
         className='scheduler-page' type="card">
         <TabPane key={"GRID"} tab="Schedule">
            <div
               className='background-color-white'
               style={{
                  position: 'relative',
                  borderRadius: 16,
                  padding: 16,
                  height: 'inherit',
                  minWidth: 1000
               }}
            >
               <ToolbarComponent isScheduler={true} type={"GRID"} />
               <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: 'calc(100% - 36px)'
               }}>
                  <div style={{
                     width: 200,
                     minWidth: 200
                  }}>
                     {_isqual(props.isLoading, true) ? renderSpinner() : <SchedulerSidebar
                        setClaimHistoryShow={setClaimHistoryShow}
                     />}
                  </div>
                  <div style={{
                     width: '100%'
                  }}>
                     {_isqual(props.isLoading, true) ? renderSpinner() : <SchedulerGridComponent />}
                  </div>
               </div>
               <ServiceRequestLayout />
               <ImportServiceRequestLayout />
               {isClaimHistoryShow &&
                  <ClaimHistory
                     serviceRequestId={serviceRequestId}
                     setClaimHistoryShow={setClaimHistoryShow}
                  />
               }
            </div>
         </TabPane>

         <TabPane key={"BOARD"} tab="Map">
            <div
               className='background-color-white'
               style={{
                  position: 'relative',
                  borderRadius: 16,
                  padding: 16,
                  height: 'inherit',
                  minWidth: 1000
               }}
            >
               <ToolbarComponent isScheduler={false} type={"BOARD"} />
               <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: 'calc(100% - 36px)'
               }}>
                  <div style={{
                     width: 360,
                     minWidth: 360,
                     overflowY: 'scroll'
                  }}>
                     <TechnicianListComponent
                        activeTab={'map'}
                        showTechId={true} showSRCount={true} />
                  </div>
                  <div
                     style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column'
                     }}
                  >
                     <div style={{
                        width: '100%',
                        height: 'calc(100% - 180px)',
                     }}>
                        <GoogleMaps/>
                     </div>
                        <ServiceRequestList/>
                  </div>
               </div>
            </div>
         </TabPane>
      </Tabs >
   );
}

const mapStateToProps = (states: RootState): ReduxState => {
   return {
      isLoading: states.schedulerReducer.state.isLoading,
      toolbar: states.schedulerReducer.state.toolbar,
      serviceRequestCount: states.technicianReducer.state.techniciansSRCounts,
   };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
   return {
      setIsLoadingScheduler: (status) => dispatch(setIsLoadingScheduler(status)),
      fetchTechniciansBOARD: () => dispatch(fetchTechniciansBOARD()),
      fetchTechniciansGRID: () => dispatch(fetchTechniciansGRID()),
      fetchAssignedServiceRequest: () => dispatch(fetchAssignedServiceRequest()),
      fetchUnassignedSR: () => dispatch(fetchUnassignedSR()),
      getAssignedServiceRequests: () => dispatch(getAssignedServiceRequests()),
      setAssignedServiceRequests: (serviceRequest: Array<ServiceRequest>) => dispatch(setAssignedServiceRequests(serviceRequest)),
      fetchTechniciansServiceRequestCount: () => dispatch(fetchTechniciansServiceRequestCount()),
      fetchFilters: () => dispatch(fetchFilters()),
      setSchedulerType: (t) => dispatch(setSchedulerType(t)),
      setToolbar: (params) => dispatch(setSchedulerToolbar(params))
   };
};

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(SchedulerPage));



















