export const BASIC_AUTH = "Basic RTJGODQ1MzY0Njg5MTg1NTJENzc4M0NCRDdBODk6dTd4IUElQypGLUphTmRSZ1VrWHAyczV2OHkvQj9FKEcrS2JQZVNoVm1ZcTN0Nnc5eiRDJkYpSkBNY1FmVGpXbg==";

/* Test Environment Endpoint */

// Localhost 
// export const ROOT_DOMAIN = 'http://localhost:8081';
  
/* Integration Endpoint */
// export const ROOT_DOMAIN = "https://int.fsa-server.managelifeapi.co";

/* Staging Endpoint */
export const ROOT_DOMAIN = 'https://stg.fsa-server.managelifeapi.co';


/* Hotfix Environment Endpoint */
// export const ROOT_DOMAIN = 'https://hf.fsa-server.managelifeapi.co';

/* Production Environment Endpoint */
// export const ROOT_DOMAIN = 'https://prd.fsa-server.managelifeapi.co';

//trigger build

const DOMAIN_PATH = `${ROOT_DOMAIN}`;
const LISTENER_PATH = `${ROOT_DOMAIN}`;

export const VERSION = '1.8.3';

const API_NAME = "ml-fsa";

const API_V1 = "v1";
const API_V2 = "v2";

const USER_TYPES = {
  users: "users",
  admin: "admin"
};

const SERVICES = {
  USER_SERVICE: "user-service",
  SERVICE_REQUEST_SERVICE: "request-service",
  FILTER_SERVICE: "filter-service",
  NOTIFICATION_SERVICE: "notification-service",
  ASURION_SERVICE: "asurion-service"
};

// export const GOOGLE_KEY = "AIzaSyAR-YizlMFsC9EtDXH2KKxfv6Jk9l5wC8w";
export const GOOGLE_KEY = "AIzaSyBGsq8DbdtN8HerevsjmjWABRXfxJoQse0";



export const TWILIO_BASE = 'https://conversations.twilio.com';
export const TWILIO_BASE_URL = 'https://conversations.twilio.com/v1';
export const TWILIO_MEDIA_URL = 'https://mcs.us1.twilio.com/v1';
export const TWILIO_CONVERSATION_SERVICE = 'IS2a6c5170f94e4aa797ea3705de66ec67';
export const TWILIO_CONVERSATION_MLFSA_REPRESENTATIVE_SERVICE = "IS6c38c3b9f90740248c24c3308f9bc939";
export const TWILIO_CONVERSATION_MLFSA_REPRESENTATIVE_MEMBER_SERVICE = "IS0523cfb7635b4dbdb75bb0395ecc257c";
export const TWILIO_CONVERSATION_MLFSA_GENERAL_SERVICE = "IS46bda6b352a84641af8097399bb21fee";

export const TWILIO_SID = "AC4590d628852954099b0519ff94413cd0";
export const TWILIO_TOKEN = "2beb04e8959778c9b24703799a8b7075";
export const TWILIO_BASE_API = `https://api.twilio.com/2010-04-01/Accounts/${TWILIO_SID}`

export const SERVER_URL = {
  AUTHENTICATION_ENDPOINT: `${DOMAIN_PATH}/oauth/token`,
  GET_USER_DETAILS_ENDPOINT: `${DOMAIN_PATH}/${USER_TYPES.users}/api/${API_NAME}/${API_V1}/me`,
  GET_DETAILS_FROM_MEMBER_PORTAL_ENDPOINT: `${DOMAIN_PATH}/${SERVICES.USER_SERVICE}/api/${API_NAME}/${API_V1}/get-admin-details`,
  RESET_PASSWORD_ENDPOINT: `${DOMAIN_PATH}/${SERVICES.USER_SERVICE}/api/${API_NAME}/${API_V1}/password/reset`,
  SET_TECHNICIAN_PASSWORD_ENDPOINT: `${DOMAIN_PATH}/${SERVICES.USER_SERVICE}/api/${API_NAME}/${API_V1}/password/specific-reset`,
  VALIDATE_PASSWORD: `${DOMAIN_PATH}/${SERVICES.USER_SERVICE}/api/${API_NAME}/${API_V1}/password/validate`,
  CHANGE_PASSWORD_ENDPOINT: `${DOMAIN_PATH}/${SERVICES.USER_SERVICE}/api/${API_NAME}/${API_V1}/password/change`,

  // More details net to adjust
  LOGIN_ENDPOINT: `${DOMAIN_PATH}/oauth/token`,
  USER_DETAILS_ENDPOINT: `${DOMAIN_PATH}/${SERVICES.USER_SERVICE}/api/${API_NAME}/v1/me`,
  USER_BY_EMAIL: `${DOMAIN_PATH}/${SERVICES.USER_SERVICE}/api/${API_NAME}/v1/users/email`,
  resetPassword: `${DOMAIN_PATH}/${SERVICES.USER_SERVICE}/api/${API_NAME}/${API_V1}/password/reset`,
  RESET_WITH_SETPASSWORD: `${DOMAIN_PATH}/${SERVICES.USER_SERVICE}/api/${API_NAME}/${API_V1}/password/set`,
  DISPATCHER: `${DOMAIN_PATH}/${SERVICES.USER_SERVICE}/api/ml-fsa/v1/${USER_TYPES.users}/dispatcher`,
  DISPATCHERS: `${DOMAIN_PATH}/${SERVICES.USER_SERVICE}/api/ml-fsa/v1/${USER_TYPES.users}/dispatchers`,
  technicians: `${DOMAIN_PATH}/${SERVICES.USER_SERVICE}/api/ml-fsa/v1/${USER_TYPES.users}/technicians`,
  getUserById: `${DOMAIN_PATH}/${SERVICES.USER_SERVICE}/api/ml-fsa/v1/users/`,
  filters: `${DOMAIN_PATH}/${SERVICES.FILTER_SERVICE}/api/ml-fsa/v1/filters`,
  userFilters: `${DOMAIN_PATH}/${SERVICES.FILTER_SERVICE}/api/ml-fsa/v1/filters/user`,
  filterFields: `${DOMAIN_PATH}/${SERVICES.FILTER_SERVICE}/api/ml-fsa/v1/filter-fields`,
  CONVERSATION_CHANNEL_ENDPOINT: `${DOMAIN_PATH}/${SERVICES.SERVICE_REQUEST_SERVICE}/api/${API_NAME}/v1/channels`,
  ASSIGNEDSR: `${DOMAIN_PATH}/${SERVICES.SERVICE_REQUEST_SERVICE}/api/${API_NAME}/v1/${USER_TYPES.admin}/service-requests/assigned`,
  unAssignedSR: `${DOMAIN_PATH}/${SERVICES.SERVICE_REQUEST_SERVICE}/api/${API_NAME}/v1/${USER_TYPES.admin}/service-requests/unassigned`,
  ASSIGN_SERVICE_REQUEST: `${DOMAIN_PATH}/${SERVICES.SERVICE_REQUEST_SERVICE}/api/${API_NAME}/v1/${USER_TYPES.admin}/service-requests/assign`,
  UNASSIGN_SERVICE_REQUEST: `${DOMAIN_PATH}/${SERVICES.SERVICE_REQUEST_SERVICE}/api/${API_NAME}/v1/${USER_TYPES.admin}/service-requests/unassign/`,
  SR_COUNT_PER_TECHNICIAN: `${DOMAIN_PATH}/${SERVICES.SERVICE_REQUEST_SERVICE}/api/${API_NAME}/v1/service-requests/count`,
  SR_COUNT_PER_TECHNICIAN_ALL: `${DOMAIN_PATH}/${SERVICES.SERVICE_REQUEST_SERVICE}/api/${API_NAME}/v1/service-requests/count/all`,
  COMPLETED_TECHNICIAN_SERVICE_REQUEST: `${DOMAIN_PATH}/${SERVICES.SERVICE_REQUEST_SERVICE}/api/${API_NAME}/v1/service-requests/completed`,
  TECHNICIAN_SERVICE_REQUEST: `${DOMAIN_PATH}/${SERVICES.SERVICE_REQUEST_SERVICE}/api/${API_NAME}/v1/service-requests`,
  TECHNICIAN_CURRENT_SR: `${DOMAIN_PATH}/${SERVICES.SERVICE_REQUEST_SERVICE}/api/${API_NAME}/v1/service-requests/current`,
  VENDOR_REQUESTS: `${DOMAIN_PATH}/${SERVICES.SERVICE_REQUEST_SERVICE}/api/${API_NAME}/v1/service-requests/vendor`,
  AVENDOR_TECHNICIANS: `${DOMAIN_PATH}/${SERVICES.USER_SERVICE}/api/ml-fsa/v1/technicians/vendor`,
  VENDOR: `${DOMAIN_PATH}/${SERVICES.USER_SERVICE}/api/managelife/v1/vendors`,
  VENDORS: `${DOMAIN_PATH}/${SERVICES.USER_SERVICE}/api/managelife/v1/admin/vendors`,
  TYPE_OF_SERVICES: `${DOMAIN_PATH}/${SERVICES.SERVICE_REQUEST_SERVICE}/api/ml-fsa/v1/type-of-services`,
  SERVICE_LOCATIONS: `${DOMAIN_PATH}/${SERVICES.USER_SERVICE}/api/ml-fsa/v1/zipcodes`,
  USERS: `${DOMAIN_PATH}/${SERVICES.USER_SERVICE}/api/ml-fsa/v1/users`,
  TECHNICIANS: `${DOMAIN_PATH}/${SERVICES.USER_SERVICE}/api/ml-fsa/v1/technicians`,
  CLAIMS_HISTORY: `${DOMAIN_PATH}/${SERVICES.SERVICE_REQUEST_SERVICE}/api/${API_NAME}/v1/service-requests/claims`,
  ACCEPT_SERVICE_REQUEST: `${DOMAIN_PATH}/${SERVICES.SERVICE_REQUEST_SERVICE}/api/${API_NAME}/${API_V1}/service-requests/accept`,
  ADMIN_SERVICE_REQUEST: `${DOMAIN_PATH}/${SERVICES.SERVICE_REQUEST_SERVICE}/api/${API_NAME}/${API_V1}/admin/service-requests`,
  Notifications: `${DOMAIN_PATH}/${SERVICES.NOTIFICATION_SERVICE}/api/${API_NAME}/v1/notifications`,
  notificationListener: `${LISTENER_PATH}/listener`,
  assignedSr: `${DOMAIN_PATH}/${SERVICES.SERVICE_REQUEST_SERVICE}/api/${API_NAME}/${API_V1}/${USER_TYPES.admin}/assigned`,
  admin: `${DOMAIN_PATH}/request-service/api/ml-fsa/v1`,
  AVAILABLE_TECHNICIANS: `${DOMAIN_PATH}/user-service/api/ml-fsa/v1/technicians/available`,
  BANK_INFORMATION: `${DOMAIN_PATH}/user-service/api/ml-fsa/v1/technicians/bank-information`,
  SERVICE_EXPERIENCE: `${DOMAIN_PATH}/user-service/api/ml-fsa/v1/technicians/service-experience`,
  AVAILABILITIES: `${DOMAIN_PATH}/user-service/api/ml-fsa/v1/technicians/availability`,
  VENDOR_SERVICE_REQUEST_HISTORY: `${DOMAIN_PATH}/${SERVICES.SERVICE_REQUEST_SERVICE}/api/${API_NAME}/v1/service-requests/listings/vendors`,
  VENDOR_TECH_SERVICE_REQUEST_HISTORY: `${DOMAIN_PATH}/${SERVICES.SERVICE_REQUEST_SERVICE}/api/${API_NAME}/v1/service-requests/listings/technicians`,
  NOTIF_ADMIN_TO_TECH_ENDPOINT: `${DOMAIN_PATH}/${SERVICES.NOTIFICATION_SERVICE}/api/${API_NAME}/${API_V1}/notifications/chat/admin-to-technician`,
  NOTIF_ADMIN_TO_MEMBER_ENDPOINT: `${DOMAIN_PATH}/${SERVICES.NOTIFICATION_SERVICE}/api/${API_NAME}/${API_V1}/notifications/chat/admin-to-member`,
  SERVICE_REQUEST_BYID_ENDPOINT: `${DOMAIN_PATH}/${SERVICES.SERVICE_REQUEST_SERVICE}/api/${API_NAME}/${API_V1}/service-requests/`,
  DISPLAY_CHAT_PHOTO: `${DOMAIN_PATH}/${SERVICES.SERVICE_REQUEST_SERVICE}/api/${API_NAME}/${API_V1}/chat/images`,
  SERVICE_REQUEST_PHOTO: `${DOMAIN_PATH}/${SERVICES.SERVICE_REQUEST_SERVICE}/api/${API_NAME}/${API_V1}/service-requests/photo/`,
  SERVICE_REQUEST_VIDEO: `${DOMAIN_PATH}/${SERVICES.SERVICE_REQUEST_SERVICE}/api/${API_NAME}/${API_V1}/service-requests/video/`,
  SERVICE_REQUEST_ITEM_PHOTO: `${DOMAIN_PATH}/${SERVICES.SERVICE_REQUEST_SERVICE}/api/${API_NAME}/${API_V1}/items/photo/`,
  ITEM_PHOTO: `${DOMAIN_PATH}/${SERVICES.SERVICE_REQUEST_SERVICE}/api/${API_NAME}/${API_V1}/items/photos`,
  NOTES: `${DOMAIN_PATH}/${SERVICES.SERVICE_REQUEST_SERVICE}/api/${API_NAME}/${API_V1}/service-notes`,
  HISTORY: `${DOMAIN_PATH}/${SERVICES.SERVICE_REQUEST_SERVICE}/api/${API_NAME}/${API_V1}/service-requests/status/histories`,
  CLOSE_FORM: `${DOMAIN_PATH}/${SERVICES.SERVICE_REQUEST_SERVICE}/api/${API_NAME}/${API_V1}/close-form`,
  CLOSE_FORM_PARTS: `${DOMAIN_PATH}/${SERVICES.SERVICE_REQUEST_SERVICE}/api/${API_NAME}/${API_V1}/close-form/part`,
  CLOSE_FORM_ATTACHMENT: `${DOMAIN_PATH}/${SERVICES.SERVICE_REQUEST_SERVICE}/api/${API_NAME}/${API_V1}/close-form/attachment`,
  CLOSE_FORM_SIGNATURE: `${DOMAIN_PATH}/${SERVICES.SERVICE_REQUEST_SERVICE}/api/${API_NAME}/${API_V1}/close-form/signature`,
  TWILIO_MEDIA: `${DOMAIN_PATH}/${SERVICES.SERVICE_REQUEST_SERVICE}/api/ml-fsa/v1/twilio`,
  ASURION_RETRIEVE_JOB_DETAILS: `${DOMAIN_PATH}/${SERVICES.ASURION_SERVICE}/job-details/asurion`,
  COSTCO_RETRIEVE_JOB_DETAILS: `${DOMAIN_PATH}/${SERVICES.ASURION_SERVICE}/job-details/costco`,
};
