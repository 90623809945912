import { ThunkDispatch, ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { SERVER_URL } from "../../configs/service-config";
import { DailyWeekly, SchedulerType, Time, Toolbar } from "../scheduler/model";
import { AssignServiceRequest, RequestFormValue, ServiceRequest } from "./model";
import { getClock, getSchedulerToolbar, getSchedulerType } from "../scheduler/action";
import { getRequest, postRequest } from "../interceptor";

import _isUndefined from "lodash/isUndefined";
import _isEqual from "lodash/isEqual";
import _isNull from "lodash/isNull";
import _isEmpty from "lodash/isEmpty";
import moment from "moment";

import { setAssignedServiceRequests } from "./action";
import { getTechniciansGrid } from "../technician/action";
import { Technician } from "../technician/model";
import { getClientId } from "../auth/actions";
import { getSearchSRNumber, getSearchType, getSearchValue } from "../filter/action";
import { SearchType } from "../filter/model";

const response = async (data?: any, type?: string,): Promise<any> => {
  switch (data.status) {
    case 200:
      if (_isEqual(type, 'blob')) {
        return {
          statusCode: 200,
          hasError: false,
          errorMsg: '',
          body: await data.blob(),
        };
      }

      return {
        statusCode: 200,
        hasError: false,
        errorMsg: '',
        body: await data.json(),
      };
    case 201:
      return {
        statusCode: 201,
        hasError: false,
        errorMsg: "",
        body: await data.json(),
      };
    case 500:
      return {
        statusCode: 500,
        hasError: true,
        errorMsg: "Internal Server Error",
        body: await data.json(),
      };
    case 400:
      return {
        statusCode: 400,
        hasError: true,
        errorMsg: "Bad Request",
        body: await data.json(),
      };
    case 401:
      return {
        statusCode: 401,
        hasError: true,
        errorMsg: "Invalid Authentication",
        body: await data.json(),
      };
    case 404:
      return {
        statusCode: 404,
        hasError: true,
        errorMsg: "Not Found",
        body: await data.json(),
      };
    default:
      return {
        statusCode: undefined,
        hasError: true,
        errorMsg: "Cannot read error ",
        body: data,
      };
  }
};

export const fetchAllNotes = (
  serviceId: string,
): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {

    const endpoint = `${SERVER_URL.NOTES}?serviceRequestId=${serviceId}`;
    const dataStore: any = localStorage.getItem("SESSION_CREDENTIALS");
    const { accessToken }: any = JSON.parse(dataStore);

    const options = {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
      method: 'GET'
    };

    try {
      const req = new Request(endpoint.toString(), options);
      const res = await fetch(req);
      const finalResponse = await response(res);
      return finalResponse;
    } catch (error) {
      const finalResponse = await response(error);
      return finalResponse;
    }
  };
};

export const serviceRequestById = (
  serviceId: string,
): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {

    const endpoint = SERVER_URL.SERVICE_REQUEST_BYID_ENDPOINT + serviceId;
    const dataStore: any = localStorage.getItem("SESSION_CREDENTIALS");
    const { accessToken }: any = JSON.parse(dataStore);

    const options = {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
      method: 'GET'
    };

    try {
      const req = new Request(endpoint.toString(), options);
      const res = await fetch(req);
      const finalResponse = await response(res);
      return finalResponse;
    } catch (error) {
      const finalResponse = await response(error);
      return finalResponse;
    }
  };
};

export const serviceRequestByNo = (
  serviceNo: string
): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const endpoint = `${SERVER_URL.SERVICE_REQUEST_BYID_ENDPOINT}no/${serviceNo}`
    const dataStore: any = localStorage.getItem("SESSION_CREDENTIALS");
    const { accessToken }: any = JSON.parse(dataStore);
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
      },
      method: "GET",
    };

    try {
      const req = new Request(endpoint.toString(), options);
      const res = await fetch(req);
      const finalResponse = await response(res);
      return finalResponse;
    } catch (error) {
      const finalResponse = await response(error);
      return finalResponse;
    }
  };
};

export const getServiceTypeService = (
  id: string
): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const endpoint = `${SERVER_URL.admin}/service-requests/request-fields?serviceTypeId=${id}`
    let token;
    token = JSON.parse(localStorage.getItem('SESSION_CREDENTIALS') || '{}');
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token.accessToken,
      },
      method: "GET",
    };

    try {
      const req = new Request(endpoint.toString(), options);
      const res = await fetch(req);
      console.log(res)
      const finalResponse = await response(res);
      return finalResponse;
    } catch (error) {
      const finalResponse = await response(error);
      return finalResponse;
    }
  };
};

export const cancelServiceRequestById = (
  serviceId: string,
): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {

    const endpoint = SERVER_URL.SERVICE_REQUEST_BYID_ENDPOINT + serviceId;
    const dataStore: any = localStorage.getItem("SESSION_CREDENTIALS");
    const { accessToken }: any = JSON.parse(dataStore);

    const options = {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
      method: 'DELETE'
    };

    try {
      const req = new Request(endpoint.toString(), options);
      const res = await fetch(req);
      const finalResponse = await response(res);
      return finalResponse;
    } catch (error) {
      const finalResponse = await response(error);
      return finalResponse;
    }
  };
};

export const cancelServiceRequestWithRelated = (
  serviceRequestNo: string,
): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {

    const endpoint = SERVER_URL.SERVICE_REQUEST_BYID_ENDPOINT + 'cancel/' + serviceRequestNo;
    const dataStore: any = localStorage.getItem("SESSION_CREDENTIALS");
    const { accessToken }: any = JSON.parse(dataStore);

    const options = {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
      method: 'DELETE'
    };

    try {
      const req = new Request(endpoint.toString(), options);
      const res = await fetch(req);
      const finalResponse = await response(res);
      return finalResponse;
    } catch (error) {
      const finalResponse = await response(error);
      return finalResponse;
    }
  };
};

export const assignServiceRequest = (assignSR: AssignServiceRequest): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const assignServiceRequestEndpoint = new URL(SERVER_URL.ASSIGN_SERVICE_REQUEST);
    let serviceRequest: any = null;
    await postRequest(assignServiceRequestEndpoint, { body: JSON.stringify(assignSR) })
      .then((response: any) => {
        serviceRequest = response;
      })
      .catch((error: any) => {
        console.log("ERROR", error);
      });

    return serviceRequest;
  };
};

export const unassignServiceRequest = (id: number): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const unassignServiceRequestEndpoint = new URL(SERVER_URL.UNASSIGN_SERVICE_REQUEST + id);
    let serviceRequest: any = null;
    await postRequest(unassignServiceRequestEndpoint)
      .then((response: any) => {
        serviceRequest = response;
      })
      .catch((error: any) => {
        console.log("ERROR", error);
      });

    return serviceRequest;
  };
};

export const fetchAssignedServiceRequest = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {

    const clientId: any = dispatch(getClientId());

    const toolbar: Toolbar = dispatch(getSchedulerToolbar()).payload;
    const fetchAssignnedServiceRequestEndpoint = new URL(SERVER_URL.ASSIGNEDSR);
    const schedulerType: SchedulerType = dispatch(getSchedulerType()).payload;

    const q: string = dispatch(getSearchValue()).payload;
    const searchType: SearchType  = dispatch(getSearchType()).payload;

    if (_isEqual(toolbar.D_W, DailyWeekly.DAILY)) {
      const currentDate = moment(toolbar.dateFilter).format("YYYYMMDDZ");
      fetchAssignnedServiceRequestEndpoint.searchParams.append("clientId", clientId);
      fetchAssignnedServiceRequestEndpoint.searchParams.append("startDate", currentDate.toString());
      fetchAssignnedServiceRequestEndpoint.searchParams.append("endDate", currentDate.toString());
    }
    if (_isEqual(toolbar.D_W, DailyWeekly.WEEKLY)) {
      const date = toolbar.dateFilter.clone().startOf("week");
      const startDate = moment(date).format("YYYYMMDDZ");
      const endDate = moment(date).add(6, 'days').format("YYYYMMDDZ");
      fetchAssignnedServiceRequestEndpoint.searchParams.append("clientId", clientId);
      fetchAssignnedServiceRequestEndpoint.searchParams.append("startDate", startDate.toString());
      fetchAssignnedServiceRequestEndpoint.searchParams.append("endDate", endDate.toString());
    }

    if (!_isNull(toolbar.typeFilter) && _isEqual(schedulerType, SchedulerType.GRID)) {
      fetchAssignnedServiceRequestEndpoint.searchParams.append("filterId", toolbar.typeFilter.toString());
    }

    if (!_isEmpty(q) && _isEqual(schedulerType, SchedulerType.GRID)) {
      fetchAssignnedServiceRequestEndpoint.searchParams.append("q", q);
      fetchAssignnedServiceRequestEndpoint.searchParams.append("searchType", searchType);
    }

    await getRequest(fetchAssignnedServiceRequestEndpoint)
      .then((response: any) => {
        dispatch(setAssignedServiceRequests(response))
      })
      .catch((error) => console.log("ERROR", error));
  };
};

export const fetchCurrentServiceRequest = (technicianId: number): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    let technicians: Array<Technician> = dispatch(getTechniciansGrid()).payload;
    const toolbar: Toolbar = dispatch(getSchedulerToolbar()).payload;
    const currentDate: any = moment(toolbar.dateFilter, "MMMM Do YYYY, h:mm:ss a");
    let time = currentDate.minute();
    let subtractedDate = null;
    if (currentDate.minute() > 29) {
      subtractedDate = moment(currentDate).subtract(time, "minutes").add(30, "minutes");
    } else {
      subtractedDate = moment(currentDate).subtract(time, "minutes");
    }
    const currentDateTimeStamp = moment(subtractedDate).format("YYYYMMDDhhmmaZ")
    const fetchCurrentServiceRequestEndpoint = new URL(SERVER_URL.TECHNICIAN_CURRENT_SR);
    fetchCurrentServiceRequestEndpoint.searchParams.append("technicianId", technicianId.toString());
    fetchCurrentServiceRequestEndpoint.searchParams.append("dateTimestamp", currentDateTimeStamp);
    const serviceRequest = await getRequest(fetchCurrentServiceRequestEndpoint);
    if (serviceRequest) {
      const scheduleDateTimeStamp: any = moment(moment(serviceRequest.scheduleDateTimeStamp), "MMMM Do YYYY, h:mm:ss a");
      const startTime: any = moment(scheduleDateTimeStamp).format("h:mm A");
      const endTime: any = moment(scheduleDateTimeStamp).add(serviceRequest.durationInMinutes, "minutes").format("h:mm A");
      serviceRequest.timeStamp = { startTime, endTime };
      let member = {
        name: "",
        propertyAddress: ""
      }
      serviceRequest.requestFormValues.forEach((formValues: RequestFormValue) => {
        if (_isEqual(formValues.field.name, "Member Name")) {
          member.name = formValues.value;
        }
        if (_isEqual(formValues.field.name, "Property Name")) {
          member.propertyAddress = formValues.value;
        }
        if (_isEqual(formValues.field.name, "Service Type")) {
          serviceRequest.title = formValues.value.replaceAll('_', ' ');
        }
        if (_isEqual(formValues.field.name, "Item")) {
          serviceRequest.title = formValues.value.toUpperCase();
        }
      });
      serviceRequest.members = member;

      return serviceRequest;
    } else {
      return null;
    }
  };
};

export const getIndexTimePeriod = (startTime: string, endTime: string): ThunkAction<Promise<Array<number>>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const clock: Array<Time> = dispatch(getClock()).payload;
    const startTimeIndex = clock.findIndex((time) => _isEqual(time.time, startTime));
    const endTimeIndex = clock.findIndex((time, index) => _isEqual(time.time, endTime) && index > startTimeIndex);
    let timeIndexes: Array<number> = [];
    if (_isEqual(startTimeIndex, -1) || _isEqual(endTimeIndex, -1)) { return timeIndexes; }
    const timeRange = clock.slice(startTimeIndex, endTimeIndex);
    timeRange.forEach((time2) => {
      const ndx: number = clock.findIndex((time1) => _isEqual(time1.time, time2.time))
      timeIndexes.push(ndx);
    });
    return timeIndexes;
  };
};

export const getSRCloseForm = (serviceRequestId: string): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const getCloseFormEndpoint = `${SERVER_URL.CLOSE_FORM}?serviceRequestId=${serviceRequestId}`;
    const dataStore: any = localStorage.getItem("SESSION_CREDENTIALS");
    const { accessToken }: any = JSON.parse(dataStore);

    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
      },
      method: "GET",
    };

    try {
      const req = new Request(getCloseFormEndpoint.toString(), options);
      const res = await fetch(req);
      const finalResponse = await response(res);
      return finalResponse;
    } catch (error) {
      const finalResponse = await response(error);
      return finalResponse;
    }
  };
};

export const getRandomBGColor = (): any => {
  const color: string = '#' + (Math.random().toString(16) + "000000").substring(2, 8)
  return color;
};

export const updateServiceRequestStatus = (
  updatedServiceRequestStatus: any
): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const endpoint = `${SERVER_URL.ADMIN_SERVICE_REQUEST}/status`;
    const dataStore: any = localStorage.getItem("SESSION_CREDENTIALS");
    const { accessToken } = JSON.parse(dataStore);
    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      method: "POST",
      body: JSON.stringify(updatedServiceRequestStatus),
    };

    try {
      const req = new Request(endpoint.toString(), options);
      const res = await fetch(req);
      const finalResponse = await response(res);
      return finalResponse;
    } catch (error) {
      const finalResponse = await response(error);
      return finalResponse;
    }
  };
};

export const acceptServiceRequest = (
  serviceId: any, technicianId: any
): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const endpoint = `${SERVER_URL.ACCEPT_SERVICE_REQUEST}`;
    const dataStore: any = localStorage.getItem("SESSION_CREDENTIALS");
    const { accessToken } = JSON.parse(dataStore);
    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      method: "POST",
      body: JSON.stringify({
        technicianId: technicianId,
        serviceRequestId: serviceId,
      }),
    };

    try {
      const req = new Request(endpoint.toString(), options);
      const res = await fetch(req);
      const finalResponse = await response(res);
      return finalResponse;
    } catch (error) {
      const finalResponse = await response(error);
      return finalResponse;
    }
  };
};

export const submitCloseForm = (
  updatedCloseForm: any,
): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const endpoint = `${SERVER_URL.CLOSE_FORM}`;
    const dataStore: any = localStorage.getItem("SESSION_CREDENTIALS");
    const { accessToken } = JSON.parse(dataStore);
    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      method: "POST",
      body: JSON.stringify(updatedCloseForm),
    };

    try {
      const req = new Request(endpoint.toString(), options);
      const res = await fetch(req);
      const finalResponse = await response(res);
      return finalResponse;
    } catch (error) {
      const finalResponse = await response(error);
      return finalResponse;
    }
  };
};

export const addNotes = (
  notesData: any
): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const endpoint = `${SERVER_URL.NOTES}`;
    const dataStore: any = localStorage.getItem("SESSION_CREDENTIALS");
    const { accessToken } = JSON.parse(dataStore);
    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      method: "POST",
      body: JSON.stringify(notesData),
    };

    try {
      const req = new Request(endpoint.toString(), options);
      const res = await fetch(req);
      const finalResponse = await response(res);
      return finalResponse;
    } catch (error) {
      const finalResponse = await response(error);
      return finalResponse;
    }
  };
};


export const flagException = (
  exception: any
): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const endpoint = `${SERVER_URL.SERVICE_REQUEST_BYID_ENDPOINT}` +'exception';
    const dataStore: any = localStorage.getItem("SESSION_CREDENTIALS");
    const { accessToken } = JSON.parse(dataStore);
    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      method: "POST",
      body: JSON.stringify(exception),
    };

    try {
      const req = new Request(endpoint.toString(), options);
      const res = await fetch(req);
      const finalResponse = await response(res);
      return finalResponse;
    } catch (error) {
      const finalResponse = await response(error);
      return finalResponse;
    }
  };
};