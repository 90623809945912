import { ThunkDispatch, ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";

// OTHERS
import { SERVER_URL } from "../../configs/service-config";
import { getRequest } from '../interceptor';

// MODEL
import { ClaimHistory } from "./model";

//  STATE
import { setClaimHistory } from "./action";

import { Toolbar } from "../scheduler/model";
import { getSchedulerToolbar } from "../scheduler/action";
import { getClientId } from "../auth/actions";

export const fetchUnassignedSR = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {

    const clientId: any = dispatch(getClientId());

    const toolbar: Toolbar = dispatch(getSchedulerToolbar()).payload;
    const geData = new URL(SERVER_URL.unAssignedSR);
    geData.searchParams.append("clientId", clientId);
    geData.searchParams.append("filterId", toolbar.typeFilter.toString());

    await getRequest(geData)
      .then((response: any) => {
        dispatch(setClaimHistory(response));
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };
};

export const getClaimsHistory = async (serviceRequestId:number):Promise<Array<ClaimHistory>> => {
  const claimsURI = new URL(`${SERVER_URL.CLAIMS_HISTORY}?serviceRequestId=${serviceRequestId}`);
  const response = await getRequest(claimsURI);
  return <Array<ClaimHistory>>response;
};

