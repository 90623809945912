import { Modal } from "antd";
import { BASIC_AUTH, ROOT_DOMAIN } from "../configs/service-config";
import _isNull from "lodash/isNull";
import _isEqual from "lodash/isEqual";

const getFinalOptions = async (method: string, options?: any) => {
  const defaultOptions = {
    headers: {
      'Host': ROOT_DOMAIN,
      credentials: 'include',
    }
  }
  const newOptions = { ...options, ...defaultOptions };
  const dataStore: any = localStorage.getItem("SESSION_CREDENTIALS");

  if (!_isNull(dataStore)) {
    const { accessToken }: any = JSON.parse(dataStore);
    newOptions.headers = {
      Authorization: 'Bearer ' + accessToken,
      ...newOptions.headers
    }
  }

  if (_isNull(dataStore)) {
    newOptions.headers = {
      Authorization: BASIC_AUTH,
      ...newOptions.headers
    }
  }

  switch (method) {
    case "POST":
      newOptions.method = "POST";
      if (!(newOptions.body instanceof FormData)) {
        newOptions.headers = {
          'Content-Type': 'application/json; charset=utf-8',
          ...newOptions.headers
        }
      } else {
        newOptions.headers = {
          'Content-Type': 'multipart/form-data',
          ...newOptions.headers
        }
      }
      break;
    case "PUT":
      newOptions.method = "PUT";
      if (!(newOptions.body instanceof FormData)) {
        newOptions.headers = {
          'Content-Type': 'application/json; charset=utf-8',
          ...newOptions.headers
        }
      } else {
        newOptions.headers = {
          'Content-Type': 'multipart/form-data',
          ...newOptions.headers
        }
      }
      break;
    default:
      break;
  }

  return newOptions;
};
export const putRequest = (URL: any, OPT?: any): Promise<any> => {
  return new Promise(async (res, rej) => {
    const options = await getFinalOptions("PUT", OPT);
    const request = new Request(URL, options);
    const response = await fetch(request);
    if (_isEqual(response.status, 200)) {
      try {
        res(response.json())
      } catch (e) {
        res(null);
      }
    } else {
      serverErrorMessages(response);
      rej(response)
    }
  });
};


export const postRequest = (URL: any, OPT?: any): Promise<any> => {
  return new Promise(async (res, rej) => {
    const options = await getFinalOptions("POST", OPT);
    const request = new Request(URL, options);
    const response = await fetch(request);
    if (_isEqual(response.status, 200)) {
      try {
        res(response.json())
      } catch (e) {
        res(null);
      }
    } else {
      serverErrorMessages(response);
      rej(response)
    }
  });
};

export const getRequest = (URL: any, OPT?: any): Promise<any> => {
  return new Promise(async (res, rej) => {
    const options = await getFinalOptions("GET", OPT);
    const request = new Request(URL, options);
    const response = await fetch(request);

    if (_isEqual(response.status, 200)) {
      try {
        const data = await response.json();
        res(data);
      } catch (e) {
        res(null);
      }
    } else {
      serverErrorMessages(response);
      rej(response)
    }
  });
};

export const deleteRequest = (URL: any, OPT?: any) => {
  return new Promise(async (res, rej) => {
    const options = await getFinalOptions("DELETE", { method: "DELETE" });
    const request = new Request(URL, options);
    const response = await fetch(request);
    if (response) {
      res(response.json())
    } else {
      serverErrorMessages(response);
      rej(response)
    }
  });
};

export const serverErrorMessages = (response: any) => {

  switch (response.status) {
    // case 500:
    //   Modal.error({
    //     title: "Internal Server Error",
    //     content: "We're sorry, but something went wrong. Please try again",
    //     onOk() {
    //       localStorage.removeItem("SESSION_CREDENTIALS");
    //       window.location.href = "/";
    //     },
    //   });

    //   break;

    default:
      break;
  }
}



