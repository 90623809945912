import { SERVER_URL } from "../configs/service-config";
import { notification } from 'antd';

interface tokenProps {
    accessToken: string,
    refreshToken: string
}
interface itemProps {
    "id": number,
    "seqNo": number,
    "name": string,
    "shortName": string,
    "type": string,
    "page": number,
    "showField": boolean,
    "displayWidth": number,
    "clientId": number,
    "values": [],
    "version": number
}
interface dataProps {
    id: number;
    clientId: number;
    currentStatus: string;
    nextStatus: string;
    lat: number;
    lng: number;
    requestFormValues: {
        seqNo: number;
        field: itemProps;
        value: any;
    }[];
}
export default class HttpServiceRequest {

    getMembers(q:string) {

        return new Promise((resolve, reject) => {
            let token: tokenProps;

            token = JSON.parse(localStorage.getItem('SESSION_CREDENTIALS') || '{}');
            const options = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token.accessToken}`
                },
            };
            fetch(`${SERVER_URL.admin}/members?q=${q}`, options)
                .then(res => res.json())
                .then(result => {
                    resolve(result);
                }).catch(err => reject(err))
        })
    }

    getSubscribers(q:string) {

        return new Promise((resolve, reject) => {
            let token: tokenProps;

            token = JSON.parse(localStorage.getItem('SESSION_CREDENTIALS') || '{}');
            const options = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token.accessToken}`
                },
            };
            fetch(`${SERVER_URL.admin}/subscribers?q=${q}`, options)
                .then(res => res.json())
                .then(result => {
                    resolve(result);
                }).catch(err => reject(err))
        })
    }

    getProperties(memberId: number) {

        return new Promise((resolve, reject) => {
            let token: tokenProps;

            token = JSON.parse(localStorage.getItem('SESSION_CREDENTIALS') || '{}');
            const options = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token.accessToken}`
                },
            };
            fetch(`${SERVER_URL.admin}/properties?memberId=${memberId}`, options)
                .then(res => res.json())
                .then(result => {
                    resolve(result);
                }).catch(err => reject(err))
        })
    }

    getAvailableTechnicians(propertyId: number, serviceTypeId: number, serviceType: string, date: string) {

        return new Promise((resolve, reject) => {
            let token: tokenProps;

            const clientId = localStorage.getItem("client");

            token = JSON.parse(localStorage.getItem('SESSION_CREDENTIALS') || '{}');
            const options = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token.accessToken}`
                },
            };
            const url = `${SERVER_URL.AVAILABLE_TECHNICIANS}?clientId=${clientId}&propertyId=${propertyId}&serviceType=${serviceType}&date=${date}`;
            fetch(url, options)
                .then(res => res.json())
                .then(result => {
                    resolve(result);
                }).catch(err => reject(err))
        })
    }

    getServiceType(id: string) {
        return new Promise((resolve, reject) => {
            let token: tokenProps;

            token = JSON.parse(localStorage.getItem('SESSION_CREDENTIALS') || '{}');
            const options = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token.accessToken}`
                },
            };
            fetch(`${SERVER_URL.admin}/service-requests/request-fields?serviceTypeId=${id}`, options)
                .then(res => res.json())
                .then(result => {
                    resolve(result);
                }).catch(err => reject(err))
        })

    }

    postImportSr(clientId: number, data: string){
    
        let token: tokenProps;

        return new Promise((resolve, reject) => {
            openNotification('Please wait!', 'Importing Service Requests...', 'waiting');
            token = JSON.parse(localStorage.getItem('SESSION_CREDENTIALS') || '{}');
            let rawData = {
                data: data.split(',')[1],
                clientId: clientId
            }
            
            const options = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token.accessToken}`
                },
                method: "POST",
                body: JSON.stringify(rawData)
            };
            fetch(`${SERVER_URL.admin}/admin/service-requests/upload`, options)
                .then(res => res.json())
                .then(result => {
                    if (result['status'] == 500) {
                        openNotification('Failed!', 'Something went wrong!', 'error');
                        resolve(false)

                    } else if (result['status'] == 401 || result['status'] == 404) {
                        openNotification('Failed!', 'Authorization went failed', 'error');
                        resolve(false)

                    }
                    else {
                        setTimeout(() => {
                            openNotification('Success!', 'The Service Requests have been imported', 'success');
                            resolve(true)
                        }, 1000)
                    }
                }).catch(err => {
                    console.log(err);
                    openNotification('Failed!', 'Something went wrong!', 'error')
                });
        })
    }

    postSR(data: any) {

        

        let token: tokenProps;

        return new Promise((resolve, reject) => {

            openNotification('Please wait!', 'Creating SR', 'waiting');

            token = JSON.parse(localStorage.getItem('SESSION_CREDENTIALS') || '{}');

            const options = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token.accessToken}`
                },
                method: "POST",
                body: JSON.stringify(data)
            };

            fetch(`${SERVER_URL.admin}/admin/service-requests`, options)
                .then(res => res.json())
                .then(result => {
                    if (result['status'] == 500) {
                        openNotification('Failed!', 'Something went wrong!', 'error');
                        resolve(null)

                    } else if (result['status'] == 401 || result['status'] == 404) {
                        openNotification('Failed!', 'Authorization went failed', 'error');
                        resolve(null)

                    }
                    else {
                        setTimeout(() => {
                            openNotification('Success!', 'Service request created', 'success');
                            resolve(result)
                        }, 1000)
                    }
                }).catch(err => openNotification('Failed!', 'Something went wrong!', 'error'));
        })
    }

    bidOut(data: any) {
        
        let token: tokenProps;
        return new Promise((resolve, reject) => {
            openNotification('Please wait!', 'Bidding Out', 'waiting');

            token = JSON.parse(localStorage.getItem('SESSION_CREDENTIALS') || '{}');
            const options = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token.accessToken}`
                },
                method: "POST",
                body: JSON.stringify(data)
            };
            fetch(`${SERVER_URL.admin}/service-requests/bid-out`, options)
                .then(res => res.json())
                .then(result => {
                    if (result['status'] == 500) {
                        openNotification('Failed!', 'Something went wrong!', 'error');
                        resolve(null)

                    } else if (result['status'] == 401 || result['status'] == 404) {
                        openNotification('Failed!', 'Authorization went failed', 'error');
                        resolve(null)

                    }
                    else {
                        setTimeout(() => {
                            openNotification('Success!', 'Service request created', 'success');
                            resolve(result)
                        }, 1000)
                    }
                    
                }).catch(err => openNotification('Bidding Failed!', 'No Technicians Found!', 'error'));
        })
    }
}

const openNotification = (title: string, description: string, type: string) => {
    const args = {
        message: title,
        description:
            description,
        duration: 2,
    };
    switch (type) {
        case 'success':
            notification.success(args);
            break;
        case 'error':
            notification.error(args);
            break;
        case 'waiting':
            notification.info(args);
            break;
        default:
            notification.success(args);
            break;
    }
};
