import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../redux";
import { ThunkDispatch } from "redux-thunk";
import _isEqual from "lodash/isEqual";
import _isUndefined from "lodash/isUndefined";
import moment from "moment";



// STATE
import { ClaimHistory } from "../../redux/claim-history/model";

import { getClaimsHistory } from "../../redux/claim-history/service";

// ANTD
import { Avatar } from "antd";
import { SearchOutlined, UserOutlined } from "@ant-design/icons";

interface OwnProps {
  serviceRequestId: number,
  setClaimHistoryShow: (isClaimHistoryComponent: boolean) => void
}

interface StateProps {
  claimHistory: Array<ClaimHistory>;
}
interface DispatchProps {}

type Props = OwnProps & StateProps & DispatchProps;

const ClaimHistoryComponent: React.FC<Props> = ({
  serviceRequestId,
  setClaimHistoryShow
}): JSX.Element => {

  const [claimsHistory, setClaimsHistory] = React.useState<Array<ClaimHistory>>();

  React.useEffect(() => {
    
    getClaimsHistory(serviceRequestId).then((claimsHistory) => {
      setClaimsHistory(claimsHistory);
    });

  }, [serviceRequestId]);

  return (

    <div className="claim-history p-4">
      <a href="#" className="ch-close-btn pointer" onClick={ () => setClaimHistoryShow(true)}></a>
      <div className="ch-title text-center text-bold3">CLAIM HISTORY</div>

      {/*
      <div className="ch-search text-center my-3">
        <SearchOutlined />
        <input placeholder="Search" className="br-6" type="text" />
      </div>
      */}

      {(!_isUndefined(claimsHistory) && !_isEqual(claimsHistory.length, 0)) &&
      
        claimsHistory.map((data: ClaimHistory, index: number) => (
          <div className="ch-list p-2 my-2">
            <div className="ch-avatar">
              <Avatar size={32} icon={<UserOutlined />} className="mt-1" />
            </div>

            <div className="ch-list-details px-2">
              <span className="fs-small">
                <strong>{data.technicianName}</strong> has{" "}
                <strong>{data.claimAction}</strong> this SR
              </span>
              <br />
              <span className="fs-small">{moment(data.dateTimestamp).format('DD MMMM, YYYY hh:mm a')}</span>
            </div>
          </div>
        ))}

      {(!_isUndefined(claimsHistory) && _isEqual(claimsHistory.length, 0)) &&
          <>
          <br/>
          <div className="ch-list ch-list-details p-2 my-2" style={{fontWeight: 'bold', textAlign: 'center'}}>
            <br/>
            There are no claims on this SR yet.
            <br/>
            <br/>
          </div>
          <br/>
          </>
        }
    </div>
  );
};

const mapStateToProps = (states: RootState): StateProps => {
  return {
    claimHistory: states.claimHistoryReducer.state.claimHistory,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>
): DispatchProps => {
  return {
  };
};

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(ClaimHistoryComponent)
);
