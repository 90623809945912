import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { RootState } from "../../redux";
import { PlusOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import { ThunkDispatch } from "redux-thunk";
import {
  message,
  Collapse,
  Empty,
  Modal,
  Input,
  DatePicker,
  Popconfirm,
  Spin,
} from "antd";
import {
  fetchFilters,
  fetchUserFilters,
  saveFilter,
} from "../../redux/filter/service";
import { ClockType, Toolbar } from "../../redux/scheduler/model";
import moment from "moment";
import { setSchedulerToolbar } from "../../redux/scheduler/action";
import { getClientId } from "../../redux/auth/actions";
import { setSearchType, setSearchValue } from "../../redux/filter/action";
import { serviceRequestByNo } from "../../redux/service-request/service";

import _isNull from "lodash/isNull";
import _isEqual from "lodash/isEqual";
import { SearchType } from "../../redux/filter/model";

interface OwnProps {
  isFilterShelvesShow: any;
  setFilterShelves: () => void;
  changeFilterName: (name: any) => void,
  type: string
}

type StateProps = {
  isLoadingUserFilters: any,
  isLoadingFilterFields: any,
  filterFields: any;
  toolbar: any;
  filters: any;
  userFilters: any;
  searchValue: string;
};

interface DispatchProps {
  saveFilter: (filter: any) => Promise<any>
  fetchFilters: () => void;
  fetchUserFilters: () => void;
  setToolbar: (params: Toolbar) => void;
  getClientId: () => any;
  setSearchValue: (q: string) => any;
  setSearchType: (type: SearchType) => any;
  serviceRequestByNo: (serviceNo: string) => any;
}

type Props = OwnProps & StateProps & DispatchProps;

const FilterShelves: React.FC<Props> = ({
  isFilterShelvesShow,
  isLoadingUserFilters,
  isLoadingFilterFields,
  setFilterShelves,
  filterFields,
  saveFilter,
  userFilters,
  fetchUserFilters,
  toolbar,
  setToolbar,
  changeFilterName,
  type,
  getClientId,
  serviceRequestByNo,
  setSearchType,
  setSearchValue,
  searchValue
}): JSX.Element => {

  useEffect(() => {
    getFilterTypes()
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [userFilters]);

  const wrapperRef = useRef<any>(null);
  const searchRef = useRef<any>(null);

  const [filter, setFilter] = useState<any>({});
  const [filters, setFilters] = useState<any>([])
  const [collapseKey, setCollapseKey] = useState<any>(undefined);
  const [adminFilter, setAdminFilter] = useState(false);
  const [optionalValue, setOptionalValue] = useState("");
  const [popConfirm, setPopConfirm] = useState(false)
  const [modal, setModal] = useState(false);

  const [searchModal, setSearchModal] = useState(false);
  const [searchModalTitle, setSearchModalTitle] = useState("");

  const [selectedOption, setSelectedOption] = useState<any>({});
  const [disableCollapse, setDisableCollapse] = useState(false);
  const [filterName, setFilterName] = useState<string>("");
  const [selectedFilters, setSelectedFilters] = useState<any[]>([]);
  const [filterItems, setFilterItems] = useState<any[]>([]);
  const [customFilter, setCustomFilter] = useState<any>({});

  const getFilterTypes = async () => {
    const filterGroupTypes = await userFilters.filter((f: any) => f.filterClass === type || f.filterClass === "BOTH")
    setFilters(filterGroupTypes)
  }

  useEffect(() => {
    if (searchRef && searchRef.current) {
      setTimeout(() => {
        const { input } = searchRef.current
        if (searchModal) {
          input.focus()
        }

        if (!searchModal) {
          input.blur()
        }
      }, 100)

    }
  }, [searchModal]);

  const getFilterOptions = (f: any) => {
    console.log("**DEBUGGER fieldName**", f.fieldName)
    setFilter(f);
    switch (f.fieldName) {
      case "serviceRequestNo":
        setSearchType(SearchType.SERVICE_REQUEST_NO);
        setSearchValue("");
        setSearchModal(true);
        break;
      case "technicianName":
        setSearchType(SearchType.TECHNICIAN_NAME);
        setSearchValue("");
        setSearchModal(true);
        break;
      case "ITM":
        setSearchType(SearchType.ITEM_NAME);
        setSearchValue("");
        setSearchModal(true);
        break;
      case "MEM":
        setSearchType(SearchType.MEMBER_NAME);
        setSearchValue("");
        setSearchModal(true);
        break;
      default:
        if (!customFilter.id || customFilter.adminFilter) {
          setCollapseKey(undefined);
          setDisableCollapse(true);
        } else {
          setDisableCollapse(false);
        }
        break;
    }
  };

  const clearAllFilter = () => {
    setFilter({});
    setFilterName("");
    setDisableCollapse(false);
    setSelectedFilters([]);
    setFilterItems([]);
    setCustomFilter({});
  };

  const clearFilterItems = () => {
    setFilterItems([]);
    setSelectedFilters([]);
  };

  const populateFilter = (f: any) => {
    if (f.adminFilter) {
      setPopConfirm(true)
      setAdminFilter(true);
      setSelectedFilters([]);
      setFilter({});
    } else {
      setAdminFilter(false);
      const newfilterItems = [];
      const existingValues = filterFields.find(
        (x: any) => f.filterRules[0].filterField.id === x.id
      );
      for (let x = 0; x < f.filterRules.length; x++) {
        newfilterItems.push({
          ...f.filterRules[x].filterValue,
          optionalValue: f.filterRules[x].optionalValue,
        });
      }
      setFilterName(f.name);
      setFilter(existingValues);
      setFilterItems(newfilterItems);
      setSelectedFilters(f.filterRules);
    }
    setCustomFilter(f);
  };

  const deleteFilterItem = (id: number) => {
    const newFilterItems = filterItems.filter((f) => f.id !== id);
    const newSelectedFilters = selectedFilters.filter(
      (f) => f.filterValue.id !== id
    );
    setSelectedFilters(newSelectedFilters);
    setFilterItems(newFilterItems);
  };

  const addFilterOptions = (o: any, action: number) => {
    const existingOption = selectedFilters.find(
      (f) => f.filterValue.id === o.id
    );

    const existingOptionalValue = selectedFilters.find((f) => {
      if (f.filterValue.id === o.id) {
        if (f.optionalValue) {
          return f.optionalValue;
        }
      }
    });

    if (!existingOption && action === 0) {
      if (o.hasOptionalValue) {
        setSelectedOption(o);
        setModal(true);
      } else {
        setSelectedOption(o);
        setSelectedFilters([
          ...selectedFilters,
          {
            filterField: {
              ...filter,
            },
            filterValue: {
              ...o,
            },
            optionalValue: "",
          },
        ]);
        setFilterItems([...filterItems, { ...o, optionalValue: "" }]);
      }
    } else if (action === 1) {
      if (o.hasOptionalValue) {
        setOptionalValue(existingOptionalValue.optionalValue);
        setSelectedOption(o);
        setModal(true);
      }
    }
  };

  const saveNewFilter = async () => {
    const filterRules = [];
    for (let x = 0; x < selectedFilters.length; x++) {
      if (selectedFilters[x].id) {
        filterRules.push({
          ...selectedFilters[x],
        });
      } else {
        filterRules.push({
          seqNo: selectedFilters[selectedFilters.length - 1].seqNo ? selectedFilters[selectedFilters.length - 1].seqNo + x : x,
          ruleClass: "RULE",
          operator: selectedFilters[0] === selectedFilters[x] ? null : "AND",
          filterRules: [],
          ...selectedFilters[x],
        });
      }
    }

    const clientId: any = getClientId();
    const newFilter = {
      clientId: clientId,
      userId: 2,
      id: customFilter.id ? customFilter.id : "",
      name: filterName,
      preset: false,
      shared: false,
      filterClass: type,
      filterRules,
      filterTags: [],
    };
    try {
      const response = await saveFilter(newFilter)
      if (response.id) {
        message.success("The filter was saved successfully.");
        setTimeout(() => {
          fetchUserFilters()
        }, 1000)
      }
    } catch (error) {
      message.error("An error occured");
    }
  };

  const applyFilter = async () => {
    if (customFilter.id) {
      try {
        const { dateFilter, clockType, D_W } = toolbar;
        setToolbar({ typeFilter: customFilter.id, dateFilter, clockType, D_W })
        changeFilterName(customFilter.name)
        message.success("The filter was applied successfully.")
        window.dispatchEvent(new Event("reloadScheduler"));
      }
      catch (error) {
        message.error("An error occured while applying the filter")
      }
    } else {
      const filterRules = [];
      for (let x = 0; x < selectedFilters.length; x++) {
        if (selectedFilters[x].id) {
          filterRules.push({
            ...selectedFilters[x],
          });
        } else {
          filterRules.push({
            seqNo: selectedFilters[selectedFilters.length - 1].seqNo ? selectedFilters[selectedFilters.length - 1].seqNo + x : x,
            ruleClass: "RULE",
            operator: selectedFilters[0] === selectedFilters[x] ? null : "AND",
            filterRules: [],
            ...selectedFilters[x],
          });
        }
      }

      const clientId: any = getClientId();

      const newFilter = {
        clientId: clientId,
        userId: 2,
        id: customFilter.id ? customFilter.id : "",
        name: filterName ? filterName : "No Name",
        preset: false,
        shared: false,
        filterClass: type,
        filterRules,
        filterTags: [],
      };
      try {
        const applyFilter = await saveFilter(newFilter)
        const { dateFilter, clockType, D_W } = toolbar;
        await setToolbar({ typeFilter: applyFilter.id, dateFilter, clockType, D_W });
        changeFilterName(applyFilter.name)
        message.success("The filter was applied successfully.")
        window.dispatchEvent(new Event("reloadScheduler"));
        setTimeout(() => {
          fetchUserFilters()
        }, 1000)
      } catch (error) {
        message.error("An error occured while applying the filter")
      }
    }
  };

  const getFilterValue = () => {
    if (selectedFilters.length !== 0) {
      return selectedFilters[0].filterValue.id !== 0;
    } else {
      return false;
    }
  };

  const getOptionalValue = (o: any) => {
    if (selectedFilters.length !== 0) {
      for (let x = 0; x < selectedFilters.length; x++) {
        if (selectedFilters[x].filterValue.id === o.id && o.hasOptionalValue) {
          return selectedFilters[x].optionalValue;
        }
      }
    }
  };

  const getAdminFiltersLength = () => {
    const adminFilter: any[] = []
    filters.map((sFilters: any) => {
      if (sFilters.adminFilter !== false) {
        adminFilter.push(sFilters)
      }
    })
    return adminFilter.length
  }
  const findOptionalValue = (o: any) => {
    if (selectedFilters.find((f) => f.filterValue.id === o.id)) return true;
  };

  const modalOk = () => {
    const existingOptionalValue = selectedFilters.find((f) => {
      if (f.filterValue.id === selectedOption.id) {
        if (f.optionalValue) {
          return true;
        }
      }
    });

    const newFieldValues = selectedFilters.map((f) => {
      if (f.filterValue.id === selectedOption.id && f.optionalValue) {
        return { ...f, optionalValue };
      }
      return f;
    });

    const newFilterItems = filterItems.map((f) => {
      if (f.id === selectedOption.id && f.optionalValue) {
        return { ...f, optionalValue };
      }
      return f;
    });

    if (!existingOptionalValue) {
      setSelectedFilters([
        ...selectedFilters,
        {
          optionalValue,
          filterField: {
            ...filter,
          },
          filterValue: {
            ...selectedOption,
          },
        },
      ]);
      setFilterItems([...filterItems, { ...selectedOption, optionalValue }]);
    } else {
      setSelectedFilters(newFieldValues);
      setFilterItems(newFilterItems);
    }
    setOptionalValue("");
    setModal(false);
  };

  const modalCancel = () => {
    setOptionalValue("");
    setModal(false);
  };

  const onConfirmPop = () => {
    const { dateFilter, clockType, D_W } = toolbar;
    setToolbar({ typeFilter: customFilter.id, dateFilter, clockType, D_W });
    changeFilterName(customFilter.name)
    message.success("Filter applied successfully")
    window.dispatchEvent(new Event("reloadScheduler"));
  };

  const onCancelPop = () => {
    console.log("cancel admin filter")
  };

  const handleClickOutside = (event: any) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target) && isFilterShelvesShow) {
      setFilterShelves()
    }
  };

  const searchNow = async () => {
    if (_isEqual(filter.fieldName, "serviceRequestNo")) {
      const { D_W } = toolbar;
      const serviceRequest = await serviceRequestByNo(searchValue);
      const sr = serviceRequest.body;
      if (!_isNull(sr.scheduleDateTimeStamp)) {
        localStorage.setItem("redirectSR", sr.serviceRequestNo);
        const dateFilter = moment(moment(sr.scheduleDateTimeStamp), "LL");
        setToolbar({ typeFilter: 0, dateFilter, clockType: ClockType.TWENTYFOUR_HOUR, D_W });
      }
    }

    setFilterShelves();
    setSearchModal(false);
    window.dispatchEvent(new Event("reloadScheduler"));
    window.dispatchEvent(new Event("reloadSchedulerSideBar"));
    window.dispatchEvent(new Event("redirectSR"));
  }

  return (
    <div className="filter-shelves br-3 p-2" ref={wrapperRef}>
      <Modal
        getContainer={() => document.getElementById('filterbox-modal-connector') as HTMLElement}
        title={`Search by ${filter.name}`}
        visible={searchModal}
        onOk={() => searchNow()}
        onCancel={() => {
          setSearchModal(false);
          setSearchValue("");
        }}
      >
        <Input
          ref={searchRef}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </Modal>

      <Modal
        getContainer={() => document.getElementById('filterbox-modal-connector') as HTMLElement}
        title={`Enter ${selectedOption.value}`}
        visible={modal}
        onOk={() => modalOk()}
        onCancel={() => modalCancel()}
      >
        {selectedOption.value === "Custom Date/time" ? (
          <DatePicker
            showTime
            defaultValue={optionalValue ? moment(optionalValue) : moment()}
            onChange={(date: any) => setOptionalValue(moment(date).format("LLL"))}
          />
        ) : (
          <Input
            value={optionalValue}
            onChange={(e) => setOptionalValue(e.target.value)}
          />
        )}
      </Modal>
      <div className="filter-shelves-box" id="filterbox-modal-connector">
        <div className="flex">
          <section className="text-bold1 fs-1 flex-grow1 ">Filters:</section>
          <section
            className="text-center pointer fs-1 flex-grow1 actions-shelf"
            onClick={(_) => setFilterShelves()}
          >
            Close
          </section>
          <section className="actions-shelf text-right pointer flex-grow1 mr-2">
            <PlusOutlined className="fs-small" />
            <span className="fs-1">Add Shelf</span>
          </section>
        </div>
        <div className="f-shelves-list-container pt-2 px-2 mt-2">
          <h3 className="p-0 m-0">Filter Shelves:</h3>
          <ul className="f-saved-shelves-list m-0 p-0 pb-1">
            <li className="text-bold3">
              <Collapse
                activeKey={collapseKey}
                accordion
                onChange={(key) => setCollapseKey(key)}
                destroyInactivePanel={true}
              >
                <Collapse.Panel
                  header="My Saved Filters"
                  key="1"
                  showArrow={false}
                  disabled={disableCollapse}
                >
                  {isLoadingUserFilters ? <div style={{ textAlign: 'center' }}><Spin /> </div>
                    : filters.length !== 0 ? (
                      <div
                        className={
                          filters.length < 4 ? "" : "f-shelves-scrollbar"
                        }
                      >
                        <ul className="f-shelves-list m-0 p-0 pb-1">
                          {filters.map((sFilters: any) => {
                            if (sFilters.adminFilter === false && sFilters.filterClass === type)
                              return (
                                <li
                                  key={sFilters.id}
                                  style={{ width: "220px" }}
                                  className={
                                    customFilter.id === sFilters.id
                                      ? "f-custom-active"
                                      : ""
                                  }
                                  onClick={() => populateFilter(sFilters)}
                                >
                                  {sFilters.name}
                                </li>
                              );
                          })
                          }
                        </ul>
                      </div>
                    ) : (
                      <Empty
                        imageStyle={{
                          height: 40,
                        }}
                      />
                    )}
                </Collapse.Panel>
                <Collapse.Panel
                  header="Admin Filters"
                  key="2"
                  showArrow={false}
                  disabled={disableCollapse}
                >
                  {isLoadingUserFilters ? <div style={{ textAlign: 'center' }}><Spin /> </div> :
                    filters.length !== 0 && getAdminFiltersLength() !== 0 ? (
                      <div
                        className={
                          getAdminFiltersLength() < 4 ? "" : "f-shelves-scrollbar"
                        }
                      >
                        <ul className="f-shelves-list m-0 p-0 pb-1">
                          {filters.map((sFilters: any) => {
                            if (sFilters.adminFilter === true && sFilters.filterClass === type || sFilters.adminFilter === true && sFilters.filterClass === "BOTH")
                              return (
                                <Popconfirm
                                  overlayStyle={{ width: "270px" }}
                                  getPopupContainer={() => document.getElementById(`${sFilters.id}area`) as HTMLElement}
                                  placement="right"
                                  key={sFilters.id}
                                  title="Are you sure to apply this filter?"
                                  onConfirm={() => onConfirmPop()}
                                  onCancel={() => onCancelPop()}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <li
                                    id={`${sFilters.id}area`}
                                    style={{ width: "220px" }}
                                    className={
                                      customFilter.id === sFilters.id
                                        ? "f-custom-active"
                                        : ""
                                    }
                                    onClick={() => populateFilter(sFilters)}
                                  >
                                    {sFilters.name}
                                  </li>
                                </Popconfirm>
                              );
                          })
                          }
                        </ul>
                      </div>
                    ) : (
                      <Empty
                        imageStyle={{
                          height: 40,
                        }}
                      />
                    )}
                </Collapse.Panel>
              </Collapse>
            </li>
            <ul className="f-shelves-list m-0 p-0">
              <div
                className="f-shelves-scrollbar"
                style={{
                  height: collapseKey === undefined ? "250px" : "120px",
                }}
              >
                {
                  isLoadingFilterFields ? <div style={{ textAlign: 'center', marginTop: '50%' }}><Spin /> </div>
                    :
                    filterFields.map((f: any) => {
                      return (
                        <li
                          key={f.id}
                          onClick={() => getFilterOptions(f)}
                          className={filter.id === f.id ? "f-shelves-active" : ""}
                        >
                          {f.name}
                        </li>
                      );
                    })
                }
              </div>
            </ul>
          </ul>
        </div>
      </div>

      {filter.id ? (
        <div className="filter-shelves-box">
          <div className="flex">
            <section
              className="actions-shelf text-right pointer fs-1 flex-grow1 mr-4"
              onClick={() => clearAllFilter()}
            >
              Clear Filter
            </section>
          </div>
          <div className="f-shelves-list-container pt-2 px-2 mt-2">
            <div className="flex">
              <h3 className="p-0 m-0 flex-grow1 ">Filter Options:</h3>
              {/* <section className=" fs-1 pointer flex-grow1 text-right">
              <CloseOutlined />
            </section> */}
            </div>
            <ul className="f-shelves-list m-0 p-0 pb-1">
              {filter.filterValues.length !== 0 ? (
                filter.filterValues.map((o: any) => {
                  return (
                    <li
                      className={
                        filterItems.find((i) => i.id === o.id)
                          ? "f-options-active"
                          : ""
                      }
                      key={o.id}
                      onClick={() => addFilterOptions(o, 0)}
                    >
                      <div>
                        {o.value}{" "}
                        {o.hasOptionalValue && findOptionalValue(o)
                          ? `: ${getOptionalValue(o)}`
                          : null}
                      </div>
                      {/* <PlusOutlined style={{float:'right', marginTop:'5px'}} /> */}
                    </li>
                  );
                })
              ) : (
                <Empty
                  imageStyle={{
                    marginTop: "50%",
                  }}
                />
              )}
            </ul>
          </div>
        </div>
      ) : null}
      {getFilterValue() ? (
        <div className="filter-shelves-box">
          <div className="flex">
            <section
              className="actions-shelf fs-1 pointer flex-grow1 ml-2"
              onClick={() => saveNewFilter()}
            >
              Save As:
            </section>
            <section className="text-bold1 text-center pointer fs-1 flex-grow1 px-1">
              <input
                type="text"
                placeholder="My New Filter"
                value={filterName}
                className="f-shelves-save-filter br-1"
                onChange={(e) => setFilterName(e.target.value)}
              />
            </section>
            <section
              className="actions-shelf text-right pointer fs-1 flex-grow1 mr-2"
              onClick={() => applyFilter()}
            >
              Apply
            </section>
          </div>
          <div className="f-shelves-list-container pt-2 px-2 mt-2">
            <div className="flex">
              <h3 className="p-0 m-0 flex-grow1 ">Filter Items:</h3>
              <section
                className="actions-shelf fs-1 pointer flex-grow1 text-right"
                onClick={() => clearFilterItems()}
              >
                Clear
              </section>
            </div>
            <ul className="f-shelves-list m-0 p-0 pb-1">
              {filterItems.map((f) => {
                return (
                  <li key={f.id} className="f-items-active flex">
                    <div>
                      {f.value}: {f.optionalValue}
                    </div>
                    <div className="flex">
                      {getOptionalValue(f) ? (
                        <EditOutlined
                          style={{ float: "right" }}
                          onClick={() => addFilterOptions(f, 1)}
                        />
                      ) : null}
                      <CloseOutlined
                        className="f-items-close"
                        onClick={() => deleteFilterItem(f.id)}
                      />
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = (states: RootState): StateProps => {
  return {
    filterFields: states.filterReducer.state.filterFields,
    toolbar: states.schedulerReducer.state.toolbar,
    filters: states.filterReducer.state.filters,
    userFilters: states.filterReducer.state.userFilters,
    isLoadingUserFilters: states.filterReducer.state.isLoadingUserFilters,
    isLoadingFilterFields: states.filterReducer.state.isLoadingFilterFields,
    searchValue: states.filterReducer.state.searchValue
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>
): DispatchProps => {
  return {
    saveFilter: (filter) => saveFilter(filter),
    fetchFilters: () => dispatch(fetchFilters()),
    fetchUserFilters: () => dispatch(fetchUserFilters()),
    setToolbar: (params) => dispatch(setSchedulerToolbar(params)),
    getClientId: () => dispatch(getClientId()),
    setSearchValue: (q) => dispatch(setSearchValue(q)),
    setSearchType: (type) => dispatch(setSearchType(type)),
    serviceRequestByNo: (serviceNo) => dispatch(serviceRequestByNo(serviceNo))
  };
};

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(FilterShelves)
);
