import { ThunkDispatch, ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { SERVER_URL } from "../../configs/service-config";
import { deleteRequest, getRequest, postRequest, putRequest } from "../interceptor";
import { setCompletedTechnicianServiceRequest, setBankInformation, setServiceExperience, setAvailabilities, setSRCountTechnicians, setTechniciansBoard, setTechniciansGrid, setTechnicianSR, setTechnicianSrLoading, setTypeOfServices, setServiceLocations } from "./action";
import _isUndefined from "lodash/isUndefined";
import _isEqual from "lodash/isEqual";
import _isNull from "lodash/isNull";
import { DailyWeekly, SchedulerType, Toolbar } from "../scheduler/model";
import { getSchedulerToolbar, getSchedulerType } from "../scheduler/action";
import moment, { Moment } from "moment";
import { ServiceRequest } from "../service-request/model";
import { message } from "antd";
import { setVendorServiceRequestHistory, setVendorTechServiceRequestHistory } from "../vendors/action";
import { getClientId } from "../auth/actions";
import { ServiceLocation, TypeOfService } from "./model";

export const fetchTechniciansBOARD = (): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const clientId: any = dispatch(getClientId());
    const schedulerType: SchedulerType = dispatch(getSchedulerType()).payload;

    const toolbar: Toolbar = dispatch(getSchedulerToolbar()).payload;
    const fetchTechniciansEndpoint = new URL(SERVER_URL.technicians);
    fetchTechniciansEndpoint.searchParams.append("clientId", clientId);

    if (!_isNull(toolbar.typeFilter) && _isEqual(schedulerType, SchedulerType.BOARD)) {
      fetchTechniciansEndpoint.searchParams.append("filterId", toolbar.typeFilter.toString());
    }
    await getRequest(fetchTechniciansEndpoint).then(response => {
      dispatch(setTechniciansBoard(response));
    }).catch((error) => console.log("ERROR", error));
  };
};

export const fetchTechniciansGRID = (): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const clientId: any = dispatch(getClientId());
    const fetchTechniciansEndpoint = new URL(SERVER_URL.technicians);
    fetchTechniciansEndpoint.searchParams.append("clientId", clientId);
    await getRequest(fetchTechniciansEndpoint).then(response => {
      dispatch(setTechniciansGrid(response));
    }).catch((error) => console.log("ERROR", error));
  };
};

export const fetchTechniciansServiceRequestCount = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const clientId: any = dispatch(getClientId());
    const toolbar: Toolbar = dispatch(getSchedulerToolbar()).payload;
    const fetchTechniciansService = new URL(SERVER_URL.SR_COUNT_PER_TECHNICIAN_ALL);
    const currentDate = moment(toolbar.dateFilter).format("YYYYMMDD");
    fetchTechniciansService.searchParams.append("clientId", clientId);
    fetchTechniciansService.searchParams.append("startDate", currentDate.toString());
    fetchTechniciansService.searchParams.append("endDate", currentDate.toString());
    await getRequest(fetchTechniciansService).then((response: any) => {
      dispatch(setSRCountTechnicians(response));
    }).catch((error) => {
      console.log("ERROR", error);
    });
  };
};

export const fetchTechniciansServiceRequest = (ids: Array<number>): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  console.log(ids);
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(setTechnicianSrLoading(true))
    const toolbar: Toolbar = dispatch(getSchedulerToolbar()).payload;
    const schedulerType: SchedulerType = dispatch(getSchedulerType()).payload;
    const fetchTechnicianServiceRequest = new URL(SERVER_URL.TECHNICIAN_SERVICE_REQUEST);
    const currentDate = moment(toolbar.dateFilter).format("YYYYMMDDZ");

    if (_isEqual(ids.length, 0)) {
      dispatch(setTechnicianSR([]));
    } else {
      if (_isEqual(ids.length, 1)) {
        fetchTechnicianServiceRequest.searchParams.append("technicianId", ids[0].toString());
      }

      if (!_isEqual(ids.length, 1)) {
        console.log("RUNNING")
        ids.forEach((id) => {
          fetchTechnicianServiceRequest.searchParams.append("technicianIds", id.toString());
        })
      }

      console.log(fetchTechnicianServiceRequest)

      if (!_isNull(toolbar.typeFilter) && _isEqual(schedulerType, SchedulerType.BOARD)) {
        fetchTechnicianServiceRequest.searchParams.append("filterId", toolbar.typeFilter.toString());
      }

      fetchTechnicianServiceRequest.searchParams.append("startDate", currentDate.toString());
      fetchTechnicianServiceRequest.searchParams.append("endDate", currentDate.toString());
      await getRequest(fetchTechnicianServiceRequest).then((response: any) => {
        console.log("SERVICE REQUEST LIST", response);
        let serviceRequest: Array<ServiceRequest> = response;
        serviceRequest.forEach((serviceRequest: ServiceRequest) => {
          const dateTimeStamp: Moment = moment(serviceRequest.scheduleDateTimeStamp);
          const timeStamp = dateTimeStamp.minute() < 29
            ? dateTimeStamp.set('minutes', 0)
            : dateTimeStamp.clone().set('minutes', 0).add(30, "minutes");

          const startTime = timeStamp.format('h:mm A');
          const endTime = timeStamp.clone().add(serviceRequest.durationInMinutes, "minutes").format('h:mm A');

          serviceRequest.schedStartTime = startTime;
          serviceRequest.acceptedStartTime = startTime;
          serviceRequest.acceptedEndTime = endTime;
        });
        dispatch(setTechnicianSR(serviceRequest));
      }).catch((error) => {
        console.log("ERROR", error);
      });
    }
  };
};

export const fetchCompletedTechnicianServiceRequest = (
  id: number
): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const fetchTechniciansEndpoint = new URL(SERVER_URL.COMPLETED_TECHNICIAN_SERVICE_REQUEST);
    fetchTechniciansEndpoint.searchParams.append("technicianId", `${id}`);
    await getRequest(fetchTechniciansEndpoint).then(response => {
      dispatch(setCompletedTechnicianServiceRequest(response));
    }).catch((error) => console.log("ERROR", error));
  };
};

export const fetchBankInformation = (
  id: number
): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const fetchTechniciansEndpoint = new URL(SERVER_URL.BANK_INFORMATION);
    fetchTechniciansEndpoint.searchParams.append("technicianId", `${id}`);
    await getRequest(fetchTechniciansEndpoint).then(response => {
      dispatch(setBankInformation(response));
    }).catch((error) => console.log("ERROR", error));
  };
};

export const fetchServiceExperience = (
  id: number
): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const fetchTechniciansEndpoint = new URL(SERVER_URL.SERVICE_EXPERIENCE);
    fetchTechniciansEndpoint.searchParams.append("technicianId", `${id}`);
    await getRequest(fetchTechniciansEndpoint).then(response => {
      dispatch(setServiceExperience(response));
    }).catch((error) => console.log("ERROR", error));
  };
};

export const fetchAvailabilities = (
  id: number
): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const fetchTechniciansEndpoint = new URL(SERVER_URL.AVAILABILITIES);
    fetchTechniciansEndpoint.searchParams.append("technicianId", `${id}`);
    await getRequest(fetchTechniciansEndpoint).then(response => {
      dispatch(setAvailabilities(response));
    }).catch((error) => console.log("ERROR", error));
  };
};

export const resetPasswordViaEmail = (email: string): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const clientId: any = dispatch(getClientId());
    let body = {
      "clientId": clientId,
      "email": email,
      "resetMode": "RESET"
    };
    let strBody = JSON.stringify(body);
    const endpoint = SERVER_URL.RESET_PASSWORD_ENDPOINT;
    const options = {
      headers: {
        Accept: "application/json",
        'Content-Type': 'application/json',
      },
      method: "POST",
      body: strBody
    };

    try {
      const req = new Request(endpoint.toString(), options);
      const res = await fetch(req);
      if (res) {
        message.success("The password was reset successfully, please check your email.");
      }
    } catch (error) {
      return message.error("An error occured while resetting the password");
    };
  };
};

export const fetchServiceLocations = (): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const clientId: any = dispatch(getClientId());
    const endpoint = new URL(SERVER_URL.SERVICE_LOCATIONS);
    endpoint.searchParams.append("clientId", clientId);
    try {
      const response = await getRequest(endpoint);
      dispatch(setServiceLocations(response));
    } catch (error) {
      console.log("Error Occur when doing fetch service locations", error)
    }
  }
};

export const updateServiceLocationRadiusInMiles = (radiusInMiles: number): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const clientId: any = dispatch(getClientId());
    const endpoint = new URL(`${SERVER_URL.SERVICE_LOCATIONS}/radius`);
    endpoint.searchParams.append("clientId", clientId);
    endpoint.searchParams.append("radiusInMiles", radiusInMiles.toString());

    const dataStore: any = localStorage.getItem("SESSION_CREDENTIALS");
    const { accessToken }: any = JSON.parse(dataStore);

    const options = {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
      method: 'PUT'
    };

    try {
      const req = new Request(endpoint.toString(), options);
      const res = await fetch(req);

      return res;
    } catch (error) {
      return null;
    }
  }
};

export const saveServiceLocation = (raw: ServiceLocation): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async () => {
    const endpoint = new URL(SERVER_URL.SERVICE_LOCATIONS);

    let serviceLocation: any;
    await postRequest(endpoint, { body: JSON.stringify(raw) })
      .then((response: any) => {
        serviceLocation = response;
      })
      .catch((error: any) => {
        console.log("ERROR", error);
      });

    return serviceLocation;
  }
};

export const deleteServiceLocation = (serviceLocation: ServiceLocation): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const endpoint = new URL(`${SERVER_URL.SERVICE_LOCATIONS}/zipcode/${serviceLocation.id}`);
    await deleteRequest(endpoint)
      .then((response: any) => console.log(response))
      .catch((error: any) => {
        console.log("ERROR", error);
      });
  }
};

export const fetchTypeOfServices = (): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const clientId: any = dispatch(getClientId());
    const endpoint = new URL(SERVER_URL.TYPE_OF_SERVICES);
    endpoint.searchParams.append("clientId", clientId);
    try {
      const response = await getRequest(endpoint);
      dispatch(setTypeOfServices(response));
    } catch (error) {
      console.log("Error Occur when doing fetch type of services", error)
    }
  }
};

export const saveTypeOfService = (name: string): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const clientId: any = dispatch(getClientId());
    const raw = {
      id: 0,
      clientId,
      name,
      active: true,
      version: 0
    }
    const endpoint = new URL(SERVER_URL.TYPE_OF_SERVICES);
    let serviceType: any;
    await postRequest(endpoint, { body: JSON.stringify(raw) })
      .then((response: any) => {
        serviceType = response;
      })
      .catch((error: any) => {
        console.log("ERROR", error);
      });

    return serviceType;
  }
};

export const deleteTypeOfService = (typeOfService: TypeOfService): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const endpoint = new URL(`${SERVER_URL.TYPE_OF_SERVICES}/${typeOfService.id}`);
    await deleteRequest(endpoint)
      .then((response: any) => { })
      .catch((error: any) => {
        console.log("ERROR", error);
      });
  }
};

export const fetchVendorServiceRequestHistory = (
  id: number,
  a: string,
  i: number,
  v: number,
  t?: number
): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const endpoint = new URL(SERVER_URL.VENDOR_SERVICE_REQUEST_HISTORY);
    endpoint.searchParams.append("vendorId", id.toString());
    endpoint.searchParams.append("action", a);
    endpoint.searchParams.append("index", i.toString());
    endpoint.searchParams.append("viewableSize", v.toString());
    if (t) {
      endpoint.searchParams.append("totalSize", t.toString());
    }
    await getRequest(endpoint)
      .then(response => {
        dispatch(setVendorServiceRequestHistory(response));
      })
      .catch((error) => console.log("ERROR", error));
  };
};

export const fetchVendorTechServiceRequestHistory = (
  id: number,
  a: string,
  i: number,
  v: number,
  t?: number
): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const endpoint = new URL(SERVER_URL.VENDOR_TECH_SERVICE_REQUEST_HISTORY);
    endpoint.searchParams.append("technicianId", id.toString());
    endpoint.searchParams.append("action", a);
    endpoint.searchParams.append("index", i.toString());
    endpoint.searchParams.append("viewableSize", v.toString());
    if (t) {
      endpoint.searchParams.append("totalSize", t.toString());
    }
    await getRequest(endpoint)
      .then(response => {
        dispatch(setVendorTechServiceRequestHistory(response));
      })
      .catch((error) => console.log("ERROR", error));
  };
};









