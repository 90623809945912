import { ThunkDispatch, ThunkAction } from "redux-thunk";

import { AnyAction } from "redux";

import { SERVER_URL } from "../../configs/service-config";

import { Filter } from "./model";

import { setFilters, setFilterFields, setUserFilters, setLoadingUserFilters, setLoadingFilterFields } from "./action";

import { getRequest, postRequest } from '../interceptor'
import { getClientId } from "../auth/actions";

export const fetchFilters = (q?:string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {

  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const clientId: any = dispatch(getClientId());
    const saveFilterURI = new URL(SERVER_URL.filters);
    saveFilterURI.searchParams.append("clientId", clientId);
    if (q) saveFilterURI.searchParams.append("q", q);

    getRequest(saveFilterURI).then((response: any) => {
      dispatch(setFilters(response));
    }).catch((error) => {
      console.log("ERROR", error);
    });
  };
};

export const fetchFilterFields = (filterClass: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {

  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(setLoadingFilterFields(true))
    const clientId: any = dispatch(getClientId());
    const fetchFilterFieldsURI = new URL(SERVER_URL.filterFields);
    fetchFilterFieldsURI.searchParams.append("clientId", clientId);
    fetchFilterFieldsURI.searchParams.append("filterClass", filterClass);
    
    getRequest(fetchFilterFieldsURI).then((response: any) => {
      dispatch(setFilterFields(response));
    }).catch((error) => {
      console.log("ERROR", error);
    });
  };
};

export const saveFilter = async (filter:Filter):Promise<Filter> => {
  const saveFilterURI = new URL(SERVER_URL.filters);
  const response = await postRequest(saveFilterURI, {body: JSON.stringify(filter)});
  return <Filter>response;
};


export const fetchUserFilters = (q?:string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {

  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {

    dispatch(setLoadingUserFilters(true))
    
    const fetchUserFiltersURI = new URL(SERVER_URL.userFilters);
    fetchUserFiltersURI.searchParams.append("userId", "2");

    await getRequest(fetchUserFiltersURI).then((response: any) => {
      dispatch(setUserFilters(response))
    }).catch((error) => {
      console.log("ERROR", error);
    });
  };
};

